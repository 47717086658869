import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Route as RouteType } from './types/Route';
import { PasswordRecovery } from './routes/passwordRecovery/PasswordRecovery';
import { PasswordReset } from './routes/passwordReset/PasswordReset';
import { PublicRoute } from './routes/PublicRoute';
import { PrivateRoute } from './routes/PrivateRoute';
import { Login } from './routes/login/Login';
import { Overview } from './routes/overview/Overview';
import { Help } from './routes/help/Help';
import { Profile } from './routes/profile/Profile';
import { AboutMe } from './routes/aboutMe/AboutMe';
import { ContactDetails } from './routes/contactDetails/ContactDetails';
import { CompanyDetails } from './routes/companyDetails/CompanyDetails';
import { OurServices } from './routes/ourServices/OurServices';
import { Uploads } from './routes/uploads/Uploads';
import { Logout } from './routes/logout/Logout';
import { Confirmation } from './routes/confirmation/Confirmation';
import { SignUp } from './routes/signUp/SignUp';
import { Opportunities } from './routes/opportunities/Opportunities';
import { Projects } from './routes/projects/Projects';
import { Archive } from './routes/archive/Archive';
import ReactGA, { FieldsObject } from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';

const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {},
) => {
  const trackPage = (page: string) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(`${props.location.pathname}${props.location.hash}`);
    }, [props.location.pathname, props.location.hash]);

    return <WrappedComponent {...props} />;
  };
};

export const Routes = () => (
  <Switch>
    <Route path={RouteType.Help} component={withTracker(Help)} />
    <PublicRoute
      path={RouteType.PasswordRecovery}
      component={withTracker(PasswordRecovery)}
    />
    <PublicRoute
      path={RouteType.PasswordReset}
      component={withTracker(PasswordReset)}
    />
    <PublicRoute
      path={RouteType.Confirmation}
      component={withTracker(Confirmation)}
    />
    <PublicRoute path={RouteType.Login} component={withTracker(Login)} />
    <PublicRoute path={RouteType.Logout} component={withTracker(Logout)} />
    <PublicRoute path={RouteType.SignUp} component={withTracker(SignUp)} />
    <PrivateRoute path={RouteType.Overview} component={withTracker(Overview)} />
    <PrivateRoute path={RouteType.AboutMe} component={withTracker(AboutMe)} />
    <PrivateRoute
      path={RouteType.ContactDetails}
      component={withTracker(ContactDetails)}
    />
    <PrivateRoute
      path={RouteType.CompanyDetails}
      component={withTracker(CompanyDetails)}
    />
    <PrivateRoute
      path={RouteType.OurServices}
      component={withTracker(OurServices)}
    />
    <PrivateRoute path={RouteType.Uploads} component={withTracker(Uploads)} />
    <PrivateRoute path={RouteType.Profile} component={withTracker(Profile)} />
    <PrivateRoute
      path={RouteType.Opportunities}
      component={withTracker(Opportunities)}
    />
    <PrivateRoute path={RouteType.Projects} component={withTracker(Projects)} />
    <PrivateRoute path={RouteType.Archive} component={withTracker(Archive)} />
    <Redirect to={RouteType.Login} />
  </Switch>
);
