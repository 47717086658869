import { media, touchSupport } from '@meindach/ui-kit';
import { Link } from 'react-router-dom';
import styled, { css } from '../../styled-components';

export const StyledLink = styled(Link)<{ selected: boolean }>`
  transition: padding 0.35s, color 0.35s;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.storm};
  font-size: 14px;
  line-height: 24px;
  display: flex;
  padding-top: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.m};

  flex-direction: column;
  flex: 1;
  text-align: center;
  ${media.md`
    flex-direction: row;
    flex: inherit;
    text-align: left;
    padding: ${({ theme }) => theme.spacing.m}
  `}

  ${({ selected }) =>
    !selected &&
    !touchSupport &&
    media.md`
      :hover {
        color: ${({ theme }) => theme.color.slate};
      }
    `}

  position: relative;
  ::before {
    content: '';
    display: block;
    background: ${({ theme }) => theme.color.red};
    position: absolute;
    top: -1px;
    left: 50%;
    right: 50%;
    height: 0;
    transition: height 0.35s, left 0.35s, right 0.35s;
    ${media.md`
      content: none;
    `}
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.color.red};
      ::before {
        height: 3px;
        left: ${({ theme }) => theme.spacing.s};
        right: ${({ theme }) => theme.spacing.s};
      }
      ${media.md`
        background: ${({ theme }) => theme.color.white};
      `}
    `}

  > svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    ${media.md`
      margin: 0 ${({ theme }) => theme.spacing.m} 0 0;
    `}
  }
`;

export const Container = styled.div<{ collapsed: boolean }>`
  flex-shrink: 0;
  display: flex;
  padding: 0;
  box-sizing: border-box;
  overflow-y: auto;

  > svg {
    color: ${({ theme }) => theme.color.silver};
    padding: ${({ theme }) => theme.spacing.m};
    width: 14px;
    height: 14px;
    cursor: pointer;
    ${!touchSupport &&
    media.md`
        :hover {
          color: ${({ theme }) => theme.color.storm};
        }
      `}
    display: none;
    ${media.md`
      display: block;
    `}
  }

  width: 100%;
  background: ${({ theme }) => theme.color.fog};
  border-top: 1px solid ${({ theme }) => theme.color.silver};
  justify-content: space-evenly;

  ${media.md`
    transition: width 0.35s;
    flex-direction: column;
    border-top: none;
    justify-content: flex-start;
    position: inherit;
  `}

  ${({ collapsed }) =>
    collapsed
      ? media.md`
          width: 50px;
          > ${StyledLink} {
            padding-left: 12px;
            padding-right: 12px;
            overflow: hidden;
          }
          > svg {
            margin: 0 auto;
          }
        `
      : media.md`
          width: 250px;
          > svg {
            margin-left: auto;
          }
        `}
`;

export const Notification = styled.div`
  background: ${({ theme }) => theme.color.error};
  color: ${({ theme }) => theme.color.white};
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  margin-left: auto;
  position: absolute;
  top: ${({ theme }) => theme.spacing.s};
  right: ${({ theme }) => theme.spacing.s};
  ${media.md`
      position: inherit;
      top: 0;
      right: 0;
    `}
`;
