const legacyCopyToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch {}

  document.body.removeChild(textArea);
};

export const copyToClipboard = (text: string) =>
  navigator.clipboard
    ? navigator.clipboard.writeText(text)
    : legacyCopyToClipboard(text);
