import React, { FC } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { Route as RouteType } from '../types/Route';

interface Props extends RouteProps {
  component: FC<RouteComponentProps>;
}

export const PrivateRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { token } = useAuth();
  if (token) {
    return <Route component={Component} {...rest} />;
  }
  let redirectTo = `${window.location.pathname}${window.location.search}${window.location.hash}`;
  return <Redirect to={`${RouteType.Login}?redirect_to=${redirectTo}`} />;
};
