import React from 'react';
import { Container, Content } from './styled';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Route } from '../../types/Route';
import { ReactComponent as UploadIcon } from '../../assets/icons/cloud_upload.svg';
import { ReactComponent as ServicesIcon } from '../../assets/icons/bell.svg';
import { ReactComponent as CompanyIcon } from '../../assets/icons/company.svg';
import { ReactComponent as ContactIcon } from '../../assets/icons/envelope.svg';
import { ReactComponent as AboutMeIcon } from '../../assets/icons/profile.svg';
import { ProfileCard } from './ProfileCard';
import { useRoofer } from '../../hooks/useRoofer';
import { getFillPercent } from '../../utils/getFillPercent';
import {
  uploadFields,
  servicesFields,
  companyFields,
  contactFields,
  aboutMeFields,
} from '../../data/requiredFields';
import { Roofer } from '../../types/Roofer';

export const Profile = () => {
  const { t } = useTranslation('pageTitle');
  const { roofer } = useRoofer();
  const completed = (fields: (keyof Roofer)[]) =>
    getFillPercent(fields, roofer) === 100;
  return (
    <Container>
      <Content>
        <NavLink to={Route.AboutMe}>
          <ProfileCard
            info={t(`${Route.AboutMe}`)}
            icon={AboutMeIcon}
            completed={completed(aboutMeFields)}
          />
        </NavLink>
        <NavLink to={Route.ContactDetails}>
          <ProfileCard
            info={t(`${Route.ContactDetails}`)}
            icon={ContactIcon}
            completed={completed(contactFields)}
          />
        </NavLink>
        <NavLink to={Route.CompanyDetails}>
          <ProfileCard
            info={t(`${Route.CompanyDetails}`)}
            icon={CompanyIcon}
            completed={completed(companyFields)}
          />
        </NavLink>
        <NavLink to={Route.OurServices}>
          <ProfileCard
            info={t(`${Route.OurServices}`)}
            icon={ServicesIcon}
            completed={completed(servicesFields)}
          />
        </NavLink>
        <NavLink to={Route.Uploads}>
          <ProfileCard
            info={t(`${Route.Uploads}`)}
            icon={UploadIcon}
            completed={completed(uploadFields)}
          />
        </NavLink>
      </Content>
    </Container>
  );
};
