import styled from '../styled-components';
import { Button, Modal } from '@meindach/ui-kit';
import { ReactComponent as Close } from '@meindach/ui-kit/dist/assets/ui/close.svg';

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.m};
`;

export const StyledButtons = styled(Button)`
  margin: ${({ theme }) => theme.spacing.xs};
  height: 42px;
  > span {
    font-size: 13px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  > svg {
    margin: ${({ theme }) => theme.spacing.s};
  }
`;

export const StyledModal = styled(Modal)`
  padding: ${({ theme }) => theme.spacing.m};
  max-width: 450px;
  margin: 0 auto;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.storm};
  > div {
    margin-bottom: 10px;
  }
`;

export const CloseIcon = styled(Close)`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: ${({ theme }) => theme.spacing.s};
  color: ${({ theme }) => theme.color.silver};
  cursor: pointer;
  position: absolute;
  right: ${({ theme }) => theme.spacing.m};
  top: ${({ theme }) => theme.spacing.m};
`;
