import { config } from '../config';
import { RooferImageType } from '../types/RooferImageType';
import { getHeaders } from './getHeaders';

export const getRooferImage = async (
  token: string,
  rooferId: string,
  imageCategory: RooferImageType,
) =>
  fetch(
    `${config.apiBaseUrl}/api/v1/roofer/${rooferId}/image/${imageCategory}`,
    {
      method: 'GET',
      headers: getHeaders(token),
    },
  );
