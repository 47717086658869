import styled from '../../styled-components';

export const Header = styled.div`
  width: 100%;
  height: 60px;
  font-weight: 700;
  font-size: 16px;
  background: ${({ theme }) => theme.color.mercury};
  color: ${({ theme }) => theme.color.red};
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.silver};
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.m};
  > svg {
    color: ${({ theme }) => theme.color.storm};
    margin-right: ${({ theme }) => theme.spacing.m};
    width: 24px;
    height: 24px;
  }
  > a {
    margin-left: auto;
    color: ${({ theme }) => theme.color.silver};
    > svg {
      padding: ${({ theme }) => theme.spacing.m};
      width: 14px;
      height: 14px;
    }
  }
`;
