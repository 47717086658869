import React, { FC } from 'react';
import { Details, H4 } from './styled';
import { Card } from '../card/Card';
import { ReactComponent as ThumbsUpCircled } from '../../assets/icons/thumbsUpCircled.svg';
import { ReactComponent as Exclaim } from '../../assets/icons/exclaim.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  completed: boolean;
  stepTextCompleted: string;
  stepTextIncomplete: string;
}

export const ProjectStep: FC<Props> = ({
  title,
  completed,
  stepTextCompleted,
  stepTextIncomplete,
  children,
}) => {
  const { t } = useTranslation('stageMessages');
  return (
    <Card title={t(title)}>
      <Details completed={completed}>
        {completed ? <ThumbsUpCircled /> : <Exclaim />}
        <H4>{completed ? t(stepTextCompleted) : t(stepTextIncomplete)}</H4>
      </Details>
      {children}
    </Card>
  );
};
