import styled from '../../styled-components';

export const StyledFooter = styled.footer`
  background: ${({ theme }) => theme.color.charcoal};
`;

export const Container = styled.div`
  max-width: ${({ theme }) => theme.layout.maxWidth};
  margin: 0 auto;
  display: flex;
  color: ${({ theme }) => theme.color.mercury};
  padding: ${({ theme }) => theme.spacing.m};
  box-sizing: border-box;
  align-items: center;
  font-size: 16px;

  > svg {
    width: 60px;
    height: 60px;
    margin-right: ${({ theme }) => theme.spacing.m};
  }

  > nav {
    margin-left: auto;
    overflow: hidden;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    > span {
      cursor: default;
    }
    > a {
      :first-child {
        margin-left: 0;
      }
      :last-child {
        margin-right: 0;
      }
      margin: 0 ${({ theme }) => theme.spacing.s};
    }
  }
`;
