import styled from '../../styled-components';

export const DataContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  margin-left: -${({ theme }) => theme.spacing.l};
  margin-right: -${({ theme }) => theme.spacing.l};

  > :nth-child(even) {
    background: ${({ theme }) => theme.color.alabaster};
  }
`;

export const DataDetails = styled.div`
  padding: ${({ theme }) => theme.spacing.m} 0;

  > div {
    margin-left: ${({ theme }) => theme.spacing.m};
    word-break: break-all;
  }

  > :first-child {
    color: ${({ theme }) => theme.color.silver};
    margin-bottom: ${({ theme }) => theme.spacing.s};
    font-size: 16px;
    font-weight: 400;
  }
`;
