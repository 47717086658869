import styled from '../../styled-components';
import { P } from '@meindach/ui-kit';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 100%;
  min-height: 100%;
  color: ${({ theme }) => theme.color.charcoal};
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.mercury};
  font-weight: 600;
  height: 100%;
`;

export const Header = styled.div`
  font-size: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  > p {
    color: ${({ theme }) => theme.color.charcoal};
    margin-left: ${({ theme }) => theme.spacing.l};
  }
`;

export const InnerContainer = styled.div`
  box-sizing: border-box;
  overflow: auto;
  padding-left: ${({ theme }) => theme.spacing.l};
  padding-right: ${({ theme }) => theme.spacing.l};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  padding-top: ${({ theme }) => theme.spacing.m};
  color: ${({ theme }) => theme.color.storm};
  font-size: 16px;
  height: 100%;
  display: flex;
  flex: 1 auto;
  flex-direction: column;
`;

export const TitleLeft = styled(P)`
  float: left;
`;

export const TitleRight = styled(P)`
  float: right;
  padding-right: 32px;
`;
