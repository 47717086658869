import { act } from 'react-dom/test-utils';
import { render } from 'react-dom';
import { withTheme } from '../styles/withTheme';

export const actRender = (
  component: JSX.Element,
  useTheme: boolean = false,
) => {
  let container: HTMLDivElement = document.createElement('div');
  act(() => {
    render(useTheme ? withTheme(component) : component, container);
  });
  return container;
};
