export const calculateMapZoom = (zoomRadius: number) => {
  if (zoomRadius <= 10) {
    return 10;
  }
  if (zoomRadius <= 20) {
    return 9;
  }
  if (zoomRadius <= 40) {
    return 8;
  }
  if (zoomRadius <= 50) {
    return 7;
  }
  if (zoomRadius <= 100) {
    return 6;
  }
  if (zoomRadius <= 500) {
    return 4;
  }
  return 3;
};
