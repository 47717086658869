import React, { FC, memo } from 'react';
import { Container, UploadInfo, ReplaceInfo } from './styled';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadIcon } from '../../assets/icons/uploadImageArrow.svg';

interface Props {
  src?: string;
  onDrop: (acceptedFiles: File[]) => void;
  disabled?: boolean;
}

const uploadProps = {
  accept: 'image/jpeg, image/jpg, image/png, image/heic, image/heif',
  multiple: false,
  maxSize: 10 * 1024 * 1024,
};

export const ImageUpload: FC<Props> = memo(({ src, onDrop, disabled }) => {
  const { t } = useTranslation('uploads');
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    ...uploadProps,
    onDrop,
    disabled,
  });

  const url =
    acceptedFiles.length > 0 ? URL.createObjectURL(acceptedFiles[0]) : src;

  return (
    //  @ts-ignore
    <Container {...getRootProps()} src={url}>
      <input {...getInputProps()} />
      {url && !disabled && (
        <ReplaceInfo>
          <UploadIcon />
          <p>{t('replaceInfo')}</p>
        </ReplaceInfo>
      )}
      {!url && (
        <UploadInfo>
          <UploadIcon />
          <p>{t('uploadInfo')}</p>
        </UploadInfo>
      )}
    </Container>
  );
});
