import styled from '../../styled-components';
import { Button } from '@meindach/ui-kit';

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.m};
`;

export const StyledButtons = styled(Button)`
  margin: ${({ theme }) => theme.spacing.xs};
  height: 42px;
  > span {
    font-size: 13px;
  }
`;
