import React from 'react';
import { getFillPercent } from '../../utils/getFillPercent';
import { useRoofer } from '../../hooks/useRoofer';
import {
  Container,
  OpportunityList,
  LostList,
  Section,
  SectionTitle,
  StyledProgressBar,
} from './styled';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../components/listItem/ListItem';
import { Link } from '@meindach/ui-kit';
import { Route } from '../../types/Route';
import { requiredFields } from '../../data/requiredFields';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { ReactComponent as TickMark } from '../../assets/icons/confirmed.svg';
import { ReactComponent as ExclaimMark } from '../../assets/icons/exclaim.svg';
import { ReactComponent as PdfIcon } from '../../assets/icons/pdf.svg';
import { ReactComponent as repairIcon } from '../../assets/icons/jobRepair.svg';
import { Link as ALink } from '../../components/link/styled';
import { useProject } from '../../hooks/useProject';

export const Overview = () => {
  const { t } = useTranslation('overview');
  const { roofer, pending } = useRoofer();
  const { inquiries, lost } = useProject();
  const percent = getFillPercent(requiredFields, roofer);

  if (pending || !roofer) {
    return <PageLoading />;
  }
  const termsAccepted = roofer.termsAccepted;

  const inquiryList = inquiries?.map((inquiry, index) => (
    <div key={index}>
      <Link to={`${Route.Opportunities}#${inquiry.opportunityId}`}>
        <ListItem
          icon={repairIcon}
          title={inquiry.specification.fullName}
          text={t('acceptOrReject')}
          completed={false}
        />
      </Link>
    </div>
  ));

  const lostList = lost?.map((lost, index) => (
    <div key={index}>
      <Link to={`${Route.Opportunities}#${lost.opportunityId}`}>
        <ListItem
          icon={repairIcon}
          title={lost.specification.fullName}
          text={t('projectLost')}
          completed={false}
          lost={true}
        />
      </Link>
    </div>
  ));

  return (
    <Container>
      <Section>
        <SectionTitle>{t('profileSection')}</SectionTitle>
        {percent < 100 && (
          <Link to={Route.Profile}>
            <ListItem
              icon={() => (
                <StyledProgressBar value={percent} strokeWidth={20} />
              )}
              title={t('profileTitle')}
              text={t('profileText', { percent })}
            />
          </Link>
        )}
        <ListItem
          icon={termsAccepted ? TickMark : ExclaimMark}
          title={t('terms')}
          text={
            <>
              <p>{t('termsInfo')}</p>
              <ALink
                href="/assets/Teilnahmebedingungen_Dachhandwerker.pdf"
                target="_blank"
              >
                <PdfIcon />
                {t('terms')}
              </ALink>
              <p>
                {t('termsDP')}
                <ALink href="/assets/Dachhandwerker.pdf" target="_blank">
                  {t('link')}
                </ALink>
              </p>
            </>
          }
          showChevron={false}
          completed={termsAccepted}
        />
        <ListItem
          icon={TickMark}
          title={t('verification') + t('approved')}
          text={t('approvedInfo')}
          showChevron={false}
          completed={true}
        />
      </Section>
      {(inquiryList || lostList) && (
        <Section>
          <SectionTitle>{t('inquirySection')}</SectionTitle>
          {inquiryList && <OpportunityList>{inquiryList}</OpportunityList>}
          {lostList && <LostList>{lostList}</LostList>}
        </Section>
      )}
    </Container>
  );
};
