import styled from '../../styled-components';

export const Details = styled.div<{ completed: boolean }>`
  display: flex;
  flex-direction: row;
  color: ${({ theme, completed }) =>
    completed ? theme.color.green : theme.color.nag};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  align-items: center;

  > svg {
    flex: 0 0 auto;
    height: 32px;
    width: 32px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }

  > h4 {
    color: ${({ theme, completed }) =>
      completed ? theme.color.greenAcc : theme.color.nagAcc};
  }
`;

export const H3 = styled.h3`
  color: ${({ theme }) => theme.color.storm};
  font: ${({ theme }) => theme.fontFamily.primary};
  font-size: 20px;
  font-weight: 600;
  line-height: 1.275;
  letter-spacing: -0.25px;
  margin: 0;
  padding: 19px 0;
`;

export const H4 = styled.h4`
  color: ${({ theme }) => theme.color.midnight};
  font: ${({ theme }) => theme.fontFamily.primary};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.2px;
  padding: 0;
  margin: 1em 0 0.5em 0;
`;

export const MessageWrapper = styled.div`
  > p {
    margin: 15px 5px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing.m};

  > label {
    color: ${({ theme }) => theme.color.silver};
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 16px;
  > button {
    text-transform: uppercase;
    padding: 10px 16px;
    height: 42px;
    > span {
      font-size: 15px;
    }
  }
`;
