export const isRequired = (message: string) => (
  value: number | string | undefined | null,
) => {
  if (typeof value === 'number' && isNaN(value)) return message;
  if (value || typeof value === 'number') return '';
  return message;
};

export const isLessThan = (max: number, message: string) => (value: number) =>
  value >= max ? message : '';

export const isMoreThan = (min: number, message: string) => (value: number) =>
  value <= min ? message : '';
