import styled from '../../styled-components';
import { Input } from '@meindach/ui-kit';

export const ErrorText = styled.p`
  line-height: 50px;
  width: 100%;
  padding: 0px;
  color: ${({ theme }) => theme.color.error};
  margin: 0;
  font-size: 24px;
  text-align: left;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 25px;
`;
