import { media } from '@meindach/ui-kit';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from '../../styled-components';
import { Text } from '../../components/listItem/styled';

export const StyledProgressBar = styled(CircularProgressbar)`
  && > path {
    &:first-child {
      stroke: ${({ theme }) => theme.color.mercury};
    }
    &:last-child {
      stroke-linecap: inherit;
      stroke: ${({ theme }) => theme.color.green};
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
`;

export const Section = styled.div`
  width: 100%;
  ${media.md`
    width: 33.33%;
    padding: ${({ theme }) => theme.spacing.s};
  `}
`;

export const SectionTitle = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.color.slate};
  margin: ${({ theme }) => theme.spacing.l} ${({ theme }) => theme.spacing.m}
    ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.l};
`;

export const OpportunityList = styled.div`
  font-weight: 500;
  font-size: 15px;
  ${Text} {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.color.nag};
  }
`;

export const LostList = styled.div`
  font-weight: 500;
  font-size: 15px;
  ${Text} {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.color.silver};
  }
`;
