import { ProjectEntity } from '../types/ProjectEntity';
import { RooferProjectEntity } from '../types/RooferProjectEntity';
import { Project } from '../types/Project';
import { mapToStage } from './salesStageUtils';
import { convertLatLon } from './convertLatLon';

export const mapToProject = (rooferProject: RooferProjectEntity): Project => ({
  opportunityId: rooferProject.project.opportunity.id,
  stage: mapToStage(rooferProject.project.opportunity.sales_stage),
  overview: populateOverview(rooferProject.project),
  specification: populateSpecification(rooferProject.project),
  notes: rooferProject.projectNotes || '',
  images: rooferProject.projectImages,
  numberOfRoofers: rooferProject.numberOfRoofers,
  projectLost: rooferProject.projectLost,
});

const populateOverview = (project: ProjectEntity) => {
  return {
    street: project.lead.primary_address_street,
    city: project.lead.primary_address_city,
    postalCode: project.lead.primary_address_postalcode,
    dateEntered: new Date(project.lead.date_entered)
      .toLocaleDateString()
      .split('/')
      .join('.'),
    reference: project.lead.reference_number_c,
    startDate: project.lead.start_date_type_v1_c ?? '',
    phone: project.lead.phone_home,
    email: project.lead.email1,
    latLon: convertLatLon(project.lead.lat_lon_c),
  };
};

const populateSpecification = (project: ProjectEntity) => ({
  fullName: `${project.lead.salutation ? `${project.lead.salutation} ` : ''}${
    project.lead.first_name
  } ${project.lead.last_name}`,
  type: project.lead.house_type_v1_c ?? '',
  constructionYear: Number(project.lead.construction_year_c ?? 0),
  roofShape: project.lead.roof_type_v1_c ?? '',
  roofArea: project.lead.roof_size_type_v1_c ?? '',
  desiredCover: project.lead.tile_shape_type_v1_c ?? '',
  coverColour: project.lead.tile_color_type_v1_c ?? '',
  coverSurface: project.lead.tile_finish_type_v1_c ?? '',
  roofInsulated: (project.lead.insulation_type_v1_c?.length ?? -1) > 0,
  budget: project.lead.mask_budget_v1_c ?? '',
  financing: (project.lead.finance_types_v1_c?.length ?? -1) > 0,
});
