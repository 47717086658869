import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useAuth } from './useAuth';
import { getImage } from '../api/getImage';
import { isIE } from 'react-device-detect';
import heic2any from 'heic2any';

interface State {
  images: Map<string, string>;
  fetchImageError: boolean;
}

export const store = createStore<State>(Store.Image, {
  images: new Map(),
  fetchImageError: false,
});

const { getState, setState } = store;

export const useImages = () => {
  const { token } = useAuth();
  const [state] = useStore<State>(Store.Image);
  const { images, fetchImageError = false } = state;

  const fetchImage = async (imageId: string) => {
    if (!token) {
      setState({ ...getState(), fetchImageError: true });
      return;
    }
    if (!images.has(imageId)) {
      try {
        const response = await getImage(token, imageId);
        if (response.ok) {
          const blob = await response.blob();
          const contentType = response.headers.get('Content-Type');
          const existingImages = getState().images;
          if (
            isIE &&
            (contentType === 'image/heic' || contentType === 'image/heif')
          ) {
            existingImages.set(imageId, 'about:blank');
            setState({ ...getState(), images: existingImages });
            return;
          }
          const converted =
            contentType === 'image/heic' || contentType === 'image/heif'
              ? await heic2any({ blob })
              : blob;
          const image = URL.createObjectURL(converted);
          existingImages.set(imageId, image);
          setState({ ...getState(), images: existingImages });
          return;
        }
      } catch {}
      setState({ ...getState(), fetchImageError: true });
    }
  };

  return {
    images,
    fetchImage,
    fetchImageError,
  };
};
