import styled from 'styled-components';

export const Select = styled.select`
  width: 100%;
  height: 42px;
  padding: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.color.storm};
  background: ${({ theme }) => theme.color.white};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
