import { Route } from '../types/Route';
import ReactGA from 'react-ga';

interface Event {
  event: 'event';
  eventAction: 'login' | 'logout' | Route;
  eventCategory: 'roofer';
  eventLabel: string | undefined;
}

export const trackEvent = (
  eventCategory: Event['eventCategory'],
  eventAction: Event['eventAction'],
  eventLabel: Event['eventLabel'] = undefined,
) =>
  ReactGA.event({
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
  });
