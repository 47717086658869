import React from 'react';
import { ReactComponent as Icon } from '../../assets/icons/logoutIllustration.svg';
import { Container } from './styled';
import { useTranslation } from 'react-i18next';
import { Button, Link } from '@meindach/ui-kit';
import { Route } from '../../types/Route';

export const Logout = () => {
  const { t } = useTranslation('logout');
  return (
    <Container>
      <Icon />
      <p>{t('info')}</p>
      <Link to={Route.Login}>
        <Button small>{t('buttonText')}</Button>
      </Link>
    </Container>
  );
};
