import React, { FC } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { Route as RouteType } from '../types/Route';
import { getQueryParameter } from '../utils/getQueryParameter';

interface Props extends RouteProps {
  component: FC<RouteComponentProps>;
}

export const PublicRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { token } = useAuth();
  if (token) {
    const redirectTo = getQueryParameter(window.location.search, 'redirect_to');
    return (
      <Redirect
        to={
          (redirectTo && `${redirectTo}${window.location.hash}`) ||
          RouteType.Overview
        }
      />
    );
  }
  return <Route component={Component} {...rest} />;
};
