import { hexToRGBA } from '@meindach/ui-kit';
import styled, { keyframes } from '../../styled-components';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: auto;
  border: 10px solid ${({ theme }) => hexToRGBA(theme.color.red, 0.2)};
  border-left: 10px solid ${({ theme }) => theme.color.red};
  transform: translateZ(0);
  animation: ${rotation} 1.1s infinite linear;
`;
