import styled, { css } from '../../styled-components';
import { media, hexToRGBA } from '@meindach/ui-kit';

export const Container = styled.div<{ centered?: boolean }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  padding: ${({ theme }) => theme.spacing.m};
  ${media.md`
    padding: ${({ theme }) => theme.spacing.l};
    max-width: 500px;
    margin: 0 auto;
  `}

  > svg {
    display: block;
    color: ${({ theme }) => theme.color.red};
    width: 100px;
    height: 100px;
    margin: ${({ theme }) => theme.spacing.l} auto;
  }

  > form > button {
    margin-top: ${({ theme }) => theme.spacing.m};
  }

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  color: ${({ theme }) => theme.color.storm};
`;

export const Info = styled.p`
  font-size: 16px;
  line-height: 22px;
`;

export const Label = styled.label`
  display: block;
  font-size: 18px;
  color: ${({ theme }) => theme.color.storm};
  margin: ${({ theme }) => theme.spacing.m} 0 ${({ theme }) => theme.spacing.s};
`;

export const PasswordError = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.color.error};
  margin: ${({ theme }) => theme.spacing.xs} 0;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  position: absolute;
  background: ${({ theme }) => hexToRGBA(theme.color.alabaster, 0.8)};
`;
