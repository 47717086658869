import { media } from '@meindach/ui-kit';
import styled from '../../styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 840px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;

  padding: ${({ theme }) => theme.spacing.m};
  ${media.md`
    padding: ${({ theme }) => theme.spacing.l};
    margin: 0 auto;
  `}
`;
