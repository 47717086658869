import { config } from '../config';
import { getHeaders } from './getHeaders';
import { Coordinates } from '../types/Coordinates';

export const getDirections = (
  token: string,
  origin: Coordinates,
  destination: Coordinates,
) => {
  const or = `${origin.lat},${origin.lon}`;
  const dest = `${destination.lat},${destination.lon}`;

  return fetch(
    `${config.classificationUrl}/api/v1/directions/${or}/${dest}/route`,
    {
      headers: getHeaders(token),
    },
  );
};
