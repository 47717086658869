import { Coordinates } from '../types/Coordinates';

export const convertLatLon = (latLonString: string) => {
  const latLonArr = latLonString.split(',');
  const retLatLon: Coordinates = {
    lat: parseFloat(latLonArr[0]),
    lon: parseFloat(latLonArr[1]),
  };
  return retLatLon;
};
