import { Validation } from '../types/Validation';

export const getErrorMessage = (
  validations: Validation[],
  value: number | string,
): string =>
  validations.reduce(
    (errorMessage, validation: any) =>
      // TODO: ensure typesafety with seperate validation types etc.
      errorMessage === '' ? (errorMessage = validation(value)) : errorMessage,
    '',
  );
