import React from 'react';
import { StyledFooter, Container } from './styled';
import { ReactComponent as Logo } from '../../assets/logo/logo_empty_white.svg';
import { useTranslation } from 'react-i18next';
import { Link } from '../link/styled';

const links = [
  ['about', 'https://www.meindach.de/ueber-meindach/'],
  ['press', 'https://www.meindach.de/pressebereich/'],
  ['faq', 'https://www.meindach.de/faq/'],
  ['contact', 'https://www.meindach.de/kontakt/'],
  ['agb', 'https://www.meindach.de/agb/'],
  ['imprint', 'https://www.meindach.de/impressum/'],
  ['privacy', 'https://www.meindach.de/datenschutz/'],
];

export const Footer = () => {
  const { t } = useTranslation('footer');
  return (
    <StyledFooter>
      <Container>
        <Logo />
        <span>{t('copyright', { year: new Date().getFullYear() })}</span>
        <nav>
          {links.map(([key, url], index) => (
            <React.Fragment key={key}>
              {index !== 0 && <span key={`${key}pipe`}>|</span>}
              <Link key={key} href={url}>
                {t(key)}
              </Link>
            </React.Fragment>
          ))}
        </nav>
      </Container>
    </StyledFooter>
  );
};
