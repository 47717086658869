import React from 'react';
import { getQueryParameter } from '../../utils/getQueryParameter';
import { Redirect } from 'react-router';
import { Route as RouteType } from '../../types/Route';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { useConfirmationToken } from '../../hooks/useConfirmationToken';

export const Confirmation = () => {
  const { sendToken, success, error, pending } = useConfirmationToken();
  const token = getQueryParameter(window.location.search, 'token');

  if (success || error || !token) {
    return <Redirect to={RouteType.Overview} />;
  }

  if (!pending) {
    sendToken(token);
  }

  return <PageLoading />;
};
