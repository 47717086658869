import React, { FC } from 'react';
import {
  ButtonContainer,
  CloseIcon,
  StyledButtons,
  StyledModal,
  Title,
} from './styled';
import { P } from '@meindach/ui-kit';

interface Props {
  icon?: JSX.Element;
  title: string;
  message: string;
  okAction?: () => void;
  okText?: string;
  toggle: () => void;
  cancelText?: string;
}

export const Modal: FC<Props> = ({
  icon,
  title,
  message,
  okAction,
  okText,
  cancelText,
  toggle,
}) => {
  return (
    <StyledModal>
      <CloseIcon onClick={toggle} />
      <Title>
        {icon}
        <P>{title}</P>
      </Title>
      <div>{message}</div>
      <ButtonContainer>
        {okAction && (
          <StyledButtons
            onClick={() => {
              toggle();
              okAction && okAction();
            }}
          >
            {okText}
          </StyledButtons>
        )}
        {cancelText && (
          <StyledButtons emptyDanger onClick={toggle}>
            {cancelText}
          </StyledButtons>
        )}
      </ButtonContainer>
    </StyledModal>
  );
};
