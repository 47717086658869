import { useCallback, useEffect, useState } from 'react';
import { sendConfirmationToken } from '../api/sendConfirmationToken';
import { useAuth } from './useAuth';

interface State {
  success?: boolean;
  pending?: boolean;
  error?: string;
  authToken?: string;
}

interface ResponseBody {
  token: string;
  statusText: string;
}

export const useConfirmationToken = () => {
  const { tokenLogin } = useAuth();
  const [state, setState] = useState<State>({});
  const { success = false, pending = false, error, authToken } = state;

  const sendToken = useCallback(
    async (token: string) => {
      setState({ pending: true });
      try {
        const response = await sendConfirmationToken(token);
        const data: ResponseBody = await response.json();
        if (response.ok) {
          setState({ success: true, authToken: data.token });
        } else {
          setState({ error: data.statusText });
        }
      } catch {
        setState({ error: 'apiError' });
      }
    },
    [setState],
  );

  useEffect(() => {
    if (success && authToken) {
      tokenLogin(authToken);
    }
  }, [authToken, success, tokenLogin]);

  return { sendToken, success, pending, error };
};
