import React, { ChangeEvent, useEffect, useState } from 'react';
import { SmallTile } from '../../components/smallTile/SmallTile';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Binoculars } from '../../assets/icons/binoculars.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as SiteAppointment } from '../../assets/icons/siteAppt.svg';
import { ReactComponent as Price } from '../../assets/icons/price.svg';
import { ReactComponent as ThumbsUp } from '../../assets/icons/thumbsUp.svg';
import { ReactComponent as Handshake } from '../../assets/icons/handshake.svg';
import { ReactComponent as HardHat } from '../../assets/icons/hardHat.svg';
import { ReactComponent as Invoice } from '../../assets/icons/invoice.svg';
import { ReactComponent as Camera } from '../../assets/icons/camera.svg';
import { ReactComponent as Archive } from '../../assets/icons/archive.svg';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { ProjectSpecification } from '../../components/projectSpecification/ProjectSpecification';
import { ReactComponent as Spec } from '../../assets/icons/spec.svg';
import { useProject } from '../../hooks/useProject';
import { ProjectOverview } from '../../components/projectOverview/ProjectOverview';
import { NextStage } from '../../components/projectSteps/NextStage';
import { Project, Stage } from '../../types/Project';
import { ArchiveReason } from '../../types/ArchiveReason';
import {
  LargeTile,
  State as LargeTileState,
} from '../../components/largeTile/LargeTile';
import {
  Column,
  ColumnContainer,
} from '../../components/columnContainer/styled';
import { EmptyContainer } from '../../components/emptyContainer/EmptyContainer';
import { TFunction } from 'i18next';
import { ProjectStep } from '../../components/projectSteps/ProjectStep';
import { NavigationHeader } from '../../components/columnContainer/NavigationHeader';
import {
  ButtonWrapper,
  SelectWrapper,
} from '../../components/projectSteps/styled';
import { Button } from '@meindach/ui-kit/dist';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Container } from './styled';
import { Select } from '../../components/select/styled';
import { Photos } from '../../components/photos/Photos';
import { useLocation } from 'react-router';
import { Route as RouteType } from '../../types/Route';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import { ProjectTakenModal } from '../../components/projectTakenModal/ProjectTakenModal';

enum View {
  Projects,
  ProjectSteps,
  ProjectCategories,
  Details,
}

interface State {
  index: number;
  stageIndex: number;
  categoryIndex: number;
  view: View;
  archiveReason: ArchiveReason;
  handledHash: boolean;
}

export const Projects = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation('projects');
  const { hash } = useLocation();
  const history = useHistory();
  const [state, setState] = useState<State>({
    index: 0,
    stageIndex: 0,
    categoryIndex: 0,
    view: View.Projects,
    archiveReason: ArchiveReason.Empty,
    handledHash: false,
  });
  const { index, stageIndex, categoryIndex, view, handledHash } = state;
  const {
    initialised,
    inquiries,
    projects,
    archived,
    updatePending,
    archiveProject,
  } = useProject();
  const { show, toggle } = useModal();

  useEffect(() => {
    if (hash && !handledHash && initialised) {
      const id = hash.slice(1);
      const idx = projects.findIndex((project) => project.opportunityId === id);
      if (idx === -1) {
        if (inquiries.find((inquiry) => inquiry.opportunityId === id)) {
          history.push(`${RouteType.Opportunities}#${id}`);
          return;
        } else if (archived.find((project) => project.opportunityId === id)) {
          history.push(`${RouteType.Archive}#${id}`);
          return;
        }
      }
      if (idx >= 0) {
        setState((s) => ({ ...s, index: idx, handledHash: true }));
      } else {
        setState((s) => ({ ...s, handledHash: true }));
        toggle();
      }
    }
  }, [
    hash,
    handledHash,
    initialised,
    inquiries,
    projects,
    archived,
    history,
    toggle,
  ]);

  useEffect(() => {
    if (!hash && projects.length >= index + 1) {
      history.push(`${RouteType.Projects}#${projects[index].opportunityId}`);
    }
  }, [hash, projects, index, history]);

  if (updatePending) {
    return <PageLoading />;
  }

  if (projects.length === 0) {
    return <EmptyContainer reason={t('reason')} action={t('action')} />;
  }

  const reset = () => {
    setState({
      ...state,
      index: 0,
      stageIndex: 0,
      categoryIndex: 0,
      view: View.Projects,
      archiveReason: ArchiveReason.Empty,
    });
  };

  const updateStageIndex = () => {
    setState({ ...state, stageIndex: stageIndex + 1 });
  };

  const archiveReasonChange = (event: ChangeEvent<HTMLSelectElement>) =>
    setState({
      ...state,
      archiveReason: event.target.value as ArchiveReason,
    });

  const archiveAndReset = async (project: Project) => {
    await archiveProject(project, state.archiveReason, () => {
      reset();
    });
  };

  const getProject = (index: number) => {
    if (index >= projects.length) {
      reset();
      return projects[0];
    }
    return projects[index];
  };

  const visibleViews = () => {
    if (width < 850) {
      return [view];
    }
    if (width < 1200) {
      if (
        view === View.Projects.valueOf() ||
        view === View.ProjectSteps.valueOf()
      ) {
        return [View.Projects.valueOf(), View.ProjectSteps.valueOf()];
      }
      if (stageIndex === 0) {
        return [View.ProjectCategories.valueOf(), View.Details.valueOf()];
      }
      return [View.ProjectCategories.valueOf()];
    }
    if (view === View.Details.valueOf()) {
      return [View.Details.valueOf()];
    }
    return [
      View.Projects.valueOf(),
      View.ProjectSteps.valueOf(),
      View.ProjectCategories.valueOf(),
    ];
  };

  const back = () => {
    setState({ ...state, view: view.valueOf() - visibleViews().length });
  };

  const project = getProject(index);
  const viewsToShow = visibleViews();
  const showNavigationHeader =
    viewsToShow.length !== 4 && view.valueOf() >= viewsToShow.length;

  const projectCards = (
    projects: Project[],
    t: TFunction,
    index: number,
    setIndex: (newIndex: number) => void,
  ) =>
    projects.map(
      ({ opportunityId, overview, specification, stage }, currIndex) => {
        const getState = (): LargeTileState => {
          if (stage === Stage.Complete) {
            return 'complete';
          } else if (stage === Stage.Invoice) {
            return 'normal';
          } else {
            return 'nag';
          }
        };

        let cardText = '';
        switch (stage) {
          case Stage.SiteVisit:
            cardText = t('siteVisitNag');
            break;
          case Stage.Quotation:
            cardText = t('quotationNag');
            break;
          case Stage.Contract:
            cardText = t('contractNag');
            break;
          case Stage.RoofWork:
            cardText = t('roofWorkNag');
            break;
          case Stage.Invoice:
            cardText = t('invoiceNag');
            break;
          case Stage.Complete:
            cardText = t('complete');
            break;
        }
        return (
          <LargeTile
            /* eslint-disable-next-line react/no-array-index-key */
            key={currIndex}
            selected={currIndex === index}
            state={getState()}
            title={specification.fullName}
            street={overview.street}
            city={overview.city}
            postalCode={overview.postalCode}
            customerLatLon={overview.latLon}
            area={t(`roofArea:${specification.roofArea}`)}
            startDate={t(`startDate:${overview.startDate}`)}
            cardText={cardText}
            onClick={() => {
              setIndex(currIndex);
              if (hash !== opportunityId) {
                history.push(`${RouteType.Projects}#${opportunityId}`);
              }
            }}
          />
        );
      },
    );

  const projectCategory = (
    stageIndex: number,
    projectDetailsCategories: JSX.Element[],
    t: TFunction,
    project: Project,
    updateStageIndex: () => void,
    archiveReasonChange: (event: ChangeEvent<HTMLSelectElement>) => void,
    archiveProject: (project: Project) => void,
  ) => {
    const archiveReasons = Object.entries(ArchiveReason)
      .filter(([key]) => key !== 'Empty')
      .map(([key, value]) => ({
        value: value,
        label: t(`archiveReason:${key}`),
      }));
    return (
      <>
        {stageIndex === 0 && projectDetailsCategories}
        {stageIndex === 1 && (
          <ProjectStep
            title="projectAcceptanceTitle"
            stepTextCompleted="projectAcceptanceComplete"
            stepTextIncomplete="projectAcceptanceComplete"
            completed
          />
        )}
        {stageIndex === 2 && (
          <ProjectStep
            title="siteVisitAppointmentTitle"
            stepTextCompleted="siteVisitAppointmentComplete"
            stepTextIncomplete="siteVisitAppointmentIncomplete"
            completed={project.stage.valueOf() > Stage.SiteVisit.valueOf()}
          >
            {project.stage === Stage.SiteVisit && (
              <NextStage project={project} updatedCallback={updateStageIndex} />
            )}
          </ProjectStep>
        )}
        {stageIndex === 3 && (
          <ProjectStep
            title="quotationTitle"
            stepTextCompleted="quotationComplete"
            stepTextIncomplete="quotationIncomplete"
            completed={project.stage.valueOf() > Stage.Quotation.valueOf()}
          >
            {project.stage === Stage.Quotation && (
              <NextStage project={project} updatedCallback={updateStageIndex} />
            )}
          </ProjectStep>
        )}
        {stageIndex === 4 && (
          <ProjectStep
            title="contractTitle"
            stepTextCompleted="contractComplete"
            stepTextIncomplete="contractIncomplete"
            completed={project.stage.valueOf() > Stage.Contract.valueOf()}
          >
            {project.stage === Stage.Contract && (
              <NextStage project={project} updatedCallback={updateStageIndex} />
            )}
          </ProjectStep>
        )}
        {stageIndex === 5 && (
          <ProjectStep
            title="roofWorkTitle"
            stepTextCompleted="roofWorkComplete"
            stepTextIncomplete="roofWorkIncomplete"
            completed={project.stage.valueOf() > Stage.RoofWork.valueOf()}
          >
            {project.stage === Stage.RoofWork && (
              <NextStage project={project} updatedCallback={updateStageIndex} />
            )}
          </ProjectStep>
        )}
        {stageIndex === 6 && (
          <ProjectStep
            title="invoiceTitle"
            stepTextCompleted="invoiceComplete"
            stepTextIncomplete="invoiceIncomplete"
            completed={project.stage.valueOf() > Stage.Invoice.valueOf()}
          >
            {project.stage === Stage.Invoice && (
              <NextStage project={project} updatedCallback={updateStageIndex} />
            )}
          </ProjectStep>
        )}
        {stageIndex === 7 && (
          <ProjectStep
            title="archiveTitle"
            stepTextCompleted="archiveComplete"
            stepTextIncomplete="archiveIncomplete"
            completed={false}
          >
            <SelectWrapper>
              <label>{t('reasonForArchiving')}</label>
              <Select
                placeholder={t('selectArchiveReason')}
                name="archiveReason"
                value={project.archiveReason}
                onChange={archiveReasonChange}
              >
                {archiveReasons.map((archiveReason) => (
                  <option key={archiveReason.value} value={archiveReason.value}>
                    {archiveReason.label}
                  </option>
                ))}
              </Select>
            </SelectWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  archiveProject(project);
                }}
              >
                {t('stageMessages:archiveButton')}
              </Button>
            </ButtonWrapper>
          </ProjectStep>
        )}
      </>
    );
  };

  const projectSteps = (
    project: Project,
    stage: Stage,
    t: TFunction,
    setStageIndex: (newStageIndex: number) => void,
  ) =>
    [
      { text: 'details', icon: Info, step: Stage.Accepted },
      { text: 'accept', icon: ThumbsUp, step: Stage.Accepted },
      {
        text: 'siteVisitAppointment',
        icon: SiteAppointment,
        step: Stage.SiteVisit,
      },
      {
        text: 'quotation',
        icon: Price,
        step: Stage.Quotation,
      },
      {
        text: 'contract',
        icon: Handshake,
        step: Stage.Contract,
      },
      {
        text: 'roofWork',
        icon: HardHat,
        step: Stage.RoofWork,
      },
      {
        text: 'invoice',
        icon: Invoice,
        step: Stage.Invoice,
      },
      {
        text: 'archive',
        icon: Archive,
      },
    ].map(({ text, icon, step }, currIndex) => {
      const enabled =
        step === undefined || step.valueOf() <= project.stage.valueOf();
      return (
        <SmallTile
          /* eslint-disable-next-line react/no-array-index-key */
          key={currIndex}
          selected={currIndex === stage}
          enabled={enabled}
          nag={step !== undefined && project.stage.valueOf() === step.valueOf()}
          text={t(text)}
          icon={icon}
          onClick={() => {
            enabled && setStageIndex(currIndex);
          }}
        />
      );
    });

  const projectDetailsCategories = (
    categoryIndex: number,
    t: TFunction,
    setCategoryIndex: (newCategoryIndex: number) => void,
  ): JSX.Element[] =>
    [
      { text: 'overView', icon: Binoculars, enabled: true },
      { text: 'details', icon: Spec, enabled: true },
      { text: 'photos', icon: Camera, enabled: true },
    ].map(({ text, icon, enabled }, currIndex) => (
      <SmallTile
        /* eslint-disable-next-line react/no-array-index-key */
        key={currIndex}
        selected={currIndex === categoryIndex}
        enabled={enabled}
        nag={false}
        text={t(text)}
        icon={icon}
        onClick={() => {
          enabled && setCategoryIndex(currIndex);
        }}
      />
    ));

  const details = (categoryIndex: number, project: Project, t: TFunction) => (
    <>
      {categoryIndex === 0 && overview(project, t)}
      {categoryIndex === 1 && projectSpec(project)}
      {categoryIndex === 2 && photos(project)}
    </>
  );

  const overview = (project: Project, t: TFunction) => (
    <ProjectOverview
      reference={project.overview.reference}
      startDate={t(`startDate:${project.overview.startDate}`)}
      street={project.overview.street}
      city={project.overview.city}
      postalCode={project.overview.postalCode}
      dateEntered={project.overview.dateEntered}
      customerFullName={project.specification.fullName}
      customerPhone={project.overview.phone}
      customerEmail={project.overview.email}
      customerStreet={project.overview.street}
      customerCity={project.overview.city}
      customerPostalCode={project.overview.postalCode}
      customerLatLon={project.overview.latLon}
    />
  );

  const projectSpec = (project: Project) => {
    const specificationData = Object.entries(
      project.specification,
    ).flatMap(([first, second]) => ({ title: first, value: second }));
    return <ProjectSpecification data={specificationData} />;
  };

  const photos = (project: Project) => {
    return <Photos project={project} allowUploads={true} />;
  };

  return (
    <Container>
      {show && <ProjectTakenModal toggle={toggle} />}
      {showNavigationHeader && (
        <NavigationHeader project={project} back={back} />
      )}
      <ColumnContainer>
        <Column
          currentView={View.Projects.valueOf()}
          viewsToShow={viewsToShow}
          fade={false}
        >
          {projectCards(projects, t, index, (idx) =>
            setState({
              ...state,
              index: idx,
              stageIndex: 0,
              categoryIndex: 0,
              view: View.ProjectSteps,
            }),
          )}
        </Column>
        <Column
          currentView={View.ProjectSteps.valueOf()}
          viewsToShow={viewsToShow}
          fade
        >
          {projectSteps(project, stageIndex, t, (idx) =>
            setState({
              ...state,
              stageIndex: idx,
              categoryIndex: 0,
              view: View.ProjectCategories,
            }),
          )}
        </Column>
        <Column
          currentView={View.ProjectCategories.valueOf()}
          viewsToShow={viewsToShow}
          fade
        >
          {projectCategory(
            stageIndex,
            projectDetailsCategories(categoryIndex, t, (idx) =>
              setState({ ...state, categoryIndex: idx, view: View.Details }),
            ),
            t,
            project,
            updateStageIndex,
            archiveReasonChange,
            archiveAndReset,
          )}
        </Column>
        {stageIndex === 0 && (
          <Column
            currentView={View.Details.valueOf()}
            viewsToShow={viewsToShow}
            fade
          >
            {details(categoryIndex, project, t)}
          </Column>
        )}
      </ColumnContainer>
    </Container>
  );
};
