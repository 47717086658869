import styled from '../../styled-components';
import { media } from '@meindach/ui-kit';

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  background: ${({ theme }) => theme.color.alabaster};
  padding: ${({ theme }) => theme.spacing.l};
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.white};

  ${media.md`
      padding: ${({ theme }) => theme.spacing.s};
  `}
`;
