import styled, { keyframes } from '../../styled-components';
import { media } from '@meindach/ui-kit';

export const ColumnContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.slate};
  background: #f5f5f5;
  border-bottom: 1px solid ${({ theme }) => theme.color.silver};
  box-sizing: border-box;
  flex-shrink: 0;

  > p {
    font-weight: 600;
    font-size: 20px;
    word-break: break-all;
  }

  > :first-child {
    flex: 0 0 auto;
    height: 21px;
    width: 21px;
    transform: rotate(90deg);
    color: ${({ theme }) => theme.color.slate};
    margin-left: ${({ theme }) => theme.spacing.s};
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`;

const fadeInFromBackground = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Column = styled.div<{
  currentView: number;
  viewsToShow: number[];
  fade: boolean;
}>`
  flex: 1;
  height: 100%;
  width: ${({ viewsToShow }) => 100 / viewsToShow.length}%;
  box-sizing: border-box;
  overflow-y: auto;
  position: ${({ currentView, viewsToShow }) =>
    viewsToShow.findIndex((it) => it === currentView) !== -1
      ? 'relative'
      : 'absolute'};
  left: ${({ currentView, viewsToShow }) =>
    viewsToShow.findIndex((it) => it === currentView) !== -1
      ? 0
      : Math.max(...viewsToShow) > currentView
      ? -100
      : 100}%;
  transition: left 0.25s ease-in-out 0s;
  @media (min-width: 780px) {
    padding: ${({ theme }) => theme.spacing.s};
  }
  > div {
    max-height: 100%;
    animation: none;
    ${({ fade }) =>
      fade &&
      media.md`
          animation: ${fadeInFromBackground} 500ms ease-in-out 0.1s both;
        `}
  }
`;
