import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, P } from '@meindach/ui-kit/dist';
import { ButtonWrapper, MessageWrapper } from './styled';
import { Project, Stage } from '../../types/Project';
import { useProject } from '../../hooks/useProject';
import { RooferProjectEntity } from '../../types/RooferProjectEntity';
import { DebouncedTextArea } from '../debouncedTextArea/DebouncedTextArea';

interface Props {
  project: Project;
  updatedCallback: (updatedProject: RooferProjectEntity) => void;
}

export const NextStage: FC<Props> = ({ project, updatedCallback }) => {
  const { t } = useTranslation('nextStage');
  const { progressToNextStage, updateNotes, updateNotesPending } = useProject();
  const initialValues = {
    notes: project.notes,
  };

  const onChange = (values: any) => {
    updateNotes(project, values, 1000);
  };

  return (
    <>
      <MessageWrapper>
        <P>
          {t(nextStageMessage(project.stage), nextStageData(project.stage))}
        </P>
        {nextStageExtra(project.stage) && (
          <P>{t(nextStageExtra(project.stage)!)}</P>
        )}
      </MessageWrapper>
      {nextStageButton && (
        <ButtonWrapper>
          <Button onClick={() => progressToNextStage(project, updatedCallback)}>
            {t(nextStageButton(project.stage))}
          </Button>
        </ButtonWrapper>
      )}
      <DebouncedTextArea
        label={t('notes')}
        initialValue={initialValues.notes}
        placeholder={t('notesPlaceholder')}
        updating={updateNotesPending}
        savingLabel={t('notesSavingLabel')}
        savedLabel={t('notesSavedLabel')}
        onChangeEvent={onChange}
      ></DebouncedTextArea>
    </>
  );
};

const nextStageMessage = (currentStage: Stage): string => {
  switch (currentStage) {
    case Stage.SiteVisit:
      return 'siteVisitMessage';
    case Stage.Quotation:
      return 'quotationMessage';
    case Stage.Contract:
      return 'contractMessage';
    case Stage.RoofWork:
      return 'roofWorkMessage';
    case Stage.Invoice:
      return 'invoiceMessage';
    default:
      return '';
  }
};

const nextStageExtra = (currentStage: Stage): string | undefined => {
  switch (currentStage) {
    case Stage.SiteVisit:
      return 'siteVisitExtra';
    case Stage.Quotation:
      return 'quotationExtra';
    default:
      return undefined;
  }
};

const nextStageData = (currentStage: Stage): any => {
  switch (currentStage) {
    case Stage.SiteVisit:
      return { siteVisitDate: '21.11.2019' };
    default:
      return {};
  }
};

const nextStageButton = (currentStage: Stage): string => {
  switch (currentStage) {
    case Stage.SiteVisit:
      return 'siteVisitButton';
    case Stage.Quotation:
      return 'quotationButton';
    case Stage.Contract:
      return 'contractButton';
    case Stage.RoofWork:
      return 'roofWorkButton';
    case Stage.Invoice:
      return 'invoiceButton';
    default:
      return '';
  }
};
