import React, { FC } from 'react';
import { DataContainer, DataDetails } from './styled';
import { useTranslation } from 'react-i18next';
import { Card } from '../card/Card';

interface Data {
  title: string;
  value: string | number | boolean | undefined;
}

interface Props {
  data: Data[];
}

export const ProjectSpecification: FC<Props> = ({ data }) => {
  const { t } = useTranslation('projectSpecification');

  const formatValues = (title: string, value: any) => {
    if (typeof value === 'boolean') {
      return t(`common:${value}`);
    } else if (value) {
      return t(`${title}:${value}`);
    } else {
      return '-';
    }
  };

  const dataDetails = data.map(({ title, value }) => (
    <DataDetails key={title}>
      <div>{t(title)}</div>
      <div>{formatValues(title, value)}</div>
    </DataDetails>
  ));

  return (
    <Card title={t('title')}>
      <DataContainer>{dataDetails}</DataContainer>
    </Card>
  );
};
