import styled from '../../styled-components';
import { media, Title, P, touchSupport } from '@meindach/ui-kit';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  ${media.md`
    padding: ${({ theme }) => theme.spacing.l};
  `}
`;

export const Content = styled.div`
  box-sizing: border-box;

  > ${Title} {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing.m};

    ${media.md`
      margin-bottom: ${({ theme }) => theme.spacing.l};
    `}
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  background: ${({ theme }) => theme.color.alabaster};
  color: ${({ theme }) => theme.color.red};
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  padding-left: ${({ theme }) => theme.spacing.m};
  box-sizing: border-box;
  > svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.color.storm};
    margin-right: ${({ theme }) => theme.spacing.m};
  }

  ${!touchSupport &&
  media.md`
        border: 1px solid ${({ theme }) => theme.color.silver};
        margin-bottom: ${({ theme }) => theme.spacing.xs};
      :hover {
        background: ${({ theme }) => theme.color.white};
      }
    `}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 82px;
  justify-content: center;
  flex-wrap: wrap;

  ${P} {
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.color.red};
    :last-child {
      font-size: 16px;
      color: ${({ theme }) => theme.color.silver};
      font-weight: normal;
    }
  }
`;

export const IconView = styled.div`
  margin-left: auto;
  width: 32px;
  height: 32px;
  right: 22px;
  top: 25px;
  margin-right: 8px;
`;

export const Tick = styled.div`
  color: ${({ theme }) => theme.color.blue400};
`;

export const Exclaim = styled.div`
  color: ${({ theme }) => theme.color.error};
`;
