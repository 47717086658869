import React, { FC, useCallback, useEffect, useState } from 'react';
import { Card } from '../card/Card';
import { useTranslation } from 'react-i18next';
import { Project } from '../../types/Project';
import { useProject } from '../../hooks/useProject';
import { useProjectEntity } from '../../hooks/useProjectEntity';
import { AddButton, ImageContainer, UploadInfo } from './styled';
import { useImages } from '../../hooks/useImages';
import { PageLoading } from '../pageLoading/PageLoading';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { useModal } from '../../hooks/useModal';
import { PhotoModal } from './PhotoModal';

interface Props {
  project: Project;
  allowUploads: boolean;
}

const acceptableMimeTypes =
  'image/jpeg, image/jpg, image/png, image/heic, image/heif';
const maxFileSize = 10 * 1024 * 1024;

const uploadProps = {
  accept: acceptableMimeTypes,
  multiple: true,
  maxSize: maxFileSize,
};

export const Photos: FC<Props> = ({ project, allowUploads }) => {
  const { t } = useTranslation('photos');
  const { uploadImage } = useProject();
  const { deleteProjectImage } = useProjectEntity();
  const { images, fetchImage } = useImages();
  const { show: showPhoto, toggle: togglePhoto } = useModal();
  const [selectedImage, selectImage] = useState<string | undefined>(undefined);
  const [pending, setPending] = useState<boolean>(false);

  const onDrop = async (acceptedFiles: File[]) => {
    let uploaded = 0;
    acceptedFiles.forEach(
      (file) =>
        project.images.length + uploaded < 10 &&
        acceptableMimeTypes.indexOf(file.type) !== -1 &&
        file.size < maxFileSize &&
        uploadImage(project, file) &&
        uploaded++,
    );
  };

  const deleteAction = useCallback(
    () => deleteProjectImage(project, selectedImage!!),
    [deleteProjectImage, project, selectedImage],
  );

  const { getRootProps } = useDropzone({
    ...uploadProps,
    onDrop,
    disabled: !allowUploads,
  });

  useEffect(() => {
    if (pending) {
      return;
    }
    Promise.all(
      project.images.map((imageId) => {
        if (images.has(imageId)) {
          return Promise.resolve();
        }
        setPending(true);
        return fetchImage(imageId);
      }),
    ).then(() => setPending(false));
  });

  if (pending) {
    return <PageLoading />;
  }
  return (
    <Card
      title={t('title')}
      secondaryTitle={allowUploads ? `${project.images.length}/10` : undefined}
    >
      {/* @ts-ignore */}
      <ImageContainer allowUploads={allowUploads} {...getRootProps()}>
        {project.images.length > 0 &&
          project.images.map((imageId) => (
            <img
              key={imageId}
              src={images.get(imageId)}
              alt={''}
              onClick={() => {
                selectImage(imageId);
                togglePhoto();
              }}
            />
          ))}
        {allowUploads && project.images.length === 0 && (
          <UploadInfo>{t('upload')}</UploadInfo>
        )}
      </ImageContainer>
      {allowUploads && project.images.length < 10 && (
        <AddButton>
          <label htmlFor={'file-upload'}>
            <Plus />
          </label>
          <input
            type="file"
            id={'file-upload'}
            multiple={true}
            accept={acceptableMimeTypes}
            onChange={(event) => {
              const files = event.target.files;
              if (files) {
                onDrop([...files]);
              }
            }}
          />
        </AddButton>
      )}
      {showPhoto && selectedImage && (
        <PhotoModal
          toggle={togglePhoto}
          deleteClick={deleteAction}
          photoSrc={images.get(selectedImage)}
        />
      )}
    </Card>
  );
};
