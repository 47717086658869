import { useStore, createStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { getDirections } from '../api/getDirections';
import { Coordinates } from '../types/Coordinates';
import { MapsDirections } from '../types/MapsApiResponse';
import Polyline from '@mapbox/polyline';
import { createGeoJSONDirection } from '../utils/createGeoJSONDirection';
import { useAuth } from './useAuth';
import { useEffect } from 'react';

interface DirectionsState {
  directions?: MapsDirections;
  error?: boolean;
  fetching?: boolean;
}

export const store = createStore<State>(Store.Directions, {});
const { getState, setState } = store;

type State = Record<string, DirectionsState>;

const fetchDirections = async (
  token: string,
  key: string,
  origin: Coordinates,
  destination: Coordinates,
) => {
  setState({ ...getState(), [key]: { fetching: true } });
  try {
    const response = await getDirections(token, origin, destination);

    if (response.ok) {
      const respJson = await response.json();
      const routeDistance = respJson.routes[0].legs[0].distance.humanReadable;
      const points = Polyline.decode(
        respJson.routes[0].overviewPolyline.encodedPath,
      );
      const geojson = createGeoJSONDirection(points);
      setState({
        ...getState(),
        [key]: { directions: { routeDistance, geojson } },
      });
    } else {
      setState({ ...getState(), [key]: { error: true } });
    }
  } catch {
    setState({ ...getState(), [key]: { error: true } });
  }
};

const fetchData = (
  token: string,
  key: string,
  destination: Coordinates,
  origin: Coordinates,
) => {
  const directionsState = getState()[key];
  if (
    !directionsState ||
    (!directionsState.fetching && !directionsState.directions)
  ) {
    fetchDirections(token, key, origin, destination);
  }
};

export const useDirections = (
  destination: Coordinates,
  origin: Coordinates,
) => {
  const key = `${destination.lat},${destination.lon}`;
  const [state] = useStore<State>(Store.Directions);
  const { token } = useAuth();

  const { directions = undefined, error = false, fetching = false } =
    state[key] || {};

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchData(token, key, destination, origin);
  }, [token, key, destination, origin]);

  return { directions, fetching, error };
};
