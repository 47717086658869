import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@meindach/ui-kit';
import { Form, Formik } from 'formik';
import { Container, FormContainer } from '../common/common-styled';
import { GenericInput } from '../../components/genericInput/GenericInput';
import { useRoofer } from '../../hooks/useRoofer';
import { jobValues } from '../../data/jobValues';
import { useConsultant } from '../../hooks/useConsultant';
import * as Yup from 'yup';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { TextArea } from '../../components/readOnlyText/TextArea';
import { SelectOptions } from '../../components/dropDownSelect/SelectOptions';
import { ProfileHeader } from '../../components/profileHeader/Header';
import { Route } from '../../types/Route';
import { ToggleInput } from '../../components/toggleInput/ToggleInput';
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import { Status } from '../../components/status/Status';
import { getFillPercent } from '../../utils/getFillPercent';
import { aboutMeFields } from '../../data/requiredFields';

export const AboutMe = () => {
  const { t } = useTranslation('aboutMe');
  const { roofer, update, pending } = useRoofer();
  const { consultants } = useConsultant();
  const completed = getFillPercent(aboutMeFields, roofer) === 100;

  if (pending || !roofer || !consultants) {
    return <PageLoading />;
  }

  const initialValues = {
    firstName: roofer.firstName,
    lastName: roofer.lastName,
    company: roofer.company,
    jobDescription: roofer.jobDescription,
    postalCode: roofer.postalCode,
    city: roofer.city,
    street: roofer.street,
    consultant: consultants.find(
      (consultant) => consultant.id === roofer.consultant,
    )?.fullName,
    masterCraftsmanCertificate: roofer.masterCraftsmanCertificate,
  };
  const onSubmit = (values: any) =>
    update({
      ...roofer,
      jobDescription: values.jobDescription,
      street: values.street,
      city: values.city,
      postalCode: values.postalCode,
      masterCraftsmanCertificate: values.masterCraftsmanCertificate,
    });
  const jobOptions = jobValues.map((key) => ({
    value: key,
    label: t(`job:${key}`),
  }));
  const aboutMeValidationSchema = Yup.object().shape({
    postalCode: Yup.string().required(t('common:required')),
    city: Yup.string().required(t('common:required')),
    street: Yup.string().required(t('common:required')),
  });

  return (
    <Container>
      <ProfileHeader
        header={t(`pageTitle:${Route.AboutMe}`)}
        image={Profile}
        route={Route.Profile}
      />
      <FormContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={aboutMeValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <Form>
            <TextArea label={t('firstName')} name="firstName" />
            <TextArea label={t('lastName')} name="lastName" />
            <TextArea label={t('company')} name="company" />
            <SelectOptions
              placeholder={t('selectJobTitle')}
              label={t('jobDescription')}
              name="jobDescription"
              options={jobOptions}
            />
            <GenericInput label={`* ${t('street')}`} name="street" />
            <GenericInput label={`* ${t('city')}`} name="city" />
            <GenericInput label={`* ${t('postalCode')}`} name="postalCode" />
            <TextArea label={t('consultant')} name="consultant" />
            <ToggleInput
              text={t('masterCraftsmanCertificate')}
              name="masterCraftsmanCertificate"
            />
            <Button type="submit">{t('buttonText')}</Button>
          </Form>
        </Formik>
        <Status completed={completed} />
      </FormContainer>
    </Container>
  );
};
