import React, { FC } from 'react';
import { ReactComponent as Chevron } from '@meindach/ui-kit/dist/assets/ui/chevronDown.svg';
import { P } from '@meindach/ui-kit';
import { Project } from '../../types/Project';
import { Container } from './styled';

interface Props {
  project: Project;
  back: () => void;
}

export const NavigationHeader: FC<Props> = ({ project, back }) => {
  return (
    <Container onClick={back}>
      <Chevron />
      <P>
        {`${project.specification.fullName} | ${project.overview.reference}`}
      </P>
    </Container>
  );
};
