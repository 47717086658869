import React, { ChangeEvent, FC, useState } from 'react';
import { Input } from '../input/Input';
import { Error, Label } from './styled';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { Validation } from '../../types/Validation';

interface FieldProps {
  className?: string;
  onChange: (value: string | number) => void;
  label?: string;
  placeholder?: string;
  type?: string;
  validations: Validation[];
  value?: string | number;
}

export const Field: FC<FieldProps> = ({
  className,
  onChange,
  label,
  placeholder,
  type,
  validations,
  value,
}) => {
  const [error, setError] = useState('');
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const errorMessage = getErrorMessage(validations, value);
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    onChange(value);
    setError('');
  };

  return (
    <div className={className}>
      {label && <Label error={!!error}>{label}</Label>}
      <Input
        onChange={handleChange}
        placeholder={placeholder}
        type={type}
        error={!!error}
        value={value}
      />
      <Error>{error}</Error>
    </div>
  );
};
