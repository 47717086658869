function flipped(coords: number[][] | []) {
  const flipped: any[] = [];
  coords.forEach((item) => flipped.push(item.slice().reverse()));
  return flipped;
}

export const createGeoJSONDirection = (points: number[][] | []) => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: flipped(points),
      },
    },
  ],
});
