import React, { FC } from 'react';
import { StyledInput, SavingSpan, Tick } from '../debouncedTextArea/styled';
import { Label } from '@meindach/ui-kit';
import { debounce } from 'debounce';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';
import { ReactComponent as TickMark } from '../../assets/icons/confirmed.svg';

interface Props {
  label: string;
  initialValue?: string;
  placeholder?: string;
  updating: boolean;
  savingLabel: string;
  savedLabel: string;
  onChangeEvent: (value: string) => void;
}

export const DebouncedTextArea: FC<Props> = ({
  label,
  initialValue,
  placeholder,
  updating,
  savingLabel,
  savedLabel,
  onChangeEvent,
}) => {
  const debouncedOnChange = debounce((value: any) => {
    onChangeEvent(value);
  }, 1000);
  return (
    <div>
      <Label>{label}</Label>
      {updating && (
        <SavingSpan>
          {savingLabel}&nbsp;
          <LoadingSpinner />
        </SavingSpan>
      )}
      {!updating && (
        <SavingSpan>
          {savedLabel}&nbsp;
          <Tick>
            <TickMark />
          </Tick>
        </SavingSpan>
      )}
      <StyledInput
        placeholder={placeholder}
        onChange={({ target }) => debouncedOnChange(target.value)}
        defaultValue={initialValue}
      />
    </div>
  );
};
