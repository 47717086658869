import React, { FC } from 'react';
import { ReactComponent as TickMark } from '../../assets/icons/confirmed.svg';
import { ReactComponent as ExclaimMark } from '../../assets/icons/exclaim.svg';
import { CardContainer, Info, IconView, Tick, Exclaim } from './styled';
import { useTranslation } from 'react-i18next';
import { P } from '@meindach/ui-kit';

interface Props {
  completed?: boolean;
  icon: FC;
  info: string;
  showCompleted?: boolean;
}

export const ProfileCard: FC<Props> = ({
  info,
  icon: Icon,
  completed = false,
  showCompleted = true,
}) => {
  const { t } = useTranslation('common');
  return (
    <CardContainer>
      <Icon />
      <Info>
        <P>{info}</P>
        <div>
          {showCompleted &&
            (completed ? (
              <P>{t('complete')}</P>
            ) : (
              <Exclaim>
                <P>{t('incomplete')}</P>
              </Exclaim>
            ))}
        </div>
      </Info>
      {showCompleted && (
        <IconView>
          {completed ? (
            <Tick>
              <TickMark />
            </Tick>
          ) : (
            <Exclaim>
              <ExclaimMark />
            </Exclaim>
          )}
        </IconView>
      )}
    </CardContainer>
  );
};
