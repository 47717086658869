import Rollbar from 'rollbar';

const accessToken = process.env.REACT_APP_ROLLBAR_TOKEN;
const environment = process.env.REACT_APP_ROLLBAR_ENV;

new Rollbar({
  accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: { environment },
  enabled: !!accessToken,
});
