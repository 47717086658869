import { sendPasswordRecovery } from '../api/sendPasswordRecovery';
import { useCallback, useState } from 'react';

interface State {
  isSuccess?: boolean;
  isSending?: boolean;
  error?: string;
}

export const usePasswordRecovery = () => {
  const [state, setState] = useState<State>({});
  const { isSuccess = false, isSending = false, error } = state;

  const recoverPassword = useCallback(
    async (email: string) => {
      setState({ isSending: true });
      try {
        const response = await sendPasswordRecovery(email);
        if (response.ok) {
          setState({ isSuccess: true });
        } else {
          setState({ error: response.statusText });
        }
      } catch {
        setState({ error: 'API error' });
      }
    },
    [setState],
  );

  return { recoverPassword, isSuccess, isSending, error };
};
