import styled, { createGlobalStyle } from '../../styled-components';
import { PageContainer } from '../../layout/styled';
import { media, Button, Input, Link, Label, P } from '@meindach/ui-kit';
import LoginBackground from '../../assets/images/loginBackground.jpg';
import { GenericInput } from '../../components/genericInput/GenericInput';
import { IconButton } from '@material-ui/core';

export const Background = createGlobalStyle`
  ${PageContainer} {
    background: url(${LoginBackground});
    background-size: cover;
    background-repeat: no-repeat;
    > section {
      background: none;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Content = styled.div`
  width: 100%;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  overflow: auto;

  background: linear-gradient(
    to right,
    ${({ theme }) => theme.color.alabaster}00,
    ${({ theme }) => theme.color.alabaster} 10%
  );

  padding: ${({ theme }) => theme.spacing.m};
  ${media.md`
    padding: ${({ theme }) => theme.spacing.l};
    padding-left: ${({ theme }) => theme.spacing.xl};
    margin-left: auto;
    max-width: 600px;
  `}

  > ${Link} {
    color: ${({ theme }) => theme.color.red};
    font-size: 15px;
    font-weight: 600;
    ${media.md`
      display: block;
      margin-top: ${({ theme }) => theme.spacing.s};
    `}
  }
  > ${P} {
    :first-of-type {
      text-align: center;
      display: none;
      ${media.md`
        display: block;
      `}
    }
    font-size: 16px;
    line-height: 22px;
  }
`;

export const StyledInput = styled(Input)`
  display: flex;
  width: calc(100% - 180px);
  margin-left: 12px;
  -webkit-text-security: disc !important;
`;

export const Title = styled.h3`
  font-weight: 600;
  color: ${({ theme }) => theme.color.slate};
  font-size: 22px;
  margin: ${({ theme }) => theme.spacing.m} 0;
`;

export const FieldInput = styled(GenericInput)`
  width: 100%;
  display: block;
  ${media.md`
    display: flex;
    align-items: center;
  `}

  > ${Label} {
    flex-shrink: 0;
    color: ${({ theme }) => theme.color.storm};
    margin-top: ${({ theme }) => theme.spacing.xs};

    font-size: 18px;
    font-weight: 400;
    ${media.md`
      width: 120px;
    `}
  }
  > input {
    margin: ${({ theme }) => theme.spacing.m} 0;
    ${media.md`
      margin: ${({ theme }) => theme.spacing.s} 0;
    `}
  }

  > p {
    /* TODO: This hides the error message, because the design doesnt have them.
    When we need to show this, it would be better to use a custom component instead of reusing the GenericInput */
    display: none;
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  align-items: center;
  ${media.md`
    align-items: center;
  `}
  > div {
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
`;

export const PasswordButton = styled(Button)`
  width: 140px;
  flex-shrink: 0;
  min-width: auto;
  margin-left: ${({ theme }) => theme.spacing.m};
  margin-bottom: 8px;
  ${media.md`
    margin-bottom: -3px;
  `}
`;

export const PasswordEye = styled(IconButton)`
  && {
    width: 48px;
    height: 48px;
    margin-left: -48px;
    margin-top: -6px;
    ${media.md`
    margin-left: -48px;
    margin-top: 5px;
  `}
  }
`;

export const Welcome = styled.div`
  display: flex;
  ${media.md`
    justify-content: center;
  `}
  > svg {
    display: none;
    ${media.md`
      margin-right: ${({ theme }) => theme.spacing.m};
      display: block;
    `}
    height: 80px;
  }
  > div {
    font-weight: 600;
    color: ${({ theme }) => theme.color.storm};
    font-size: 14px;
    ${media.md`
      display: flex;
      flex-direction: column;
      font-size: 18px;
      justify-content: space-between;
      height: 80px;
    `}
    > b {
      white-space: nowrap;
      color: ${({ theme }) => theme.color.red};
      font-size: 18px;
      ${media.md`
        font-size: 30px;
      `}
    }
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
`;

export const SignUpButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing.s} auto 0;
  > span > svg {
    transform: rotate(-90deg);
    margin: 0;
    margin-left: 8px;
    margin-bottom: 1px;
  }
`;
