import styled from '../../styled-components';
import { media } from '@meindach/ui-kit/dist';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  ${media.md`
    padding: ${({ theme }) => theme.spacing.l};
  `}
`;
