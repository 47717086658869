import styled, { keyframes } from '../../styled-components';
import { hexToRGBA } from '@meindach/ui-kit';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: auto;
  float: right;
  border: 2px solid ${({ theme }) => hexToRGBA(theme.color.red, 0.2)};
  border-left: 2px solid ${({ theme }) => theme.color.red};
  transform: translateZ(0);
  animation: ${rotation} 1.1s infinite linear;
`;
