import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@meindach/ui-kit';
import { Form, Formik } from 'formik';
import { GenericInput } from '../../components/genericInput/GenericInput';
import { useRoofer } from '../../hooks/useRoofer';
import { Route } from '../../types/Route';
import * as Yup from 'yup';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { TextArea } from '../../components/readOnlyText/TextArea';
import { ProfileHeader } from '../../components/profileHeader/Header';
import { Container, FormContainer } from '../common/common-styled';
import { ReactComponent as Envelope } from '../../assets/icons/envelope.svg';
import { Status } from '../../components/status/Status';
import { getFillPercent } from '../../utils/getFillPercent';
import { contactFields } from '../../data/requiredFields';

export const ContactDetails = () => {
  const { t } = useTranslation('contactDetails');
  const { roofer, pending, update } = useRoofer();
  const completed = getFillPercent(contactFields, roofer) === 100;

  if (pending || !roofer) {
    return <PageLoading />;
  }

  const initialValues = {
    email: roofer.email,
    phone: roofer.phone,
    mobile: roofer.mobile,
    website: roofer.website,
  };
  const onSubmit = (values: any) => update({ ...roofer, ...values });

  const contactDetailsValidation = Yup.object().shape({
    phone: Yup.string()
      .required(t('common:required'))
      .matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s\\./0-9]*$/, t('invalid')),
    mobile: Yup.string().matches(
      /^[+]*[(]?[0-9]{1,4}[)]?[-\s\\./0-9]*$/,
      t('invalid'),
    ),
  });
  return (
    <Container>
      <ProfileHeader
        header={t(`pageTitle:${Route.ContactDetails}`)}
        image={Envelope}
        route={Route.Profile}
      />
      <FormContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={contactDetailsValidation}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <Form>
            <TextArea label={t('email')} name="email" />
            <GenericInput label={`* ${t('phone')}`} name="phone" />
            <GenericInput label={t('mobile')} name="mobile" />
            <GenericInput label={t('website')} name="website" />
            <Button type="submit">{t('buttonText')}</Button>
          </Form>
        </Formik>
        <Status completed={completed} />
      </FormContainer>
    </Container>
  );
};
