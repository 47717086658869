import { Coordinates } from './Coordinates';

interface FileData {
  // Used to display image
  url?: string;
  // Used when submitting image data to the back-end
  path?: string;
}

export enum Partner {
  Braas = 'braas',
  Icopal = 'icopal',
  Vedag = 'vedag',
  Wolfin = 'wolfin',
}

export enum Service {
  Solar = 'solar',
  Photovoltaics = 'photovoltaics',
  SolarThermalEnergy = 'solar_thermal_energy',
  FlatRoof = 'flat_roof',
  PitchedRoof = 'pitched_roof',
  ConstructionPlumber = 'construction_plumber',
  RoofTopWindow = 'roof_top_window',
  FacadeCladding = 'facade_cladding',
  Scaffolding = 'scaffolding',
  Carpentry = 'carpentry',
  RoofExtension = 'roof_extension',
}

export enum Material {
  Clay = 'clay',
  FibreCement = 'fiber_cement',
  Metal = 'metal',
  Slate = 'slate',
}

export interface Roofer {
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  partner: Partner[];
  email: string;

  jobDescription: string;
  street: string;
  city: string;
  postalCode: string;
  consultant: string;
  masterCraftsmanCertificate: boolean;

  mobile?: string;
  website?: string;

  maxDistance: string;
  latLon: Coordinates;
  incorporationYear: number;

  services: Service[];
  material: Material[];
  asbestosCertificate?: boolean;
  energyConsultant?: boolean;
  energyConsultantName?: string;

  companyLogo?: FileData;
  profile?: FileData;

  termsAccepted: boolean;
  accountId: string;
}
