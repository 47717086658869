import React, { FC, ReactComponentElement } from 'react';
import { ReactComponent as Repair } from '../../assets/icons/jobRepair.svg';
import { ReactComponent as Expand } from '../../assets/icons/expand.svg';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as LocationPin } from '../../assets/icons/locationPin.svg';
import { ReactComponent as Exclaim } from '../../assets/icons/exclaim.svg';
import { ReactComponent as WrenchCircled } from '../../assets/icons/wrenchCircled.svg';
import { ReactComponent as ThumbsUp } from '../../assets/icons/thumbsUpCircled.svg';
import { ReactComponent as Car } from '../../assets/icons/car.svg';
import { ReactComponent as Chevron } from '@meindach/ui-kit/dist/assets/ui/chevronDown.svg';
import {
  CardDetails,
  CardFooter,
  CardHeader,
  CardMiddle,
  DeleteContainer,
  DeleteButton,
  DeleteIcon,
  LargeTileContainer,
  LargeTileInnerContainer,
  LeftSpacer,
} from './styled';
import { Coordinates } from '../../types/Coordinates';
import { useDirections } from '../../hooks/useDirections';
import { useRoofer } from '../../hooks/useRoofer';

export type State = 'normal' | 'nag' | 'complete' | 'lost';

interface Props {
  selected: boolean;
  state: State;
  title: string;
  street?: string;
  city?: string;
  postalCode: string;
  customerLatLon: Coordinates;
  area: string;
  startDate: string;
  cardText: string;
  onClick?: () => void;
  onDeleteClick?: () => void;
  icon?: ReactComponentElement<any>;
}

export const LargeTile: FC<Props> = ({
  selected,
  state,
  title,
  street,
  city,
  postalCode,
  customerLatLon,
  area,
  startDate,
  cardText,
  onClick,
  onDeleteClick,
  icon,
}) => {
  const { roofer } = useRoofer();
  const { directions } = useDirections(customerLatLon, roofer!.latLon);

  const getIcon = () => {
    if (icon) {
      return icon;
    }
    switch (state) {
      case 'normal':
        return <WrenchCircled />;
      case 'nag':
        return <Exclaim />;
      case 'complete':
        return <ThumbsUp />;
      case 'lost':
        return <LeftSpacer />;
    }
  };

  return (
    <LargeTileContainer selected={selected} state={state} onClick={onClick}>
      <LargeTileInnerContainer>
        <CardDetails>
          <div>
            <Repair />
          </div>
          <div>
            <CardHeader>
              <span>{title}</span>
            </CardHeader>
            <CardMiddle>
              <span>
                <LocationPin />
                {street ? `${street}, ` : undefined}
                {city ? `${city}, ` : undefined}
                {postalCode}
              </span>
              <span>
                <Car />
                {`${directions?.routeDistance}`}
              </span>
              <span>
                <Expand />
                {area}
              </span>
              <span>
                <Calendar />
                {startDate}
              </span>
            </CardMiddle>
          </div>
        </CardDetails>
        <CardFooter>
          <div>{getIcon()}</div>
          <div>{cardText}</div>
        </CardFooter>
        {state === 'lost' && (
          <DeleteContainer>
            <DeleteButton onClick={onDeleteClick}>
              <DeleteIcon />
            </DeleteButton>
          </DeleteContainer>
        )}
      </LargeTileInnerContainer>
      <div>
        <Chevron />
      </div>
    </LargeTileContainer>
  );
};
