import React, { FC } from 'react';
import { ButtonContainer, StyledButtons } from './styled';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/useModal';
import { AcceptOpportunityModal } from './AcceptOpportunityModal';
import { DeclineOpportunityModal } from './DeclineOpportunityModal';
import { Card } from '../card/Card';
import { Project } from '../../types/Project';

interface Props {
  project: Project;
}

export const AcceptOpportunity: FC<Props> = ({ project }) => {
  const { t } = useTranslation('acceptOrDecline');
  const { show: showAccept, toggle: toggleAccept } = useModal();
  const { show: showDecline, toggle: toggleDecline } = useModal();

  return (
    <Card title={t('title')}>
      <p> {t('description')} </p>
      <ButtonContainer>
        <StyledButtons onClick={toggleAccept}>{t('accept')}</StyledButtons>
        <StyledButtons onClick={toggleDecline} emptyDanger>
          {t('decline')}
        </StyledButtons>
      </ButtonContainer>
      {showAccept && (
        <AcceptOpportunityModal toggle={toggleAccept} project={project} />
      )}
      {showDecline && (
        <DeclineOpportunityModal toggle={toggleDecline} project={project} />
      )}
    </Card>
  );
};
