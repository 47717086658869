import React, { FC, memo, useRef } from 'react';
import { ViewState } from 'react-map-gl';
import {
  StyledNavigationControl,
  StyledFullscreenControl,
  StyleSwitch,
} from './styled';
import { MapStyle } from './MapStyle';
import { ReactComponent as MapIcon } from '@meindach/ui-kit/dist/assets/icons/map.svg';
import { ReactComponent as SatelliteIcon } from '@meindach/ui-kit/dist/assets/icons/satellite.svg';

interface Props {
  onViewportChange: (vp: ViewState) => void;
  setMapStyle: (mapStyle: MapStyle) => void;
  mapStyle: MapStyle;
}

export const Controls: FC<Props> = memo(
  ({ onViewportChange, setMapStyle, mapStyle }) => {
    const ref = useRef(null);
    const toggleMapStyle = () =>
      setMapStyle(
        mapStyle === MapStyle.Default ? MapStyle.Satellite : MapStyle.Default,
      );
    return (
      <>
        <StyledNavigationControl
          onViewportChange={onViewportChange}
          showCompass={false}
        />
        <StyledFullscreenControl ref={ref} container={ref.current} />
        <StyleSwitch className="mapboxgl-ctrl-group">
          <button className="mapboxgl-ctrl-icon" onClick={toggleMapStyle}>
            {mapStyle === MapStyle.Default ? <SatelliteIcon /> : <MapIcon />}
          </button>
        </StyleSwitch>
      </>
    );
  },
);
