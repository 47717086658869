import { StyledLink, Notification } from './styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

interface Props {
  route: string;
  icon: FC;
  notificationCount?: number;
}

export const NavigationTab: FC<Props> = ({
  route,
  icon: Icon,
  notificationCount = 0,
}) => {
  const { t } = useTranslation('navigation');
  const { pathname } = useLocation();
  return (
    <StyledLink to={route} selected={pathname.startsWith(route)}>
      <Icon />
      {t(`pageTitle:${route}`)}
      {notificationCount > 0 && (
        <Notification>{notificationCount}</Notification>
      )}
    </StyledLink>
  );
};
