import React, { FC, useState } from 'react';
import { Container } from './styled';
import { Route } from '../../types/Route';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import { ReactComponent as Help } from '../../assets/icons/help.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as Opportunities } from '../../assets/icons/opportunities02.svg';
import { ReactComponent as Projects } from '../../assets/icons/jobs02.svg';
import { ReactComponent as Archive } from '../../assets/icons/archive.svg';
import { ReactComponent as Menu } from '@meindach/ui-kit/dist/assets/icons/menu.svg';
import { NavigationTab } from './NavigationTab';
import {
  aboutMeFields,
  companyFields,
  contactFields,
  servicesFields,
  uploadFields,
} from '../../data/requiredFields';
import { Roofer } from '../../types/Roofer';
import { getFillPercent } from '../../utils/getFillPercent';
import { useRoofer } from '../../hooks/useRoofer';
import { useProject } from '../../hooks/useProject';

const links: [Route, FC][] = [
  [Route.Overview, Home],
  [Route.Opportunities, Opportunities],
  [Route.Projects, Projects],
  [Route.Archive, Archive],
  [Route.Profile, Profile],
  [Route.Help, Help],
];

export const Navigation = () => {
  const profileFields = [
    aboutMeFields,
    uploadFields,
    servicesFields,
    contactFields,
    companyFields,
  ];
  const [collapsed, setCollapsed] = useState(false);
  const { roofer } = useRoofer();
  const { inquiries, projects } = useProject();
  const completed = (fields: (keyof Roofer)[]) =>
    getFillPercent(fields, roofer) === 100;
  const totalProfileFields = profileFields.filter((field) => !completed(field));
  const assignedOpportunities = inquiries.length;
  const notifiedRoutes = [
    { route: Route.Profile, count: totalProfileFields.length },
    { route: Route.Opportunities, count: assignedOpportunities },
    { route: Route.Projects, count: projects.length },
  ];

  return (
    <Container collapsed={collapsed}>
      {collapsed && <Menu onClick={() => setCollapsed(false)} />}
      {!collapsed && <Close onClick={() => setCollapsed(true)} />}
      {links.map(([route, Icon]) => (
        <NavigationTab
          route={route}
          icon={Icon}
          key={route}
          notificationCount={notifiedRoutes.reduce((result, notifiedRoute) => {
            return notifiedRoute.route === route ? notifiedRoute.count : result;
          }, 0)}
        />
      ))}
    </Container>
  );
};
