import styled, { createGlobalStyle } from '../../styled-components';
import { FullscreenControl, NavigationControl } from 'react-map-gl';

export const MarkerStyle = createGlobalStyle`
  .mapboxgl-marker {
    transform: translate(-50%, -100%);
  }
`;

export const StyledNavigationControl = styled(NavigationControl)`
  position: absolute;
  right: 6px;
  bottom: 42px;
`;

export const StyledFullscreenControl = styled(FullscreenControl)`
  position: absolute;
  right: 6px;
  bottom: 110px;
`;

export const StyleSwitch = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  > button > svg {
    width: 100%;
    height: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
`;
