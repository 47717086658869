import styled, { css } from '../../styled-components';
import { hexToRGBA, media } from '@meindach/ui-kit';

export const Container = styled.div<{ src?: string }>`
  position: relative;
  width: 100%;
  padding-top: 100%;
  ${media.md`
    width: 296px;
    height: 296px;
    padding: 0;
  `}
  background: ${({ theme }) => theme.color.mercury};
  border: 2px dashed ${({ theme }) => theme.color.silver};
  ${({ src }) =>
    src &&
    css`
      background: url(${src}) center center;
      background-size: cover;
    `};
`;

export const UploadInfo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.color.storm};

  > svg {
    margin-bottom: ${({ theme }) => theme.spacing.s};
  }
  > p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    max-width: 200px;
  }
`;

export const ReplaceInfo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  padding: ${({ theme }) => theme.spacing.m};
  box-sizing: border-box;

  color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: flex-end;

  background-image: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 50%);

  > svg {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    flex-shrink: 0;
  }
  > p {
    padding: 0 ${({ theme }) => theme.spacing.m};
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background: ${({ theme }) => hexToRGBA(theme.color.slate, 0.5)};

  display: flex;
  justify-content: center;
  align-items: center;
`;
