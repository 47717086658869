import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@meindach/ui-kit';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CheckboxInputGroup } from '../../components/checkboxInputGroup/CheckboxInputGroup';
import { GenericInput } from '../../components/genericInput/GenericInput';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { Material, Service } from '../../types/Roofer';
import { useRoofer } from '../../hooks/useRoofer';
import { Route } from '../../types/Route';
import { Container, FormContainer } from '../common/common-styled';
import { ToggleInput } from '../../components/toggleInput/ToggleInput';
import { ProfileHeader } from '../../components/profileHeader/Header';
import { ReactComponent as Bell } from '../../assets/icons/bell.svg';
import { Status } from '../../components/status/Status';
import { getFillPercent } from '../../utils/getFillPercent';
import { servicesFields } from '../../data/requiredFields';

export const OurServices = () => {
  const { t } = useTranslation('ourServices');
  const { roofer, pending, update } = useRoofer();
  const completed = getFillPercent(servicesFields, roofer) === 100;

  if (pending || !roofer) {
    return <PageLoading />;
  }

  const initialValues = {
    services: roofer.services,
    material: roofer.material,
    asbestosCertificate: !!roofer.asbestosCertificate,
    energyConsultant: !!roofer.energyConsultant,
    energyConsultantName: roofer.energyConsultantName?.trim() || '',
  };
  const onSubmit = (values: any) => update({ ...roofer, ...values });

  const servicesValidationSchema = Yup.object().shape({
    services: Yup.array()
      .of(Yup.string())
      .min(1, t('common:required'))
      .required(t('common:required')),
    material: Yup.array().of(Yup.string()),
    energyConsultant: Yup.boolean(),
    energyConsultantName: Yup.string().when('energyConsultant', {
      is: true,
      then: Yup.string().trim().required(t('common:required')),
    }),
  });

  return (
    <Container>
      <ProfileHeader
        header={t(`pageTitle:${Route.OurServices}`)}
        image={Bell}
        route={Route.Profile}
      />
      <FormContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={servicesValidationSchema}
        >
          {(formik) => (
            <Form>
              <CheckboxInputGroup
                label={`* ${t('services')}`}
                name="services"
                data={Object.entries(Service).map((value) => ({
                  value: value[1],
                  text: t(`service:${value[1]}`),
                }))}
              />
              <CheckboxInputGroup
                label={t('material')}
                name="material"
                data={Object.entries(Material).map((value) => ({
                  value: value[1],
                  text: t(`material:${value[1]}`),
                }))}
              />
              <ToggleInput
                text={t('asbestosCertificate')}
                name="asbestosCertificate"
              />
              <ToggleInput text={t('energyConsultant')} name="energyConsultant">
                <GenericInput
                  label={`* ${t('energyConsultantName')}`}
                  name="energyConsultantName"
                  placeholder={'NONE'}
                  onBlur={(event) => {
                    formik.setFieldValue(
                      event.target.name,
                      event.target.value.trim(),
                    );
                  }}
                />
              </ToggleInput>
              <Button type="submit">{t('buttonText')}</Button>
            </Form>
          )}
        </Formik>
        <Status completed={completed} />
      </FormContainer>
    </Container>
  );
};
