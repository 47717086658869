import styled from '../../styled-components';

export const ContainerCard = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 10px;
`;

export const ContainerMap = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  box-sizing: border-box;
  height: 300px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.color.mercury};
  > div {
    display: flex;
    flex: 1;
  }
`;

export const Driving = styled.div`
  > svg {
    fill: ${({ theme }) => theme.color.silver};
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`;
