import { useAuth } from './useAuth';
import { createStore, useStore } from 'react-hookstore';
import { useCallback, useEffect } from 'react';
import { Store } from '../types/Store';
import { getRoofer } from '../api/getRoofer';
import { Roofer } from '../types/Roofer';
import { updateRoofer } from '../api/updateRoofer';
import { convertLatLon } from '../utils/convertLatLon';
import { RooferImageType } from '../types/RooferImageType';
import { getRooferImage as getImageApi } from '../api/getRooferImage';
import { uploadImage as uploadImageApi } from '../api/uploadImage';
import { config } from '../config';

interface State {
  roofer?: Roofer;
  profile?: string;
  companyLogo?: string;
  pending?: boolean;
  error?: boolean;
  updateError?: boolean;
  getImagePending?: boolean;
  getImageError?: boolean;
  uploadImagePending?: boolean;
  uploadImageError?: boolean;
}

export const store = createStore<State>(Store.Roofer, {});
const { getState, setState } = store;

const fetchRoofer = async (token: string, logout: () => Promise<void>) => {
  setState({ ...getState(), error: false, pending: true });
  try {
    const request = await getRoofer(token);
    if (request.ok) {
      const roofer = await request.json();
      setState({
        ...getState(),
        roofer: {
          ...roofer,
          latLon: convertLatLon(roofer.latLon),
        },
        pending: false,
      });
      if (roofer.profile) {
        await fetchProfile(token, roofer.accountId);
      }
      if (roofer.companyLogo) {
        await fetchCompanyLogo(token, roofer.accountId);
      }
      return;
    }
    if (request.status === 401) {
      logout();
    }
  } catch {}
  setState({ ...getState(), pending: false, error: true });
};

const fetchProfile = async (token: string, rooferId: string) => {
  const profileRequest = await getImageApi(
    token,
    rooferId,
    RooferImageType.Profile,
  );
  if (profileRequest.ok) {
    setState({
      ...getState(),
      profile: URL.createObjectURL(await profileRequest.blob()),
    });
  }
};

const fetchCompanyLogo = async (token: string, rooferId: string) => {
  const companyLogoRequest = await getImageApi(
    token,
    rooferId,
    RooferImageType.CompanyLogo,
  );
  if (companyLogoRequest.ok) {
    const blob = await companyLogoRequest.blob();
    const url = URL.createObjectURL(blob);
    setState({
      ...getState(),
      companyLogo: url,
    });
  }
};

export const useRoofer = () => {
  const [state] = useStore<State>(Store.Roofer);
  const {
    roofer,
    profile,
    companyLogo,
    pending = false,
    error = false,
    updateError = false,
    uploadImagePending = false,
    uploadImageError = false,
  } = state;
  const { token, logout } = useAuth();

  useEffect(() => {
    if (token) {
      const { roofer, pending } = getState();
      if (!roofer && !pending) {
        fetchRoofer(token, logout);
      }
    } else if (roofer) {
      setState({});
    }
  }, [token, roofer, error, logout]);

  const update = useCallback(
    async (updatedRoofer: Roofer) => {
      if (!token) {
        setState({ ...getState(), updateError: true });
        return;
      }
      setState({ ...getState(), updateError: false, pending: true });
      try {
        const response = await updateRoofer(token, updatedRoofer);
        if (response.ok) {
          await fetchRoofer(token, logout);
          return;
        }
      } catch {}
      setState({ ...getState(), updateError: true });
    },
    [token, logout],
  );

  const uploadImage = useCallback(
    async (type: RooferImageType, file: File) => {
      if (!token) {
        setState({ ...getState(), uploadImageError: true });
        return;
      }
      setState({
        ...getState(),
        uploadImageError: false,
        uploadImagePending: true,
      });
      try {
        const request = await uploadImageApi(
          token,
          roofer!.accountId,
          type,
          file,
        );
        if (request.ok) {
          let updatedRoofer = { ...roofer } as Roofer;
          let promise;
          if (type === RooferImageType.Profile) {
            promise = fetchProfile(token, roofer!.accountId);
            updatedRoofer.profile = {
              url: `${config.apiBaseUrl}/api/v1/roofer/${
                roofer!.accountId
              }/image/${RooferImageType.Profile}`,
            };
          } else {
            promise = fetchCompanyLogo(token, roofer!.accountId);
            updatedRoofer.companyLogo = {
              url: `${config.apiBaseUrl}/api/v1/roofer/${
                roofer!.accountId
              }/image/${RooferImageType.CompanyLogo}`,
            };
          }
          setState({
            ...getState(),
            roofer: updatedRoofer,
            uploadImagePending: false,
          });
          await promise;
          return;
        }
      } catch {}
      setState({
        ...getState(),
        uploadImageError: true,
        uploadImagePending: false,
      });
    },
    [token, roofer],
  );

  return {
    roofer,
    profile,
    companyLogo,
    pending,
    error,
    update,
    updateError,
    uploadImage,
    uploadImagePending,
    uploadImageError,
  };
};
