import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@meindach/ui-kit';
import { Form, Formik } from 'formik';
import { GenericInput } from '../../components/genericInput/GenericInput';
import { CheckboxInputGroup } from '../../components/checkboxInputGroup/CheckboxInputGroup';
import { useRoofer } from '../../hooks/useRoofer';
import { Route } from '../../types/Route';
import * as Yup from 'yup';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { ProfileHeader } from '../../components/profileHeader/Header';
import { ReactComponent as Company } from '../../assets/icons/company.svg';
import { Container, FormContainer } from '../common/common-styled';
import { Status } from '../../components/status/Status';
import { getFillPercent } from '../../utils/getFillPercent';
import { companyFields } from '../../data/requiredFields';
import { Partner } from '../../types/Roofer';

export const CompanyDetails = () => {
  const { t } = useTranslation('companyDetails');
  const { roofer, pending, update } = useRoofer();
  const completed = getFillPercent(companyFields, roofer) === 100;

  if (pending || !roofer) {
    return <PageLoading />;
  }

  const initialValues = {
    partner: roofer.partner,
    maxDistance: `${roofer.maxDistance}`,
    incorporationYear: roofer.incorporationYear,
  };
  const onSubmit = (values: any) => update({ ...roofer, ...values });

  const companyDetailsValidation = Yup.object().shape({
    incorporationYear: Yup.number().typeError(t('invalid')),
    partner: Yup.array()
      .of(Yup.string())
      .min(1, t('common:required'))
      .required(t('common:required')),
    maxDistance: Yup.string().required(t('common:required')),
  });

  return (
    <Container>
      <ProfileHeader
        header={t(`pageTitle:${Route.CompanyDetails}`)}
        image={Company}
        route={Route.Profile}
      />
      <FormContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={companyDetailsValidation}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <Form>
            <CheckboxInputGroup
              label={`* ${t('partner')}`}
              name="partner"
              data={Object.entries(Partner).map((value) => ({
                value: value[1],
                text: t(`partner:${value[1]}`),
              }))}
            />
            <GenericInput
              placeholder={t('selectMaxDistance')}
              label={`* ${t('maxDistance')}`}
              name="maxDistance"
              type="number"
            />
            <GenericInput
              label={t('incorporationYear')}
              name="incorporationYear"
              type="number"
            />
            <Button type="submit">{t('buttonText')}</Button>
          </Form>
        </Formik>
        <Status completed={completed} />
      </FormContainer>
    </Container>
  );
};
