import { config } from '../config';
import { getHeaders } from './getHeaders';
import { RooferProjectEntity } from '../types/RooferProjectEntity';

export const updateProjectEntityNotes = (
  token: string,
  rooferId: string,
  projectEntity: RooferProjectEntity,
) =>
  fetch(
    `${config.classificationUrl}/api/v1/roofer/${rooferId}/project/${projectEntity.project.opportunity.id}/notes`,
    {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(projectEntity),
    },
  );
