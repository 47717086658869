import { Avatar, hexToRGBA, media } from '@meindach/ui-kit';
import styled from '../../styled-components';

const headerHeightMobile = `70px`;
const headerHeightDesktop = `100px`;

export const StyledHeader = styled.header`
  flex: 0 0 auto;
  width: 100%;
  box-sizing: border-box;
  height: ${headerHeightMobile};
  ${media.md`
    height: ${headerHeightDesktop};
  `}
  background: ${({ theme }) => theme.color.red};
`;

export const StyledAvatar = styled(Avatar)`
  color: ${({ theme }) => theme.color.white};
  width: 32px;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  display: block;
  background: ${({ theme }) => hexToRGBA(theme.color.white, 0.25)};
  ${media.md`
      margin-right: ${({ theme }) => theme.spacing.s};
    `}

  font-weight: 600;
  text-shadow: 0 6px 1px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.15),
    0 1px 3px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(0, 0, 0, 0.3),
    0 10px 10px rgba(0, 0, 0, 0.3), 0 20px 20px rgba(0, 0, 0, 0.2);

  > div {
    border: none;
  }

  ${media.md`
    width: 65px;
    height: 65px;
    font-size: 40px;
    line-height: 65px;
  `}
`;

export const Container = styled.div`
  max-width: ${({ theme }) => theme.layout.maxWidth};
  margin: 0 auto;
  position: relative;

  > a:first-child {
    position: absolute;
    left: ${({ theme }) => theme.spacing.m};
    top: 0;
    display: flex;
    flex-direction: column-reverse;
    height: ${headerHeightMobile};
    ${media.md`
      height: ${headerHeightDesktop};
    `}
    > svg {
      margin: auto;
      width: 50px;
      ${media.md`
        width: 70px;
      `}
    }
  }
  > div:last-child {
    position: absolute;
    top: 0;
    right: ${({ theme }) => theme.spacing.m};
    height: ${headerHeightMobile};
    ${media.md`
      height: ${headerHeightDesktop};
    `}
    display: flex;
    align-items: center;
    font-size: 16px;

    > a {
      color: ${({ theme }) => theme.color.white};
      display: flex;
      align-items: center;
    }
  }
`;

export const Title = styled.h1`
  line-height: ${headerHeightMobile};
  ${media.md`
    line-height: ${headerHeightDesktop};
  `}
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.white};
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  padding: 0 76px;
  ${media.md`
    text-align: left;
    font-size: 24px;
    padding: 0 116px;
  `}
`;
