import React, { FC } from 'react';
import { useField } from 'formik';
import {
  Container,
  ErrorText,
  HiddenCheckbox,
  Option,
  StyledCheckbox,
  HiddenInput,
  Text,
} from './styled';
import { ReactComponent as Tick } from '../../assets/icons/tick.svg';

interface Props {
  text: string;
  name: string;
}

export const ToggleInput: FC<Props> = ({ text, name, children }) => {
  const [field, meta] = useField({
    name,
    type: 'checkbox',
  });

  return (
    <Container>
      {meta.error && <ErrorText>{meta.error}</ErrorText>}
      <Option>
        <StyledCheckbox checked={!!field.checked}>
          <Tick />
        </StyledCheckbox>
        <Text>{text}</Text>
        <HiddenCheckbox {...field} value={`${field.value}`} />
      </Option>
      {!!field.checked && children && <HiddenInput>{children}</HiddenInput>}
    </Container>
  );
};
