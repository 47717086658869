import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Title, Info, Overlay, Email } from './styled';
import { Formik } from 'formik';
import { usePasswordRecovery } from '../../hooks/usePasswordRecovery';
import * as Yup from 'yup';
import { ReactComponent as Icon } from '../../assets/icons/forgotPassword.svg';
import { ReactComponent as EmailSentIcon } from '../../assets/icons/emailSent.svg';
import { PasswordRecoveryForm } from './PasswordRecoveryForm';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Route } from '../../types/Route';

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9]{2,5}/i;

export const PasswordRecovery = () => {
  const [email, setEmail] = useState<string>();
  const { t } = useTranslation('passwordRecovery');
  const {
    recoverPassword,
    isSending,
    isSuccess,
    error,
  } = usePasswordRecovery();
  const history = useHistory();

  useEffect(() => {
    if (isSuccess) {
      const timeout = setTimeout(() => history.push(Route.Login), 5000);
      return () => clearTimeout(timeout);
    }
  }, [isSuccess, history]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailPattern, t('invalidEmail'))
      .required(t('emptyEmail')),
  });

  if (isSuccess) {
    return (
      <Container centered>
        <EmailSentIcon />
        <Info>{t('emailSentTitle')}</Info>
        <Email>{email}</Email>
        <Info>{t('emailSentInfo')}</Info>
        <Info>{t('emailSentInfo2')}</Info>
      </Container>
    );
  }

  const onSubmit = ({ email }: { email: string }) => {
    setEmail(email);
    recoverPassword(email);
  };
  return (
    <Container>
      {isSending && (
        <Overlay>
          <PageLoading />
        </Overlay>
      )}
      <Title>{t('title')}</Title>
      <Icon />
      <Info>{t('info')}</Info>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <PasswordRecoveryForm isSending={isSending} error={error} />
      </Formik>
    </Container>
  );
};
