import { Roofer } from '../types/Roofer';

const isTruthy = (value: boolean | string) =>
  value === false ? true : !!value;

const fieldIsComplete = (value: any) =>
  value && typeof value === 'object'
    ? Object.keys(value).length > 0
    : isTruthy(value);

export const getFillPercent = (fields: (keyof Roofer)[], roofer?: Roofer) =>
  roofer
    ? Math.round(
        (fields.filter((key) => fieldIsComplete(roofer[key])).length /
          fields.length) *
          100,
      )
    : 0;
