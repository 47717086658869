import React, { FC, memo } from 'react';
import { Roofer } from '../../types/Roofer';
import { StyledAvatar } from './styled';
import { useRoofer } from '../../hooks/useRoofer';

interface Props {
  roofer?: Roofer;
}

export const RooferAvatar: FC<Props> = memo(() => {
  const { roofer, profile } = useRoofer();
  return (
    <StyledAvatar
      firstName={roofer && roofer.firstName}
      lastName={roofer && roofer.lastName}
      src={profile}
      small
    />
  );
});
