import { Container, Content, InnerContainer, TitleText } from './styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { P, Title } from '@meindach/ui-kit';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { Link } from '../../components/link/styled';

export const Help = () => {
  const { t } = useTranslation('help');

  return (
    <Container>
      <Content>
        <Title xl>{t('title')}</Title>
        <TitleText>
          <P>{t('titleText')}</P>
        </TitleText>
        <InnerContainer>
          <EmailIcon />
          <Link href={'mailto:dachdecker@meindach.de'} target="_blank">
            {'dachdecker@meindach.de'}
          </Link>
        </InnerContainer>
        <InnerContainer>
          <PhoneIcon />
          <Link href={'tel:015730378852'} target="_blank">
            {'01573 0378852'}
          </Link>
        </InnerContainer>
      </Content>
    </Container>
  );
};
