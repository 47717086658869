import React, { FC } from 'react';
import { Marker } from 'react-map-gl';
import { Pin } from './styled';
import { ReactComponent as HomeIcon } from '@meindach/ui-kit/dist/assets/map/home-pin.svg';

interface Props {
  lat: number;
  lon: number;
}

export const OpportunityMarker: FC<Props> = ({ lon, lat }) => (
  <Marker longitude={lon} latitude={lat} offsetLeft={-12} offsetTop={-35}>
    <Pin>
      <HomeIcon />
    </Pin>
  </Marker>
);
