import React, { FC } from 'react';
import { useField } from 'formik';
import { Label } from '@meindach/ui-kit';
import {
  Container,
  HiddenCheckbox,
  StyledCheckbox,
  Text,
  ErrorText,
  Option,
} from './styled';
import { ReactComponent as Tick } from '../../assets/icons/tick.svg';

interface Data {
  value: string;
  text: string;
}

interface CheckboxInputGroupProps {
  label: string;
  name: string;
  data: Data[];
}

export const CheckboxInputGroup: FC<CheckboxInputGroupProps> = ({
  label,
  name,
  data,
}) => {
  const [field, meta] = useField({
    name: name,
  });

  const checkboxes = data.map(({ value, text }) => (
    <CheckboxInput key={value} name={name} value={value} text={text} />
  ));

  return (
    <Container>
      {meta.error && <ErrorText>{meta.error}</ErrorText>}
      <Label htmlFor={field.name}>{label}</Label>
      {checkboxes}
    </Container>
  );
};

interface CheckboxInputProps {
  name: string;
  value: string;
  text: string;
}

const CheckboxInput: FC<CheckboxInputProps> = (props: CheckboxInputProps) => {
  const { name, value, text } = props;
  const [field] = useField({
    name,
    value,
    type: 'checkbox',
  });

  return (
    <Option>
      <StyledCheckbox checked={!!field.checked}>
        <Tick />
      </StyledCheckbox>
      <Text>{text}</Text>
      <HiddenCheckbox {...field} {...props} />
    </Option>
  );
};
