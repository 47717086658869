import styled from '../../styled-components';

export const StyledInput = styled.textarea`
  margin-bottom: 25px;
  width: 100%;
  height: 150px;
`;

export const SavingSpan = styled.span`
  padding-top: 5px;
  float: right;
`;

export const Tick = styled.div`
  float: right;
  height: 20px;
  color: ${({ theme }) => theme.color.blue400};
  > svg {
    width: 20px;
    height: 20px;
  }
`;
