import { config } from '../config';
import { getHeaders } from './getHeaders';

export const deleteProjectEntityImage = (
  token: string,
  rooferId: string,
  projectId: string,
  imageId: string,
) => {
  return fetch(
    `${config.classificationUrl}/api/v1/roofer/${rooferId}/project/${projectId}/image/${imageId}`,
    {
      method: 'DELETE',
      headers: getHeaders(token),
    },
  );
};
