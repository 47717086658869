import React, { FC, ReactNode } from 'react';
import { Container, Title, Text, Tick, Exclaim, Lost } from './styled';
import { ReactComponent as Chevron } from '@meindach/ui-kit/dist/assets/ui/chevronDown.svg';

interface Props {
  icon: FC;
  title: string;
  text: string | ReactNode;
  showChevron?: boolean;
  completed?: boolean;
  lost?: boolean;
}

export const ListItem: FC<Props> = ({
  icon: Icon,
  title,
  text,
  showChevron = true,
  completed = true,
  lost = false,
}) => (
  <Container lost={lost}>
    <div>
      {completed ? (
        <Tick>
          <Icon />
        </Tick>
      ) : lost ? (
        <Lost>
          <Icon />
        </Lost>
      ) : (
        <Exclaim>
          <Icon />
        </Exclaim>
      )}
    </div>
    <div>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </div>
    <div>{showChevron && <Chevron />}</div>
  </Container>
);
