import styled from '../../styled-components';
import { css } from 'styled-components';
import { media, touchSupport } from '@meindach/ui-kit';

export const ErrorText = styled.p`
  line-height: 50px;
  width: 100%;
  padding: 0px;
  color: ${({ theme }) => theme.color.error};
  margin: 0;
  font-size: 24px;
  text-align: left;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
  opacity: 0;
  overflow: hidden;
  width: 0;
  height: 0;
`;

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  background: ${({ checked, theme }) =>
    checked ? theme.color.red : theme.color.white};
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.color.midnight : theme.color.silver};
  transition: all 150ms;

  ${({ checked }) =>
    !checked &&
    css`
      > svg {
        display: none;
      }
    `}
`;

export const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  padding-left: 15px;
`;

export const Option = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px;
  cursor: pointer;
  width: fit-content;

  ${!touchSupport &&
  media.md`
      :hover ${StyledCheckbox} {
        background: ${({ theme }) => theme.color.silver};
      }
    `}
`;
