import { SalesStage } from '../types/ProjectEntity';
import { Stage } from '../types/Project';
import { assertUnreachable } from './assertUnreachable';

export const mapToStage = (salesStage: SalesStage): Stage => {
  switch (salesStage) {
    case SalesStage.RooferPicking:
      return Stage.NotAccepted;
    case SalesStage.Inspection:
      return Stage.SiteVisit;
    case SalesStage.ProposalCreation:
      return Stage.Quotation;
    case SalesStage.ProposalCheck:
      return Stage.Contract;
    case SalesStage.ProposalTracking:
      return Stage.Contract;
    case SalesStage.ProposalGranted:
      return Stage.Contract;
    case SalesStage.ProposalDeclined:
      return Stage.Contract;
    case SalesStage.ContractCreation:
      return Stage.Contract;
    case SalesStage.Execution:
      return Stage.RoofWork;
    case SalesStage.Cleared:
      return Stage.Invoice;
    case SalesStage.ClosedWon:
      return Stage.Complete;
    case SalesStage.ClosedLost:
      return Stage.Archived;
    case SalesStage.Completion:
      return Stage.Archived;
    case SalesStage.GiveAway:
      return Stage.Archived;
  }
  return assertUnreachable(salesStage);
};

export const mapToSalesStage = (stage: Stage): SalesStage => {
  switch (stage) {
    case Stage.NotAccepted:
      return SalesStage.RooferPicking;
    case Stage.Accepted:
      return SalesStage.RooferPicking;
    case Stage.SiteVisit:
      return SalesStage.Inspection;
    case Stage.Quotation:
      return SalesStage.ProposalCreation;
    case Stage.QuotationCheck:
      return SalesStage.ProposalCheck;
    case Stage.Contract:
      return SalesStage.ContractCreation;
    case Stage.RoofWork:
      return SalesStage.Execution;
    case Stage.Invoice:
      return SalesStage.Cleared;
    case Stage.Complete:
      return SalesStage.ClosedWon;
    case Stage.Archived:
      return SalesStage.ClosedLost;
  }
  return assertUnreachable(stage);
};
