import styled from '../styled-components';
import { media } from '@meindach/ui-kit';

export const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background: #f5f5f5;
  flex: 1;
  overflow: hidden;

  ${media.md`
      background: linear-gradient(
        to right,
        ${({ theme }) => theme.color.fog} 50%,
        #f5f5f5 50%
      );
      padding-bottom: 0;
      > section {
        flex-direction: row;
      }
   `}

  > section {
    background: #f5f5f5;
    height: 100%;
    width: 100%;
    max-width: ${({ theme }) => theme.layout.maxWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    ${media.md`
      flex-direction: row;
      justify-content: initial;
    `}
  }
`;

export const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  transform: translateZ(0px);
`;
