import { fromJS } from 'immutable';
import MAP_STYLE from '../../assets/map/map-style-light-v10.json';
import SATELLITE_MAP_STYLE from '../../assets/map/map-style-satellite-v9.json';
import { theme } from '@meindach/ui-kit';

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
const dataLayers = [
  {
    id: 'radius',
    type: 'line',
    source: 'dataSource',
    layout: {},
    interactive: false,
    paint: {
      'line-color': theme.color.slate,
      'line-width': 3,
      'line-opacity': 0.6,
    },
  },
  {
    id: 'route',
    type: 'line',
    source: 'directions',
    layout: {},
    interactive: false,
    paint: {
      'line-color': theme.color.success,
      'line-width': 8,
      'line-opacity': 0.8,
    },
  },
];

const defaultMapStyle = fromJS(MAP_STYLE);
const satelliteMapStyle = fromJS(SATELLITE_MAP_STYLE);

export const dataMapStyle = defaultMapStyle.set(
  'layers',
  defaultMapStyle.get('layers').push(...dataLayers),
);

export const dataMapStyleSatellite = satelliteMapStyle.set(
  'layers',
  satelliteMapStyle.get('layers').push(...dataLayers),
);
