import React, { FC } from 'react';
import { GlobalStyle } from './GlobalStyle';
import { PageContainer, ScreenContainer } from './styled';
import { Header } from '../components/header/Header';
import { Footer } from '../components/footer/Footer';
import { usePlatform } from '@meindach/ui-kit';
import { useAuth } from '../hooks/useAuth';
import { Navigation } from '../components/navigation/Navigation';

export const Layout: FC = ({ children }) => {
  const { token } = useAuth();
  const { desktop } = usePlatform();
  return (
    <ScreenContainer>
      <GlobalStyle />
      <Header />
      <PageContainer>
        <section>
          {token && <Navigation />}
          {children}
        </section>
      </PageContainer>
      {desktop && <Footer />}
    </ScreenContainer>
  );
};
