import { config } from '../config';
import { getHeaders } from './getHeaders';
import { Roofer } from '../types/Roofer';

export const updateRoofer = (token: string, roofer: Roofer) =>
  fetch(`${config.apiBaseUrl}/api/v1/roofer/me`, {
    method: 'PUT',
    headers: getHeaders(token),
    body: JSON.stringify(roofer),
  });
