import React, { ChangeEvent, FC } from 'react';
import { useField } from 'formik';
import { Label, Option } from '@meindach/ui-kit';
import { DropDown, ErrorText } from './styled';
import { Select } from '../select/styled';

interface Props {
  placeholder: string;
  label: string;
  name: string;
  options: Option[];
}

export const SelectOptions: FC<Props> = ({
  placeholder,
  label,
  name,
  options,
}) => {
  const [field, meta] = useField<any>({ name, type: 'select' });
  return (
    <DropDown>
      <Label>{label}</Label>
      {meta.error && <ErrorText>{meta.error}</ErrorText>}
      <Select
        placeholder={placeholder}
        name={field.name}
        value={meta.value}
        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
          field.onChange(field.name)(event.target.value)
        }
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </Select>
    </DropDown>
  );
};
