import { isRequired, isLessThan, isMoreThan } from './utils/validations';
import { GlobalStyle } from './styles/GlobalStyle';
import { media, breakpoints, Breakpoint } from './styles/media';
import { theme } from './styles/theme';
import { withTheme } from './styles/withTheme';
import { usePlatform } from './hooks/usePlatform';

export {
  GlobalStyle,
  breakpoints,
  media,
  // @ts-ignore This export affects only package build so the error can be ignored for Storybook
  Breakpoint,
  theme,
  withTheme,
};

export { usePlatform };

export const validations = {
  isRequired,
  isLessThan,
  isMoreThan,
};

export * from './components';
export * from './utils';
