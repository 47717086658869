import React, { FC } from 'react';
import { ReactComponent as TickMark } from '../../assets/icons/confirmed.svg';
import { ReactComponent as ExclaimMark } from '../../assets/icons/exclaim.svg';
import { useTranslation } from 'react-i18next';
import { Container } from './styled';

interface Props {
  completed: boolean;
}

export const Status: FC<Props> = ({ completed }) => {
  const { t } = useTranslation('common');

  return (
    <Container completed={completed}>
      <p>{completed ? t('complete') : t('incomplete')}</p>
      {completed ? <TickMark /> : <ExclaimMark />}
    </Container>
  );
};
