import { Coordinates } from '../types/Coordinates';

// simple mid distance calculation for reasonably close coordinates
export const midPointLatLon = (
  coordinates1: Coordinates,
  coordinates2: Coordinates,
) => {
  const dLat = Math.abs(coordinates1.lat - coordinates2.lat) / 2;
  const dLon = Math.abs(coordinates1.lon - coordinates2.lon) / 2;
  const midLat = Math.min(coordinates1.lat, coordinates2.lat) + dLat;
  const midLon = Math.min(coordinates1.lon, coordinates2.lon) + dLon;
  const midPoint: Coordinates = {
    lat: midLat,
    lon: midLon,
  };
  return midPoint;
};
