import React, { FC } from 'react';
import { Coordinates } from '../../types/Coordinates';
import { ContainerCard, ContainerMap, Driving } from './styled';
import { Data, Details } from '../projectOverview/styled';
import { config } from '../../config';
import { Map } from '../map/Map';
import { Label } from '@meindach/ui-kit';
import { ReactComponent as Car } from '../../assets/icons/car.svg';
import { useDirections } from '../../hooks/useDirections';
import { createGeoJSONCircle } from '../../utils/createGeoJSONCircle';
import { OpportunityMarker } from '../opportunityMarker/OpportunityMarker';
import { RooferMarker } from '../rooferMarker/RooferMarker';
import { useRoofer } from '../../hooks/useRoofer';
import { PageLoading } from '../pageLoading/PageLoading';
import { midPointLatLon } from '../../utils/midPointLatLon';
import { calculateMapZoom } from '../../utils/calculateMapZoom';
import { useTranslation } from 'react-i18next';

interface Props {
  customerLatLon: Coordinates;
}

export const MapCard: FC<Props> = ({ customerLatLon }) => {
  const { t } = useTranslation('mapCard');
  const { roofer, pending } = useRoofer();
  const centreLatLon = midPointLatLon(roofer!.latLon, customerLatLon);
  const { directions, fetching: directionsFetching } = useDirections(
    customerLatLon,
    roofer!.latLon,
  );

  if (pending || !roofer || directionsFetching) {
    return <PageLoading />;
  }

  const maxDistance = roofer.maxDistance ? parseInt(roofer.maxDistance) : 10;

  const zoomLevel = calculateMapZoom(maxDistance);

  const dataSource = createGeoJSONCircle(
    roofer.latLon.lat,
    roofer.latLon.lon,
    maxDistance,
  );

  return (
    <ContainerCard>
      <Details>
        <Driving>
          <Car />
          <Label>{t('drivingDistance')}</Label>
        </Driving>
        <Data>{directions && directions.routeDistance}</Data>
      </Details>
      <ContainerMap>
        <Map
          latitude={centreLatLon.lat}
          longitude={centreLatLon.lon}
          mapboxApiAccessToken={config.mapBoxApiToken}
          dataSource={dataSource}
          directions={directions ? directions.geojson : undefined}
          zoom={zoomLevel}
        >
          <OpportunityMarker {...customerLatLon} />
          <RooferMarker {...roofer.latLon} />
        </Map>
      </ContainerMap>
    </ContainerCard>
  );
};
