import React, { memo } from 'react';
import { GlobalStyle as BaseGlobalStyle } from '@meindach/ui-kit';
import { createGlobalStyle } from '../styled-components';

const DocumentStyle = createGlobalStyle`
  body {
    display: flex;
  }
  #root {
    height: 100%;
    width: 100%;
    flex-direction: column;
    > div {
      flex: 1 0 auto;
    }
  }
`;

export const GlobalStyle = memo(() => (
  <>
    <BaseGlobalStyle />
    <DocumentStyle />
  </>
));
