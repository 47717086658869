import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useCallback, useEffect } from 'react';
import { getOpportunities } from '../api/getOpportunities';
import { deleteLostOpportunity } from '../api/deleteLostOpportunity';
import { useAuth } from './useAuth';
import { SalesStage } from '../types/ProjectEntity';
import { useRoofer } from './useRoofer';
import { Project } from '../types/Project';
import { declineOpportunities } from '../api/declineOpportunities';
import { acceptOpportunities } from '../api/acceptOpportunities';
import { updateProjectEntity } from '../api/updateProjectEntity';
import { updateProjectEntityNotes } from '../api/updateProjectEntityNotes';
import { RooferProjectEntity } from '../types/RooferProjectEntity';
import { uploadProjectEntityImage } from '../api/uploadProjectEntityImage';
import { deleteProjectEntityImage } from '../api/deleteProjectEntityImage';

interface State {
  initialised: boolean;
  projectRefresh: number;
  projects: RooferProjectEntity[];
  pending: boolean;
  acceptPending: boolean;
  acceptError: boolean;
  declinePending: boolean;
  declineError: boolean;
  updatePending: boolean;
  updateError: boolean;
  updateNotesPending: boolean;
  updateNotesError: boolean;
  uploadProjectImagePending: boolean;
  uploadProjectImageError: boolean;
  deleteProjectImagePending: boolean;
  deleteProjectImageError: boolean;
  deleteLostPending: boolean;
  deleteLostError: boolean;
}

export const store = createStore<State>(Store.ProjectEntity, {
  initialised: false,
  projectRefresh: 10000,
  projects: [],
  pending: false,
  acceptPending: false,
  acceptError: false,
  declinePending: false,
  declineError: false,
  updatePending: false,
  updateError: false,
  updateNotesPending: false,
  updateNotesError: false,
  uploadProjectImagePending: false,
  uploadProjectImageError: false,
  deleteProjectImagePending: false,
  deleteProjectImageError: false,
  deleteLostPending: false,
  deleteLostError: false,
});
const { getState, setState } = store;
const salesStages: SalesStage[] = [
  SalesStage.RooferPicking,
  SalesStage.Inspection,
  SalesStage.ProposalCreation,
  SalesStage.ProposalCheck,
  SalesStage.ProposalTracking,
  SalesStage.ProposalGranted,
  SalesStage.ProposalDeclined,
  SalesStage.ContractCreation,
  SalesStage.Execution,
  SalesStage.Cleared,
  SalesStage.ClosedWon,
  SalesStage.Completion,
  SalesStage.ClosedLost,
  SalesStage.GiveAway,
];

const fetchOpportunities = async (token: string, rooferId: string) => {
  const { pending } = getState();
  if (pending) {
    return;
  }
  setState({ ...getState(), pending: true });
  try {
    const request = await getOpportunities(token, rooferId, salesStages);
    if (request.ok) {
      setState({
        ...getState(),
        initialised: true,
        pending: false,
        projects: await request.json(),
      });
      return;
    }
  } catch {}
  setState({ ...getState(), pending: false });
};

export const useProjectEntity = () => {
  const { token } = useAuth();
  const { roofer } = useRoofer();
  const [state] = useStore<State>(Store.ProjectEntity);
  const {
    initialised = false,
    projectRefresh = 10000,
    projects = [],
    pending = false,
    acceptPending = false,
    acceptError = false,
    declinePending = false,
    declineError = false,
    updatePending = false,
    updateError = false,
    updateNotesPending = false,
    updateNotesError = false,
    uploadProjectImagePending = false,
    uploadProjectImageError = false,
    deleteProjectImagePending = false,
    deleteProjectImageError = false,
    deleteLostPending = false,
    deleteLostError = false,
  } = state;

  useEffect(() => {
    if (!token || !roofer) {
      return;
    }
    fetchOpportunities(token, roofer.accountId);
    const interval = setInterval(() => {
      fetchOpportunities(token, roofer.accountId);
    }, projectRefresh);
    return () => clearInterval(interval);
  }, [token, roofer, projectRefresh]);

  const accept = useCallback(
    async (opportunityId: string) => {
      if (!token || !roofer) {
        setState({ ...getState(), acceptError: true });
        return;
      }
      setState({ ...getState(), acceptPending: true });
      try {
        setState({
          ...getState(),
          projects: getState().projects.map((rooferProject) => {
            if (rooferProject.project.opportunity.id === opportunityId) {
              rooferProject.project.opportunity.sales_stage =
                SalesStage.Inspection;
            }
            return rooferProject;
          }),
        });
        const request = await acceptOpportunities(
          token,
          roofer.accountId,
          opportunityId,
        );
        setState({ ...getState(), acceptPending: false });
        if (request.ok) {
          await fetchOpportunities(token, roofer.accountId);
          return;
        }
      } catch {}
      setState({ ...getState(), acceptPending: false, acceptError: true });
    },
    [token, roofer],
  );

  const decline = useCallback(
    async (opportunityId: string) => {
      if (!token || !roofer) {
        setState({ ...getState(), declineError: true });
        return;
      }
      setState({ ...getState(), declinePending: true });
      try {
        setState({
          ...getState(),
          projects: getState().projects.filter(
            (rooferProject) =>
              rooferProject.project.opportunity.id !== opportunityId,
          ),
        });
        const request = await declineOpportunities(
          token,
          roofer.accountId,
          opportunityId,
        );
        setState({ ...getState(), declinePending: false });
        if (request.ok) {
          await fetchOpportunities(token, roofer.accountId);
          return;
        }
      } catch {}
      setState({ ...getState(), declinePending: false, declineError: true });
    },
    [token, roofer],
  );

  const updateProject = useCallback(
    async (
      updatedProject: RooferProjectEntity,
      updatedCallback: (updatedProject: RooferProjectEntity) => void = () => {},
    ) => {
      if (!token || !roofer) {
        setState({ ...getState(), updateError: true });
        return;
      }
      setState({ ...getState(), updatePending: true });
      try {
        setState({
          ...getState(),
          projects: getState().projects.map((rooferProject) => {
            if (
              rooferProject.project.opportunity.id ===
              updatedProject.project.opportunity.id
            ) {
              return updatedProject;
            }
            return rooferProject;
          }),
        });
        updatedCallback(updatedProject);
        const request = await updateProjectEntity(
          token,
          roofer.accountId,
          updatedProject,
        );
        setState({ ...getState(), updatePending: false });
        if (request.ok) {
          await fetchOpportunities(token, roofer.accountId);
          return;
        }
      } catch {}
      setState({ ...getState(), updatePending: false, updateError: true });
    },
    [token, roofer],
  );

  const updateProjectNotes = useCallback(
    async (updatedProject: RooferProjectEntity) => {
      if (!token || !roofer) {
        setState({ ...getState(), updateNotesError: true });
        return;
      }
      setState({ ...getState(), updateNotesPending: true });
      try {
        setState({
          ...getState(),
          projects: getState().projects.map((rooferProject) => {
            if (
              rooferProject.project.opportunity.id ===
              updatedProject.project.opportunity.id
            ) {
              return updatedProject;
            }
            return rooferProject;
          }),
        });
        const request = await updateProjectEntityNotes(
          token,
          roofer.accountId,
          updatedProject,
        );
        setState({ ...getState(), updateNotesPending: false });
        if (request.ok) {
          return;
        }
      } catch {}
      setState({
        ...getState(),
        updateNotesPending: false,
        updateNotesError: true,
      });
    },
    [token, roofer],
  );

  const uploadProjectImage = useCallback(
    async (projectEntity: RooferProjectEntity, image: File) => {
      if (!token || !roofer) {
        setState({ ...getState(), uploadProjectImageError: true });
        return;
      }
      setState({ ...getState(), uploadProjectImagePending: true });
      try {
        const request = await uploadProjectEntityImage(
          token,
          roofer.accountId,
          projectEntity.project.opportunity.id,
          image,
        );
        setState({ ...getState(), uploadProjectImagePending: false });
        if (request.ok) {
          return;
        }
      } catch {}
      setState({
        ...getState(),
        uploadProjectImagePending: false,
        uploadProjectImageError: true,
      });
    },
    [token, roofer],
  );

  const deleteProjectImage = useCallback(
    async (project: Project, imageId: string) => {
      if (!token || !roofer) {
        setState({ ...getState(), deleteProjectImageError: true });
        return;
      }
      setState({ ...getState(), deleteProjectImagePending: true });
      try {
        const request = await deleteProjectEntityImage(
          token,
          roofer.accountId,
          project.opportunityId,
          imageId,
        );
        setState({ ...getState(), deleteProjectImagePending: false });
        if (request.ok) {
          await fetchOpportunities(token, roofer.accountId);
          return;
        }
      } catch {}
      setState({
        ...getState(),
        deleteProjectImagePending: false,
        deleteProjectImageError: true,
      });
    },
    [token, roofer],
  );

  const deleteLost = useCallback(
    async (opportunityId?: string) => {
      if (!token || !roofer || !opportunityId) {
        setState({ ...getState(), deleteLostError: true });
        return;
      }
      setState({ ...getState(), deleteLostPending: true });
      try {
        const request = await deleteLostOpportunity(
          token,
          roofer.accountId,
          opportunityId,
        );
        setState({ ...getState(), deleteLostPending: false });
        if (request.ok) {
          await fetchOpportunities(token, roofer.accountId);
          return;
        }
      } catch {}
      setState({
        ...getState(),
        deleteLostPending: false,
        deleteLostError: true,
      });
    },
    [token, roofer],
  );

  return {
    initialised,
    projects,
    pending,
    accept,
    acceptPending,
    acceptError,
    decline,
    declinePending,
    declineError,
    updateProject,
    updateProjectNotes,
    updatePending,
    updateError,
    updateNotesPending,
    updateNotesError,
    uploadProjectImage,
    uploadProjectImagePending,
    uploadProjectImageError,
    deleteProjectImage,
    deleteLost,
    deleteProjectImagePending,
    deleteProjectImageError,
    deleteLostPending,
    deleteLostError,
  };
};
