import React, { FC } from 'react';
import { useField } from 'formik';
import { Label } from '@meindach/ui-kit';
import { ErrorText, StyledInput } from './styled';

interface Props {
  label: string;
  name: string;
  type?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  placeholder?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const GenericInput: FC<Props> = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props });
  return (
    <div>
      {meta.error && <ErrorText>{meta.error}</ErrorText>}
      <Label htmlFor={field.name}>{label}</Label>
      <StyledInput {...field} {...props} />
    </div>
  );
};
