import React, { FC } from 'react';
import {
  Container,
  Header,
  InnerContainer,
  TitleLeft,
  TitleRight,
} from './styled';

interface Props {
  title: string;
  secondaryTitle?: string;
}

export const Card: FC<Props> = ({ title, secondaryTitle, children }) => {
  return (
    <Container>
      <Header>
        <TitleLeft>{title}</TitleLeft>
        {secondaryTitle && <TitleRight>{secondaryTitle}</TitleRight>}
      </Header>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  );
};
