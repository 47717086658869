import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { useEffect } from 'react';
import { getConsultants } from '../api/getConsultants';
import { Consultant } from '../types/Consultant';
import { useAuth } from './useAuth';

interface State {
  consultants?: Consultant[];
  pending?: boolean;
  error?: boolean;
}

export const store = createStore<State>(Store.Consultant, {});
const { getState, setState } = store;

const fetchConsultant = async (token: string) => {
  setState({ pending: true });
  try {
    const request = await getConsultants(token);
    if (request.ok) {
      setState({ consultants: await request.json() });
      return;
    }
  } catch {}
  setState({ error: true });
};

export const useConsultant = () => {
  const { token } = useAuth();
  const [state] = useStore<State>(Store.Consultant);
  const { consultants, pending = false, error = false } = state;

  useEffect(() => {
    if (token) {
      const { consultants, pending } = getState();
      if (!consultants && !pending) {
        fetchConsultant(token);
      }
    } else if (consultants) {
      setState({});
    }
  }, [token, consultants]);

  return { consultants, pending, error };
};
