import React, { FC } from 'react';
import { P } from '@meindach/ui-kit/dist';
import { Container } from './styled';

interface Props {
  reason: string;
  action: string;
}

export const EmptyContainer: FC<Props> = ({ reason, action }) => {
  return (
    <Container>
      <div>
        <P>{reason}</P>
        <P>{action}</P>
      </div>
    </Container>
  );
};
