import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@meindach/ui-kit';
import { Form, useField } from 'formik';
import { ErrorText } from '../../components/genericInput/styled';
import { Label, EmailError } from './styled';

interface Props {
  isSending: boolean;
  error?: string;
}

export const PasswordRecoveryForm: FC<Props> = ({ error, isSending }) => {
  const { t } = useTranslation('passwordRecovery');
  const [field, meta] = useField({ name: 'email' });
  return (
    <Form>
      <ErrorText>{error}</ErrorText>
      <Label htmlFor={field.name}>{t('emailAddress')}</Label>
      <Input {...field} error={!!meta.error} placeholder={t('emailAddress')} />
      {meta.error && <EmailError>{meta.error}</EmailError>}
      <Button type="submit" disabled={isSending} small>
        {t('buttonText')}
      </Button>
    </Form>
  );
};
