import styled from '../../styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.l};
  width: 100%;
  box-sizing: border-box;

  > svg {
    width: 100px;
    height: 100px;
    margin: ${({ theme }) => theme.spacing.m} 0;
  }

  > p {
    font-size: 16px;
    margin: ${({ theme }) => theme.spacing.m} 0;
  }
`;
