export enum Route {
  PasswordReset = '/passwords',
  PasswordRecovery = '/passwordrecovery',
  Overview = '/overview',
  Help = '/help',
  Login = '/login',
  Logout = '/logout',
  SignUp = '/sign-up',
  Profile = '/profile',
  AboutMe = '/profile/about-me',
  ContactDetails = '/profile/contact-details',
  CompanyDetails = '/profile/company-details',
  OurServices = '/profile/our-services',
  Uploads = '/profile/uploads',
  Opportunities = '/opportunities',
  Projects = '/projects',
  Archive = '/archive',
  Confirmation = '/confirmations',
}
