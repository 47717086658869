import { Coordinates } from './Coordinates';
import { ArchiveReason } from './ArchiveReason';

export interface Project {
  opportunityId: string;
  stage: Stage;
  overview: Overview;
  specification: Specification;
  notes: string;
  images: string[];
  archiveReason?: ArchiveReason;
  numberOfRoofers: number;
  projectLost: boolean;
}

export enum Stage {
  NotAccepted,
  Accepted,
  SiteVisit,
  Quotation,
  QuotationCheck, // ProposalCheck - Don't nag, don't show next step button (ProposalDeclined should be the same for now)
  Contract,
  RoofWork,
  Invoice,
  Complete,
  Archived,
}

interface Overview {
  street: string;
  city: string;
  postalCode: string;
  dateEntered: string;
  reference: string;
  startDate: string;
  phone?: string;
  email?: string;
  latLon: Coordinates;
}

interface Specification {
  fullName: string;
  type: string;
  constructionYear: number;
  roofShape: string;
  roofArea: string;
  desiredCover: string;
  coverColour: string;
  coverSurface: string;
  roofInsulated: boolean;
  budget: string;
  financing: boolean;
}
