import { config } from '../config';
import { getHeaders } from './getHeaders';

interface PasswordResetProps {
  password: string;
  token: string;
}

export const sendPasswordReset = (props: PasswordResetProps) =>
  fetch(`${config.apiBaseUrl}/api/v1/roofer/password`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(props),
  });
