import { sendPasswordReset } from '../api/sendPasswordReset';
import { useCallback, useState } from 'react';

interface State {
  isSuccess?: boolean;
  isSending?: boolean;
  error?: string;
}

interface ResponseBody {
  statusText: string;
}

export const usePasswordReset = () => {
  const [state, setState] = useState<State>({});
  const { isSuccess = false, isSending = false, error } = state;

  const resetPassword = useCallback(
    async (password: string, token: string) => {
      setState({ isSending: true });
      try {
        const response = await sendPasswordReset({ password, token });
        if (response.ok) {
          setState({ isSuccess: true });
        } else {
          const data: ResponseBody = await response.json();
          setState({ error: data.statusText });
        }
      } catch {
        setState({ error: 'apiError' });
      }
    },
    [setState],
  );

  return { resetPassword, isSuccess, isSending, error };
};
