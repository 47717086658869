import styled from '../../styled-components';

export const Link = styled.a`
  position: relative;
  color: ${({ theme }) => theme.color.mercury};

  :hover {
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;
    ::after {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    border-bottom: 1px solid;
    bottom: 0px;
    left: 0;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.15s ease-in-out 0s;
  }
`;
