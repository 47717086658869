export enum ArchiveReason {
  Empty = '',
  ProjectTooBig = 'project_too_big',
  ProjectTooSmall = 'project_too_small',
  ProjectTooFarAway = 'project_too_far_away',
  HomeownerDisagreement = 'homeowner_disagreement',
  NoRooferCapacity = 'no_roofer_capacity',
  HomeownerUnreachable = 'homeowner_unreachable',
  BudgetTooSmall = 'budget_too_small',
  HomeownerAlreadyHasARoofer = 'homeowner_already_has_a_roofer',
  PriceInquiry = 'price_inquiry',
  NotTheHomeowner = 'not_the_homeowner',
  WrongPhoneNumber = 'wrong_phone_number',
}
