import styled, { css } from '../../styled-components';
import { media, touchSupport } from '@meindach/ui-kit';
import { State } from './LargeTile';
import { ReactComponent as Trash } from '../../assets/icons/trashCan.svg';

export const LeftSpacer = styled.div`
  width: 72px;
  height: 32px;
  display: block;
  position: relative;
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: row;
  > :first-child {
    > svg {
      flex: 0 0 auto;
      height: 48px;
      width: 48px;
    }
  }

  > :last-child {
    margin-left: ${({ theme }) => theme.spacing.m};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.s};
  margin-left: ${({ theme }) => theme.spacing.s};

  > span {
    margin-bottom: ${({ theme }) => theme.spacing.s};
    font-size: 22px;
    font-weight: 600;
  }
`;

export const CardMiddle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.s};

  > span {
    font-size: 16px;
    margin: ${({ theme }) => theme.spacing.s};
    display: flex;
    align-items: center;
    > svg {
      flex: 0 0 auto;
      margin-right: ${({ theme }) => theme.spacing.s};
      height: 24px;
      width: 24px;
    }
  }
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;

  > :first-child {
    > svg {
      flex: 0 0 auto;
      margin-left: ${({ theme }) => theme.spacing.s};
      margin-right: ${({ theme }) => theme.spacing.l};
      height: 32px;
      width: 32px;
    }
  }

  > :last-child {
    margin-right: 21px;
    position: relative;
    font-size: 15px;
  }
`;

export const LargeTileContainer = styled.div<{
  selected: boolean;
  state: State;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.color.silver};
  background: ${({ theme }) => theme.color.pearl};
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  box-sizing: border-box;

  > :first-child {
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing.m};
    margin-left: ${({ theme }) => theme.spacing.s};
    margin-bottom: ${({ theme }) => theme.spacing.m};

    > ${CardDetails} {
      > div {
        > svg {
          color: ${({ theme, state }) => {
            if (state === 'nag') {
              return theme.color.nag;
            } else if (state === 'complete') {
              return theme.color.green;
            } else {
              return theme.color.silver;
            }
          }};
        }
      }
    }

    > ${CardFooter} {
      > div {
        color: ${({ theme, state }) => {
          if (state === 'nag') {
            return theme.color.nag;
          } else if (state === 'complete') {
            return theme.color.green;
          } else {
            return theme.color.silver;
          }
        }};
      }
    }
  }

  > :last-child {
    margin-right: ${({ theme }) => theme.spacing.s};
    > svg {
      flex: 0 0 auto;
      transform: rotate(-90deg);
      color: ${({ theme }) => theme.color.silver};
      width: 21px;
      height: 21px;
    }
  }

  ${({ selected, state }) =>
    !touchSupport &&
    media.md`
        border: 1px solid ${({ theme }) => theme.color.mercury};
        margin-bottom: 12px;

        :hover {
          transition: transition: background 0.15s ease 0s, box-shadow 0.15s ease 0s, color 0.15s ease 0s;
          background: ${({ theme }) => theme.color.white};
          box-shadow: 0px 0px 2px 2px ${({ theme }) => theme.color.mercury};
          color: ${({ theme }) => theme.color.slate};
        
          > :first-child {
            > ${CardDetails} {
              > div {
                > svg {
                  flex: 0 0 auto;
                  color: ${({ theme }) => {
                    if (state === 'nag') {
                      return theme.color.nagAcc;
                    } else if (state === 'complete') {
                      return theme.color.greenAcc;
                    } else {
                      return theme.color.storm;
                    }
                  }};
                }
              }
            }

            > ${CardFooter} {
              > div {
                color: ${({ theme }) => {
                  if (state === 'nag') {
                    return theme.color.nagAcc;
                  } else if (state === 'complete') {
                    return theme.color.greenAcc;
                  } else {
                    return theme.color.slate;
                  }
                }};
              }
            }
          }
        }

        ${
          selected &&
          css`
            background: ${({ theme }) => theme.color.white};
            box-shadow: 0px 0px 2px 2px ${({ theme }) => theme.color.mercury};
            border: 1px solid ${({ theme }) => theme.color.silver};
            color: ${({ theme }) => theme.color.slate};
            > :first-child {
              > ${CardDetails} {
                > div {
                  > svg {
                    flex: 0 0 auto;
                    color: ${({ theme }) => {
                      if (state === 'nag') {
                        return theme.color.nagAcc;
                      } else if (state === 'complete') {
                        return theme.color.greenAcc;
                      } else {
                        return theme.color.storm;
                      }
                    }};
                  }
                }
              }

              > ${CardFooter} {
                > div {
                  color: ${({ theme }) => {
                    if (state === 'nag') {
                      return theme.color.nagAcc;
                    } else if (state === 'complete') {
                      return theme.color.greenAcc;
                    } else {
                      return theme.color.slate;
                    }
                  }};
                  > svg {
                    color: ${({ theme }) => {
                      if (state === 'nag') {
                        return theme.color.nagAcc;
                      } else if (state === 'complete') {
                        return theme.color.greenAcc;
                      } else {
                        return theme.color.storm;
                      }
                    }};
                  }
                }
              }
            }
          `
        }
      `}
`;

export const LargeTileInnerContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const DeleteContainer = styled.div`
  position: absolute;
  right -21px;
  bottom: 36px;  
`;

export const DeleteButton = styled.div`
  border-radius: 2px;
  min-width: 42px;
  height: 42px;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.green};
  background: ${({ theme }) => theme.color.mercury};
  position: absolute;
  right 0px;
  top: 0px;
  z-index: 1;
  :hover {
    > svg {
      color: ${({ theme }) => theme.color.slate};
    }
  }
`;

export const DeleteIcon = styled(Trash)`
  position: absolute;
  color: ${({ theme }) => theme.color.silver};
  width: 14px;
  height: 14px;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
`;
