import React, { FC } from 'react';
import { Text } from './styled';
import { Label } from '@meindach/ui-kit';
import { useField } from 'formik';

interface Props {
  label: string;
  name: string;
}
export const TextArea: FC<Props> = ({ label, name }) => {
  const [field] = useField({
    name,
  });

  return (
    <div>
      <Label>{label}</Label>
      <Text>
        <p>{field.value}</p>
      </Text>
    </div>
  );
};
