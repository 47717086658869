import React, { useEffect, useState } from 'react';
import { SmallTile } from '../../components/smallTile/SmallTile';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Binoculars } from '../../assets/icons/binoculars.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as SiteAppointment } from '../../assets/icons/siteAppt.svg';
import { ReactComponent as Price } from '../../assets/icons/price.svg';
import { ReactComponent as ThumbsUp } from '../../assets/icons/thumbsUp.svg';
import { ReactComponent as Handshake } from '../../assets/icons/handshake.svg';
import { ReactComponent as HardHat } from '../../assets/icons/hardHat.svg';
import { ReactComponent as Invoice } from '../../assets/icons/invoice.svg';
import { ReactComponent as Camera } from '../../assets/icons/camera.svg';
import { ReactComponent as Comments } from '../../assets/icons/comments.svg';
import { Notes as Note } from '../../components/notes/Notes';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { ProjectSpecification } from '../../components/projectSpecification/ProjectSpecification';
import { ReactComponent as Spec } from '../../assets/icons/spec.svg';
import { useProject } from '../../hooks/useProject';
import { ProjectOverview } from '../../components/projectOverview/ProjectOverview';
import { Project, Stage } from '../../types/Project';
import { LargeTile } from '../../components/largeTile/LargeTile';
import {
  Column,
  ColumnContainer,
} from '../../components/columnContainer/styled';
import { EmptyContainer } from '../../components/emptyContainer/EmptyContainer';
import { TFunction } from 'i18next';
import { ProjectStep } from '../../components/projectSteps/ProjectStep';
import { NavigationHeader } from '../../components/columnContainer/NavigationHeader';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Container } from '../projects/styled';
import { Photos } from '../../components/photos/Photos';
import { useLocation } from 'react-router';
import { Route as RouteType } from '../../types/Route';
import { useHistory } from 'react-router-dom';
import { ProjectTakenModal } from '../../components/projectTakenModal/ProjectTakenModal';
import { useModal } from '../../hooks/useModal';

enum View {
  Projects,
  ProjectSteps,
  ProjectCategories,
  Details,
}

interface State {
  index: number;
  stageIndex: number;
  categoryIndex: number;
  view: View;
  handledHash: boolean;
}

export const Archive = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation('archive');
  const { hash } = useLocation();
  const history = useHistory();
  const [state, setState] = useState<State>({
    index: 0,
    stageIndex: 0,
    categoryIndex: 0,
    view: View.Projects,
    handledHash: false,
  });
  const { index, stageIndex, categoryIndex, view, handledHash } = state;
  const {
    initialised,
    inquiries,
    projects,
    archived: archivedProjects,
    updatePending,
  } = useProject();
  const { show, toggle } = useModal();

  useEffect(() => {
    if (hash && !handledHash && initialised) {
      const id = hash.slice(1);
      const idx = archivedProjects.findIndex(
        (archivedProject) => archivedProject.opportunityId === id,
      );
      if (idx === -1) {
        if (inquiries.find((inquiry) => inquiry.opportunityId === id)) {
          history.push(`${RouteType.Opportunities}#${id}`);
        } else if (projects.find((project) => project.opportunityId === id)) {
          history.push(`${RouteType.Projects}#${id}`);
        }
      }
      if (idx >= 0) {
        setState((s) => ({ ...s, index: idx, handledHash: true }));
      } else {
        setState((s) => ({ ...s, handledHash: true }));
        toggle();
      }
    }
  }, [
    hash,
    handledHash,
    initialised,
    inquiries,
    projects,
    archivedProjects,
    history,
    toggle,
  ]);

  useEffect(() => {
    if (!hash && archivedProjects.length >= index + 1) {
      history.push(
        `${RouteType.Archive}#${archivedProjects[index].opportunityId}`,
      );
    }
  }, [hash, archivedProjects, index, history]);

  if (updatePending) {
    return <PageLoading />;
  }

  if (archivedProjects.length === 0) {
    return <EmptyContainer reason={t('reason')} action={t('action')} />;
  }

  const getArchivedProject = (index: number) => {
    if (index >= archivedProjects.length) {
      setState({
        ...state,
        index: 0,
        stageIndex: 0,
        categoryIndex: 0,
        view: View.Projects,
      });
      return archivedProjects[0];
    }
    return archivedProjects[index];
  };

  const visibleViews = () => {
    if (width < 850) {
      return [view];
    }
    if (width < 1200) {
      if (
        view === View.Projects.valueOf() ||
        view === View.ProjectSteps.valueOf()
      ) {
        return [View.Projects.valueOf(), View.ProjectSteps.valueOf()];
      }
      if (stageIndex === 0) {
        return [View.ProjectCategories.valueOf(), View.Details.valueOf()];
      }
      return [View.ProjectCategories.valueOf()];
    }
    if (view === View.Details.valueOf()) {
      return [View.Details.valueOf()];
    }
    return [
      View.Projects.valueOf(),
      View.ProjectSteps.valueOf(),
      View.ProjectCategories.valueOf(),
    ];
  };

  const back = () => {
    setState({ ...state, view: view.valueOf() - visibleViews().length });
  };

  const archivedProject = getArchivedProject(index);
  const viewsToShow = visibleViews();
  const showNavigationHeader =
    viewsToShow.length !== 4 && view.valueOf() >= viewsToShow.length;

  const projectCards = (
    projects: Project[],
    t: TFunction,
    index: number,
    setIndex: (newIndex: number) => void,
  ) =>
    projects.map(
      ({ opportunityId, overview, specification, stage }, currIndex) => {
        let cardText = '';
        switch (stage) {
          case Stage.Complete:
            cardText = t('complete');
            break;
          case Stage.Archived:
            cardText = t('archived');
            break;
        }
        return (
          <LargeTile
            /* eslint-disable-next-line react/no-array-index-key */
            key={currIndex}
            selected={currIndex === index}
            state={'complete'}
            title={specification.fullName}
            street={overview.street}
            city={overview.city}
            postalCode={overview.postalCode}
            customerLatLon={overview.latLon}
            area={t(`roofArea:${specification.roofArea}`)}
            startDate={t(`startDate:${overview.startDate}`)}
            cardText={cardText}
            onClick={() => {
              setIndex(currIndex);
              if (hash !== opportunityId) {
                history.push(`${RouteType.Archive}#${opportunityId}`);
              }
            }}
          />
        );
      },
    );

  const projectSteps = (
    project: Project,
    stage: Stage,
    t: TFunction,
    setStageIndex: (newStageIndex: number) => void,
  ) =>
    [
      { text: 'details', icon: Info, step: Stage.Accepted },
      { text: 'accept', icon: ThumbsUp, step: Stage.Accepted },
      {
        text: 'siteVisitAppointment',
        icon: SiteAppointment,
        step: Stage.SiteVisit,
      },
      {
        text: 'quotation',
        icon: Price,
        step: Stage.Quotation,
      },
      {
        text: 'contract',
        icon: Handshake,
        step: Stage.Contract,
      },
      {
        text: 'roofWork',
        icon: HardHat,
        step: Stage.RoofWork,
      },
      {
        text: 'invoice',
        icon: Invoice,
        step: Stage.Invoice,
      },
    ].map(({ text, icon, step }, currIndex) => {
      const enabled = step.valueOf() <= project.stage.valueOf();
      return (
        <SmallTile
          /* eslint-disable-next-line react/no-array-index-key */
          key={currIndex}
          selected={currIndex === stage}
          enabled={enabled}
          nag={false}
          text={t(text)}
          icon={icon}
          onClick={() => {
            enabled && setStageIndex(currIndex);
          }}
        />
      );
    });

  const projectCategory = (
    stageIndex: number,
    projectDetailsCategories: JSX.Element[],
    project: Project,
  ) => (
    <>
      {stageIndex === 0 && projectDetailsCategories}
      {stageIndex === 1 && (
        <ProjectStep
          title="projectAcceptanceTitle"
          stepTextCompleted="projectAcceptanceComplete"
          stepTextIncomplete="projectAcceptanceComplete"
          completed
        />
      )}
      {stageIndex === 2 && (
        <ProjectStep
          title="siteVisitAppointmentTitle"
          stepTextCompleted="siteVisitAppointmentComplete"
          stepTextIncomplete="siteVisitAppointmentIncomplete"
          completed={project.stage.valueOf() > Stage.SiteVisit.valueOf()}
        />
      )}
      {stageIndex === 3 && (
        <ProjectStep
          title="quotationTitle"
          stepTextCompleted="quotationComplete"
          stepTextIncomplete="quotationIncomplete"
          completed={project.stage.valueOf() > Stage.Quotation.valueOf()}
        />
      )}
      {stageIndex === 4 && (
        <ProjectStep
          title="contractTitle"
          stepTextCompleted="contractComplete"
          stepTextIncomplete="contractIncomplete"
          completed={project.stage.valueOf() > Stage.Contract.valueOf()}
        />
      )}
      {stageIndex === 5 && (
        <ProjectStep
          title="roofWorkTitle"
          stepTextCompleted="roofWorkComplete"
          stepTextIncomplete="roofWorkIncomplete"
          completed={project.stage.valueOf() > Stage.RoofWork.valueOf()}
        />
      )}
      {stageIndex === 6 && (
        <ProjectStep
          title="invoiceTitle"
          stepTextCompleted="invoiceComplete"
          stepTextIncomplete="invoiceIncomplete"
          completed={project.stage.valueOf() > Stage.Invoice.valueOf()}
        />
      )}
    </>
  );

  const projectDetailsCategories = (
    project: Project,
    categoryIndex: number,
    t: TFunction,
    setCategoryIndex: (newCategoryIndex: number) => void,
  ): JSX.Element[] =>
    [
      { text: 'overView', icon: Binoculars, enabled: true },
      { text: 'details', icon: Spec, enabled: true },
      { text: 'notes', icon: Comments, enabled: true },
      {
        text: 'photos',
        icon: Camera,
        enabled: project.stage !== Stage.Archived,
      },
    ].map(({ text, icon, enabled }, currIndex) => (
      <SmallTile
        /* eslint-disable-next-line react/no-array-index-key */
        key={currIndex}
        selected={currIndex === categoryIndex}
        enabled={enabled}
        nag={false}
        text={t(text)}
        icon={icon}
        onClick={() => {
          enabled && setCategoryIndex(currIndex);
        }}
      />
    ));

  const details = (categoryIndex: number, project: Project, t: TFunction) => (
    <>
      {categoryIndex === 0 && overview(project, t)}
      {categoryIndex === 1 && projectSpec(project)}
      {categoryIndex === 2 && <Note projectNotes={project.notes} />}
      {categoryIndex === 3 && photos(project)}
    </>
  );

  const overview = (project: Project, t: TFunction) => (
    <ProjectOverview
      reference={project.overview.reference}
      startDate={t(`startDate:${project.overview.startDate}`)}
      street={project.overview.street}
      city={project.overview.city}
      postalCode={project.overview.postalCode}
      dateEntered={project.overview.dateEntered}
      customerFullName={project.specification.fullName}
      customerPhone={project.overview.phone}
      customerEmail={project.overview.email}
      customerStreet={project.overview.street}
      customerCity={project.overview.city}
      customerPostalCode={project.overview.postalCode}
      customerLatLon={project.overview.latLon}
    />
  );

  const projectSpec = (project: Project) => {
    const specificationData = Object.entries(
      project.specification,
    ).flatMap(([first, second]) => ({ title: first, value: second }));
    return <ProjectSpecification data={specificationData} />;
  };

  const photos = (project: Project) => {
    return <Photos project={project} allowUploads={false} />;
  };

  return (
    <Container>
      {show && <ProjectTakenModal toggle={toggle} />}
      {showNavigationHeader && (
        <NavigationHeader project={archivedProject} back={back} />
      )}
      <ColumnContainer>
        <Column
          currentView={View.Projects.valueOf()}
          viewsToShow={viewsToShow}
          fade={false}
        >
          {projectCards(archivedProjects, t, index, (idx) =>
            setState({
              ...state,
              index: idx,
              stageIndex: 0,
              categoryIndex: 0,
              view: View.ProjectSteps,
            }),
          )}
        </Column>
        <Column
          currentView={View.ProjectSteps.valueOf()}
          viewsToShow={viewsToShow}
          fade
        >
          {projectSteps(archivedProject, stageIndex, t, (idx) =>
            setState({
              ...state,
              stageIndex: idx,
              categoryIndex: 0,
              view: View.ProjectCategories,
            }),
          )}
        </Column>
        <Column
          currentView={View.ProjectCategories.valueOf()}
          viewsToShow={viewsToShow}
          fade
        >
          {projectCategory(
            stageIndex,
            projectDetailsCategories(archivedProject, categoryIndex, t, (idx) =>
              setState({ ...state, categoryIndex: idx, view: View.Details }),
            ),
            archivedProject,
          )}
        </Column>
        {stageIndex === 0 && (
          <Column
            currentView={View.Details.valueOf()}
            viewsToShow={viewsToShow}
            fade
          >
            {details(categoryIndex, archivedProject, t)}
          </Column>
        )}
      </ColumnContainer>
    </Container>
  );
};
