import { InitializeOptions } from 'react-ga';

interface Config {
  apiBaseUrl: string;
  registrationUrl: string;
  classificationUrl: string;
  mapBoxApiToken: string;
  googleMapsApiToken: string;
  googleTrackingId: string;
  googleOptions: InitializeOptions;
}

const {
  NODE_ENV,
  REACT_APP_B2B_URL,
  REACT_APP_CLASSIFICATION_URL,
  REACT_APP_ROLLBAR_TOKEN,
  REACT_APP_ROLLBAR_ENV,
  REACT_APP_ROOFER_REGISTRATION_URL,
  REACT_APP_MAPBOX_API_TOKEN,
  REACT_APP_GOOGLE_MAPS_API_TOKEN,
  REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
} = process.env;

if (NODE_ENV === 'production') {
  Object.entries({
    REACT_APP_B2B_URL,
    REACT_APP_CLASSIFICATION_URL,
    REACT_APP_ROLLBAR_TOKEN,
    REACT_APP_ROLLBAR_ENV,
    REACT_APP_ROOFER_REGISTRATION_URL,
    REACT_APP_MAPBOX_API_TOKEN,
    REACT_APP_GOOGLE_MAPS_API_TOKEN,
    REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  }).forEach(([key, value]) => {
    if (!value) {
      throw new Error(`Please set ${key}`);
    }
  });
}

export const config: Config = {
  apiBaseUrl: REACT_APP_B2B_URL || 'http://localhost:8081',
  registrationUrl:
    REACT_APP_ROOFER_REGISTRATION_URL ||
    'https://develop-ui-b2b-sign-up.netlify.app',
  classificationUrl: REACT_APP_CLASSIFICATION_URL || 'http://localhost:8082',
  mapBoxApiToken:
    REACT_APP_MAPBOX_API_TOKEN ||
    'pk.eyJ1IjoibWVpbmRhY2giLCJhIjoiY2p1aTRsZ3ZxMGF5czN6bmJsbXdwbHp2aSJ9.9o0UqzYWhG5k6T-XqxY63g',
  googleMapsApiToken:
    REACT_APP_GOOGLE_MAPS_API_TOKEN ||
    'AIzaSyAQaHPyaXUkWa9YOFeaxmd1iLs_iEdfDK8',
  googleTrackingId: REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || 'UA-177203816-3',
  googleOptions: NODE_ENV === 'development' ? { debug: true } : {},
};
