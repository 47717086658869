import styled, { css, keyframes } from '../../styled-components';
import { media, touchSupport } from '@meindach/ui-kit';

const fadeInFromBackground = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const SmallTileContainer = styled.div<{
  selected: boolean;
  enabled: boolean;
  nag: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'default')};
  color: ${({ theme, enabled, nag }) =>
    enabled
      ? nag
        ? theme.color.nag
        : theme.color.silver
      : theme.color.midGrey};
  background: ${({ theme, enabled }) =>
    enabled ? theme.color.pearl : '#f5f5f5'};
  border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
  flex: 0 1 auto;
  box-sizing: border-box;
  max-height: 100%;
  animation: none;

  > :first-child {
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    margin-left: ${({ theme }) => theme.spacing.m};
  }

  > div {
    flex: 1 1 auto;
    > p {
      margin-left: ${({ theme }) => theme.spacing.m};
      font-weight: 600;
      font-size: 20px;
      color: ${({ theme, enabled, nag }) =>
        enabled
          ? nag
            ? theme.color.nag
            : theme.color.silver
          : theme.color.midGrey};
    }
  }

  > :last-child {
    flex: 0 0 auto;
    margin-right: ${({ theme }) => theme.spacing.m};
    transform: rotate(-90deg);
    color: ${({ theme }) => theme.color.silver};
    width: 21px;
    height: 21px;
  }

  ${({ selected, enabled, nag }) =>
    !touchSupport &&
    media.md`
        border: 1px solid ${({ theme }) => theme.color.mercury};
        margin-bottom: 12px;
        animation: ${fadeInFromBackground} 500ms ease-in-out 0.1s both;
        
        ${
          enabled &&
          css`
            :hover {
              transition: background 0.15s ease 0s, box-shadow 0.15s ease 0s,
                color 0.15s ease 0s;
              background: ${({ theme }) => theme.color.white};
              box-shadow: 0px 0px 2px 2px ${({ theme }) => theme.color.mercury};
              color: ${({ theme }) =>
                nag ? theme.color.nagAcc : theme.color.slate};
              > div {
                > p {
                  color: ${({ theme }) =>
                    nag ? theme.color.nagAcc : theme.color.slate};
                }
              }
            }

            ${selected &&
            css`
              border: 1px solid ${({ theme }) => theme.color.silver};
              background: ${({ theme }) => theme.color.white};
              box-shadow: 0px 0px 2px 2px ${({ theme }) => theme.color.mercury};
              color: ${({ theme }) =>
                nag ? theme.color.nagAcc : theme.color.slate};
              > div {
                > p {
                  color: ${({ theme }) =>
                    nag ? theme.color.nagAcc : theme.color.slate};
                }
              }
            `}
          `
        }
    `}
`;
