import styled from '../../styled-components';
import { Link } from '../link/styled';

export const DetailsHeader = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  font-size: 18px;
  > label {
    color: ${({ theme }) => theme.color.silver};
    margin-right: ${({ theme }) => theme.spacing.s};
  }

  > p {
    margin-top: 0px;
    color: ${({ theme }) => theme.color.storm};
  }
`;

export const Details = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  color: ${({ theme }) => theme.color.silver};
  margin-bottom: ${({ theme }) => theme.spacing.m};

  > :first-child {
    display: flex;
    align-items: center;
    > svg {
      height: 24px;
      width: 24px;
      margin-right: ${({ theme }) => theme.spacing.s};
    }

    > label {
      font-size: 16px;
      font-weight: 400;
      margin-right: ${({ theme }) => theme.spacing.s};
    }
  }
`;

export const Data = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.storm};
  margin-left: ${({ theme }) => theme.spacing.l};
`;

export const SubHeader = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.color.charcoal};
`;

export const FullName = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.slate};
  margin: ${({ theme }) => theme.spacing.m} 0;
`;

export const Phone = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  color: ${({ theme }) => theme.color.red};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  > svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }
  > ${Link} {
    color: inherit;
    :hover {
      color: ${({ theme }) => theme.color.red600};
    }
  }
`;

export const Email = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  color: ${({ theme }) => theme.color.red};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  > svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }
  > ${Link} {
    color: inherit;
    :hover {
      color: ${({ theme }) => theme.color.red600};
    }
  }
`;

export const Address = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  color: ${({ theme }) => theme.color.silver};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  > svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`;
