import React from 'react';
import { useRoofer } from '../../hooks/useRoofer';
import { ImageTitle, Info, InnerContainer } from './styled';
import { Route } from '../../types/Route';
import { useTranslation } from 'react-i18next';
import { ImageUpload } from '../../components/imageUpload/ImageUpload';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { ProfileHeader } from '../../components/profileHeader/Header';
import { Container, FormContainer } from '../common/common-styled';
import { getFillPercent } from '../../utils/getFillPercent';
import { uploadFields } from '../../data/requiredFields';
import { ReactComponent as UploadIcon } from '../../assets/icons/cloud_upload.svg';
import { Status } from '../../components/status/Status';
import { RooferImageType } from '../../types/RooferImageType';

export const Uploads = () => {
  const { t } = useTranslation('uploads');
  const {
    profile,
    companyLogo,
    uploadImage,
    roofer,
    uploadImagePending,
  } = useRoofer();
  if (!roofer || uploadImagePending) {
    return <PageLoading />;
  }
  const completed = getFillPercent(uploadFields, roofer) === 100;
  const onDrop = (type: RooferImageType) => (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      uploadImage(type, acceptedFiles[0]);
    }
  };

  return (
    <Container>
      <ProfileHeader
        header={t(`pageTitle:${Route.Uploads}`)}
        image={UploadIcon}
        route={Route.Profile}
      />
      <FormContainer>
        <Info>
          <p>{t('info')}</p>
          <p>{t('limits', { max: 10 })}</p>
        </Info>
        <InnerContainer>
          <div>
            <ImageTitle>{t('consultantPortrait')}</ImageTitle>
            <ImageUpload
              src={profile}
              onDrop={onDrop(RooferImageType.Profile)}
            />
          </div>
          <div>
            <ImageTitle>{t('companyLogo')}</ImageTitle>
            <ImageUpload
              src={companyLogo}
              onDrop={onDrop(RooferImageType.CompanyLogo)}
            />
          </div>
          <Status completed={completed} />
        </InnerContainer>
      </FormContainer>
    </Container>
  );
};
