import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './de.json';

const resources = { de };
const languages = ['de'];

i18n.languages = languages;
i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, // not needed for react
    },
  });

export default i18n;
