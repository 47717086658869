import { media } from '@meindach/ui-kit';
import styled from '../../styled-components';

export const Title = styled.div`
  font-size: 16px;
  margin: 0;
  word-break: break-word;
  color: ${({ theme }) => theme.color.storm};
`;

export const Tick = styled.div`
  color: ${({ theme }) => theme.color.green};
  > svg {
    width: 48px;
    height: 48px;
  }
`;

export const Exclaim = styled.div`
  color: ${({ theme }) => theme.color.nag};
  > svg {
    width: 48px;
    height: 48px;
  }
`;

export const Lost = styled.div`
  color: ${({ theme }) => theme.color.silver};
  > svg {
    width: 48px;
    height: 48px;
  }
`;

export const Text = styled.div`
  font-size: 15px;
  margin: 0;
  &:first-child {
    font-size: 20px;
  }
  > p {
    > a {
      color: ${({ theme }) => theme.color.red};
      :hover {
        color: ${({ theme }) => theme.color.red};
      }
    }
  }
  > a {
    font-size: 15px;
    color: ${({ theme }) => theme.color.red};
    :hover {
      color: ${({ theme }) => theme.color.red};
    }
    > svg {
      width: 21px;
      height: 21px;
      margin-right: ${({ theme }) => theme.spacing.s};
    }
  }
`;

export const Container = styled.div<{
  lost: boolean;
}>`
  display: flex;
  width: 100%;
  cursor: pointer;
  background: ${({ theme }) => theme.color.pearl};

  border: 1px solid ${({ theme }) => theme.color.mercury};
  border-left: none;
  border-right: none;
  margin-bottom: ${({ theme }) => theme.spacing.s};
  ${media.md`
    border: 1px solid ${({ theme }) => theme.color.mercury};
  `}

  padding: ${({ theme }) => theme.spacing.m};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.storm};

  :hover {
    background: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.slate};
    ${Tick} {
      color: ${({ theme }) => theme.color.greenAcc};
    }
    ${Exclaim} {
      color: ${({ theme }) => theme.color.nagAcc};
    }
    ${Lost} {
      color: ${({ theme }) => theme.color.storm};
    }
    ${Text} {
      color: ${({ theme, lost }) => {
        if (lost) {
          return theme.color.slate;
        } else {
          return theme.color.nagAccDark;
        }
      }};
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  > :first-child {
    width: 48px;
    height: 48px;
    margin-right: ${({ theme }) => theme.spacing.m};
  }
  > :last-child {
    margin-left: auto;
    justify-content: center;
    > svg {
      transform: rotate(-90deg);
      color: ${({ theme }) => theme.color.silver};
      width: 21px;
      height: 21px;
    }
  }
`;
