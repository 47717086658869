import styled from '../../styled-components';
import { media, Title } from '@meindach/ui-kit';
import { Link } from '../../components/link/styled';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  ${media.md`
    padding: ${({ theme }) => theme.spacing.l};
  `}
`;

export const Content = styled.div`
  box-sizing: border-box;
  margin: ${({ theme }) => theme.spacing.s};

  > ${Title} {
    margin-bottom: ${({ theme }) => theme.spacing.m};
    ${media.md`
      margin-bottom: ${({ theme }) => theme.spacing.l};
    `}
  }
`;

export const TitleText = styled.div`
  > p {
    font-size: 18px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  text-align: left;
  font-size: 20px;
  justify-content: left;
  align-items: left;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.red};
  > svg {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  > ${Link} {
    color: ${({ theme }) => theme.color.red};
  }
  padding: ${({ theme }) => theme.spacing.m};
  ${media.md`
    > :first-child {
      margin-right: ${({ theme }) => theme.spacing.m};
    }
  `}
  > div {
    width: 100%;
  }
`;
