import styled from '../../styled-components';

export const ErrorText = styled.p`
  line-height: 50px;
  width: 100%;
  padding: 0px;
  color: ${({ theme }) => theme.color.error};
  margin: 0;
  font-size: 24px;
`;

export const DropDown = styled.div`
  margin-bottom: 25px;
`;
