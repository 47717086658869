export enum Store {
  Auth = 'auth',
  Roofer = 'roofer',
  Consultant = 'consultant',
  ProjectEntity = 'opportunities',
  Project = 'project',
  Employee = 'employee',
  Directions = 'directions',
  Image = 'image',
}
