import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThumbsUp } from '../../assets/icons/thumbsUp.svg';
import { useProjectEntity } from '../../hooks/useProjectEntity';
import { Project } from '../../types/Project';
import { Modal } from '../../modal/Modal';

interface Props {
  toggle: () => void;
  project: Project;
}

export const AcceptOpportunityModal: FC<Props> = ({ toggle, project }) => {
  const { t } = useTranslation('acceptOrDecline');
  const { accept } = useProjectEntity();

  const okAction = useCallback(() => accept(project.opportunityId), [
    accept,
    project.opportunityId,
  ]);

  return (
    <Modal
      icon={<ThumbsUp />}
      title={t('title')}
      message={t('acceptInfo')}
      okAction={okAction}
      okText={t('accept')}
      toggle={toggle}
      cancelText={t('cancel')}
    />
  );
};
