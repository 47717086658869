import React from 'react';
import { useState } from 'react';
import { P, Link, usePlatform } from '@meindach/ui-kit';
import { useAuth } from '../../hooks/useAuth';
import { trackEvent } from '../../utils/trackEvent';
import { Form, Formik } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { Route } from '../../types/Route';
import * as Yup from 'yup';
import { ErrorText } from '../../components/genericInput/styled';
import {
  Background,
  Title,
  Content,
  Container,
  SignUpButton,
  FieldInput,
  PasswordContainer,
  PasswordButton,
  PasswordEye,
  StyledLink,
  Welcome,
} from './styled';
import { ReactComponent as Chevron } from '@meindach/ui-kit/dist/assets/ui/chevronDown.svg';
import { ReactComponent as Logo } from '@meindach/ui-kit/dist/assets/logo/logo.svg';
import { SplitDivider } from '../../components/divider/styled';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export const Login = () => {
  const { login, error } = useAuth();
  const { t } = useTranslation('login');
  const { desktop } = usePlatform();
  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9]{2,5}/i, t('invalidEmail'))
      .required(t('emptyEmail')),
    password: Yup.string().required(t('emptyPassword')),
  });

  const initialValues = { email: '', password: '' };
  const [passwordShown, setPasswordShown] = useState(false);
  const onSubmit = async (values: any) => {
    const response = await login(values.email, values.password);
    trackEvent('roofer', 'login', response?.uuid);
  };

  const toggleViewPassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Container>
      {desktop && <Background />}
      <Content>
        <Welcome>
          <Logo />
          <div>
            <Trans i18nKey="login:welcome">
              Welcome<b>Title</b>!
            </Trans>
          </div>
        </Welcome>
        <P>{t('info')}</P>
        <Title>{t('title')}</Title>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={loginValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <Form>
            {error && <ErrorText>{error}</ErrorText>}
            <div>
              <FieldInput
                label={t('emailAddress')}
                name="email"
                type="email"
                inputMode="email"
              />
              <PasswordContainer>
                <FieldInput
                  label={t('password')}
                  name="password"
                  type={passwordShown ? 'text' : 'password'}
                />
                <PasswordEye
                  onClick={toggleViewPassword}
                  onMouseDown={toggleViewPassword}
                >
                  {passwordShown ? <Visibility /> : <VisibilityOff />}
                </PasswordEye>
                <PasswordButton small type="submit">
                  {t('buttonText')}
                </PasswordButton>
              </PasswordContainer>
            </div>
          </Form>
        </Formik>
        <Link to={Route.PasswordRecovery}>{t('forgotpwd')}</Link>
        <SplitDivider>{t('or')}</SplitDivider>
        <Title>{t('signUp')}</Title>
        <P>{t('signUpInfo')}</P>
        <StyledLink to={Route.SignUp}>
          <SignUpButton small emptyDanger>
            {t('signUp')}
            <Chevron />
          </SignUpButton>
        </StyledLink>
      </Content>
    </Container>
  );
};
