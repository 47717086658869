import { config } from '../config';
import { getHeaders } from './getHeaders';

export const uploadProjectEntityImage = (
  token: string,
  rooferId: string,
  projectId: string,
  image: File,
) => {
  const formData = new FormData();
  formData.append('file', image);
  return fetch(
    `${config.classificationUrl}/api/v1/roofer/${rooferId}/project/${projectId}/image`,
    {
      method: 'POST',
      headers: getHeaders(token, null),
      body: formData,
    },
  );
};
