export const fetchAndParse = async <T = any>(
  url: string,
  options?: RequestInit,
): Promise<T | null> => {
  try {
    const response = await fetch(url, options);
    if (response.ok) {
      return await response.json();
    }
  } catch {}
  return null;
};
