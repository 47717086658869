import React, { memo } from 'react';
import { Container, StyledHeader, Title } from './styled';
import { ReactComponent as Logo } from '../../assets/logo/logo_empty_white.svg';
import { useRoofer } from '../../hooks/useRoofer';
import { Route } from '../../types/Route';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { usePlatform } from '@meindach/ui-kit';
import { RooferAvatar } from './RooferAvatar';
import { VerticalDivider } from '../divider/styled';
import { trackEvent } from '../../utils/trackEvent';

export const Header = memo(() => {
  const { t } = useTranslation('header');
  const { pathname } = useLocation();
  const { token, logout } = useAuth();
  const { roofer } = useRoofer();
  const { desktop } = usePlatform();
  return (
    <StyledHeader>
      <Container>
        <Link to={token ? Route.Overview : Route.Login}>
          <Logo />
        </Link>
        <Title>
          {Object.values(Route).includes(pathname as Route)
            ? t(`pageTitle:${pathname}`)
            : t('pageTitle:default')}
        </Title>
        {token && (
          <div>
            <Link to={Route.Profile}>
              <RooferAvatar />
              {desktop && roofer && (
                <>
                  {roofer.firstName} {roofer.lastName}
                </>
              )}
            </Link>
            {desktop && roofer && <VerticalDivider />}
            {desktop && (
              <Link
                to={Route.Logout}
                onClick={async () => {
                  trackEvent('roofer', 'logout', roofer?.accountId);
                  await logout();
                }}
              >
                {t('logout')}
              </Link>
            )}
          </div>
        )}
      </Container>
    </StyledHeader>
  );
});
