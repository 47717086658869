import { config } from '../config';
import { getHeaders } from './getHeaders';

export const acceptOpportunities = (
  token: string,
  rooferId: string,
  opportunityId: string,
) =>
  fetch(
    `${config.classificationUrl}/api/v1/roofer/${rooferId}/project/${opportunityId}/accept`,
    {
      method: 'PUT',
      headers: getHeaders(token),
    },
  );
