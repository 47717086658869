import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Info, Overlay, Title } from './styled';
import { Formik } from 'formik';
import { usePasswordReset } from '../../hooks/usePasswordReset';
import { Route } from '../../types/Route';
import { getQueryParameter } from '../../utils/getQueryParameter';
import * as Yup from 'yup';
import { ReactComponent as Icon } from '../../assets/icons/forgotPassword.svg';
import { ReactComponent as PasswordResetIcon } from '../../assets/icons/confirmed.svg';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { PasswordResetForm } from './PasswordResetForm';
import { useHistory } from 'react-router-dom';
import { ErrorText } from '../../components/genericInput/styled';

export const PasswordReset = () => {
  const { t } = useTranslation('passwordReset');
  const { resetPassword, isSuccess, isSending, error } = usePasswordReset();
  const history = useHistory();

  const token = getQueryParameter(window.location.search, 'token');

  useEffect(() => {
    if (isSuccess || !token) {
      const timeout = setTimeout(() => history.push(Route.Login), 5000);
      return () => clearTimeout(timeout);
    }
  }, [isSuccess, history, token]);

  if (isSuccess) {
    return (
      <Container centered>
        <PasswordResetIcon />
        <Info>{t('resetSuccessTitle')}</Info>
      </Container>
    );
  }

  if (!token) {
    return (
      <Container>
        <Title>{t('title')}</Title>
        <Icon />
        <ErrorText>{t('missingToken')}</ErrorText>
      </Container>
    );
  }

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string().required(t('emptyPassword')),
  });

  return (
    <Container>
      {isSending && (
        <Overlay>
          <PageLoading />
        </Overlay>
      )}
      <Title>{t('title')}</Title>
      <Icon />
      <Formik
        initialValues={{ password: '' }}
        onSubmit={({ password }) => resetPassword(password, token)}
        validationSchema={passwordValidationSchema}
      >
        <PasswordResetForm isSending={isSending} error={error} />
      </Formik>
    </Container>
  );
};
