import styled from '../../styled-components';
import { media, Modal, P } from '@meindach/ui-kit';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as Trash } from '../../assets/icons/trashCan.svg';

export const ImageContainer = styled.div<{ allowUploads: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 0 auto;
  width: 100%;
  ${({ allowUploads, theme }) =>
    allowUploads && `border: 1px dashed ${theme.color.mercury};`}

  > img {
    object-fit: cover;
    width: 100%;
    height: 296px;
    padding: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    ${media.md`
      width: 296px;
      margin: 8px;
  `}
  }
  > p {
    margin: auto;
    color: ${({ theme }) => theme.color.silver};
    font-size: 18px;
    font-weight: 400;
  }
`;

export const AddButton = styled.div`
  border-radius: 50%;
  min-width: 48px;
  min-height: 48px;
  height: 42px;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.red};
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms 0ms cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  right: 20px;
  bottom: 12px;
  z-index: 1;

  :hover {
    background: ${({ theme }) => theme.color.red600};
  }

  > label {
    cursor: pointer;
    > svg {
      position: absolute;
      color: ${({ theme }) => theme.color.white};
      width: 24px;
      height: 24px;
      left: calc(50% - 12px);
      top: calc(50% - 12px);
    }
  }

  > input {
    display: none;
  }
`;

export const CloseButton = styled.div`
  border-radius: 2px;
  min-width: 42px;
  min-height: 42px;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.white};
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  :hover {
    > svg {
      color: ${({ theme }) => theme.color.slate};
    }
  }
`;

export const CloseIcon = styled(Close)`
  position: absolute;
  color: ${({ theme }) => theme.color.silver};
  width: 14px;
  height: 14px;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
`;

export const StyledModal = styled(Modal)`
  border: none;
  background: none;
  box-shadow: 5px 5px 5px 0px ${({ theme }) => theme.color.slate};
  padding: 0px;
  margin: 0 auto;
  position: relative;
  width: fit-content;
  max-height: auto;
`;

export const PhotoContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 42px;
  margin-bottom: -4px;
  > img {
    border-radius: 4px;
    border: none;
    object-fit: cover;
    max-width: 100%;
    max-height: 800px;
    padding: 0;
    margin-top: 0px;
  }
`;

export const UploadInfo = styled(P)`
  padding: 16px;
  text-align: center;
`;

export const ButtonDivider = styled.div`
  min-width: 2px;
  min-height: 42px;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.color.slate};
  position: absolute;
  right: 42px;
  top: 0px;
  z-index: 1;
`;

export const DeleteButton = styled.div`
  border-radius: 2px;
  min-width: 42px;
  min-height: 42px;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.white};
  position: absolute;
  right: 44px;
  top: 0px;
  z-index: 1;
  :hover {
    > svg {
      color: ${({ theme }) => theme.color.slate};
    }
  }
`;

export const DeleteIcon = styled(Trash)`
  position: absolute;
  color: ${({ theme }) => theme.color.silver};
  width: 14px;
  height: 14px;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
`;
