import styled from '../../styled-components';

export const HorizontalDivider = styled.div`
  margin: ${({ theme }) => theme.spacing.m} 0;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.mercury};

  ::before {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
    margin-top: 3px;
  }
  ::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.color.mercury};
    margin-top: 3px;
  }
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 20px;
  background: ${({ theme }) => theme.color.white};
  margin: 0 ${({ theme }) => theme.spacing.s};
`;

export const SplitDivider = styled.div`
  margin: ${({ theme }) => theme.spacing.m} 0;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.storm};

  ::before {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.color.storm};
    margin-top: 3px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }
  ::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.color.storm};
    margin-top: 3px;
    margin-left: ${({ theme }) => theme.spacing.s};
  }
`;
