import React, { FC } from 'react';
import { Marker } from 'react-map-gl';
import { Pin } from './styled';
import { ReactComponent as RooferPin } from '@meindach/ui-kit/dist/assets/map/roofer-pin.svg';

interface Props {
  lat: number;
  lon: number;
}

export const RooferMarker: FC<Props> = ({ lon, lat }) => (
  <Marker longitude={lon} latitude={lat} offsetLeft={-12} offsetTop={-35}>
    <Pin>
      <RooferPin />
    </Pin>
  </Marker>
);
