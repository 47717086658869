import { useCallback, useEffect } from 'react';
import { useProjectEntity } from './useProjectEntity';
import { SalesStage } from '../types/ProjectEntity';
import { RooferProjectEntity } from '../types/RooferProjectEntity';
import { createStore, useStore } from 'react-hookstore';
import { Store } from '../types/Store';
import { Project, Stage } from '../types/Project';
import { mapToProject } from '../utils/mapToProject';
import { mapToSalesStage } from '../utils/salesStageUtils';
import { ArchiveReason } from '../types/ArchiveReason';

interface State {
  initialised: boolean;
  inquiries: Project[];
  projects: Project[];
  archived: Project[];
  lost: Project[];
  updatePending: boolean;
  updateNotesPending: boolean;
  uploadProjectImagePending: boolean;
  deleteProjectImagePending: boolean;
}

export const store = createStore<State>(Store.Project, {
  initialised: false,
  inquiries: [],
  projects: [],
  archived: [],
  lost: [],
  updatePending: false,
  updateNotesPending: false,
  uploadProjectImagePending: false,
  deleteProjectImagePending: false,
});
const { getState, setState } = store;

export const useProject = () => {
  const {
    projects: projectEntities,
    initialised: projectEntityInitialised,
    updateProject,
    updateProjectNotes,
    uploadProjectImage,
    deleteProjectImage,
  } = useProjectEntity();
  const [state] = useStore<State>(Store.Project);
  const {
    initialised,
    inquiries,
    projects,
    archived,
    lost,
    updatePending,
    updateNotesPending,
    uploadProjectImagePending,
    deleteProjectImagePending,
  } = state;

  useEffect(() => {
    const projects = mapProjects(projectEntities);
    setState({
      ...getState(),
      initialised: projectEntityInitialised,
      inquiries: projects.filter(
        (proj) =>
          proj.stage === Stage.NotAccepted && proj.projectLost === false,
      ),
      projects: projects.filter(
        (proj) =>
          proj.stage >= Stage.Accepted &&
          proj.stage < Stage.Complete &&
          proj.projectLost === false,
      ),
      archived: projects.filter(
        (proj) =>
          (proj.stage === Stage.Complete || proj.stage === Stage.Archived) &&
          proj.projectLost === false,
      ),
      lost: projects.filter((proj) => proj.projectLost === true),
    });
  }, [projectEntities, projectEntityInitialised]);

  const setUpdateNotesPendingFalse = useCallback((minDuration) => {
    setTimeout(() => {
      setState({ ...getState(), updateNotesPending: false });
    }, minDuration);
  }, []);

  const progressToNextStage = async (
    project: Project,
    updatedCallback: (updatedProject: RooferProjectEntity) => void,
  ) => {
    setState({ ...getState(), updatePending: true });
    const nextStage: Stage = (project.stage.valueOf() + 1) as Stage;
    const projectEntity = projectEntities.find(
      (projectEntity) =>
        projectEntity.project.opportunity.id === project.opportunityId,
    );
    if (!projectEntity) {
      return;
    }
    await updateProject(
      {
        ...projectEntity,
        project: {
          lead: { ...projectEntity.project.lead },
          opportunity: {
            ...projectEntity.project.opportunity,
            sales_stage: mapToSalesStage(nextStage),
          },
        },
      },
      updatedCallback,
    );
    setState({ ...getState(), updatePending: false });
  };

  const archiveProject = async (
    project: Project,
    archiveReason: ArchiveReason,
    archivedCallback: (updatedProject: RooferProjectEntity) => void,
  ) => {
    setState({ ...getState(), updatePending: true });
    const projectEntity = projectEntities.find(
      (projectEntity) =>
        projectEntity.project.opportunity.id === project.opportunityId,
    );
    if (!projectEntity) {
      return;
    }
    await updateProject(
      {
        ...projectEntity,
        project: {
          lead: { ...projectEntity.project.lead },
          opportunity: {
            ...projectEntity.project.opportunity,
            sales_stage: SalesStage.ClosedLost,
            archive_reason_c: archiveReason,
          },
        },
      },
      archivedCallback,
    );
    setState({ ...getState(), updatePending: false });
  };

  const updateNotes = async (
    project: Project,
    notes: string,
    minDuration: number,
  ) => {
    setState({ ...getState(), updateNotesPending: true });
    const projectEntity = projectEntities.find(
      (projectEntity) =>
        projectEntity.project.opportunity.id === project.opportunityId,
    );
    if (!projectEntity) {
      setState({ ...getState(), updateNotesPending: false });
      return;
    }
    await updateProjectNotes({
      ...projectEntity,
      project: {
        lead: { ...projectEntity.project.lead },
        opportunity: { ...projectEntity.project.opportunity },
      },
      projectNotes: notes,
    });
    if (minDuration > 0) {
      setUpdateNotesPendingFalse(minDuration);
    } else {
      setState({ ...getState(), updateNotesPending: false });
    }
  };

  const uploadImage = async (project: Project, image: File) => {
    setState({ ...getState(), uploadProjectImagePending: true });
    const projectEntity = projectEntities.find(
      (projectEntity) =>
        projectEntity.project.opportunity.id === project.opportunityId,
    );
    if (!projectEntity) {
      setState({ ...getState(), uploadProjectImagePending: false });
      return;
    }
    await uploadProjectImage(projectEntity, image);
  };

  const deleteImage = async (project: Project, imageId: string) => {
    setState({ ...getState(), deleteProjectImagePending: true });
    const projectEntity = projectEntities.find(
      (projectEntity) =>
        projectEntity.project.opportunity.id === project.opportunityId,
    );
    if (!projectEntity) {
      setState({ ...getState(), deleteProjectImagePending: false });
      return;
    }
    await deleteProjectImage(project, imageId);
  };

  return {
    initialised,
    inquiries,
    projects,
    archived,
    lost,
    progressToNextStage,
    archiveProject,
    updateNotes,
    uploadImage,
    deleteImage,
    updatePending,
    updateNotesPending,
    uploadProjectImagePending,
    deleteProjectImagePending,
  };
};

const mapProjects = (projects: RooferProjectEntity[]): Project[] =>
  projects.map((project) => mapToProject(project));
