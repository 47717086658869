export const getHeaders = (
  token?: string,
  contentType: string | null = 'application/json',
) => {
  const headers = new Headers();
  if (contentType) {
    headers.append('Content-Type', contentType);
  }
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('X-Authentication-Type', 'meindach');
  }
  return headers;
};
