import styled from '../../styled-components';

export const Container = styled.div<{ completed: boolean }>`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.storm};
  margin-top: ${({ theme }) => theme.spacing.m};

  > svg {
    margin-left: auto;
    color: ${({ completed, theme }) =>
      completed ? theme.color.blue400 : theme.color.error};
  }
`;
