import { config } from '../config';
import { RooferImageType } from '../types/RooferImageType';
import { getHeaders } from './getHeaders';

export const uploadImage = async (
  token: string,
  rooferId: string,
  imageCategory: RooferImageType,
  file: File,
) => {
  const formData = new FormData();
  formData.append('file', file);
  return fetch(
    `${config.apiBaseUrl}/api/v1/roofer/${rooferId}/image/${imageCategory}`,
    {
      method: 'POST',
      headers: getHeaders(token, null),
      body: formData,
    },
  );
};
