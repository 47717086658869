import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../card/Card';
import { StyledTextArea } from './styled';

interface Props {
  projectNotes: string;
}

export const Notes: FC<Props> = ({ projectNotes }) => {
  const { t } = useTranslation('notes');
  return (
    <Card title={t('title')}>
      <StyledTextArea value={projectNotes} readOnly={true} />
    </Card>
  );
};
