import React, {
  FC,
  useState,
  useEffect,
  ReactElement,
  Children,
  memo,
  useCallback,
} from 'react';
import MapGL, { ViewState, MapboxProps } from 'react-map-gl';
import { MarkerStyle } from './styled';
import { sortByKeyDesc } from '@meindach/ui-kit';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Controls } from './Controls';
import { MapStyle } from './MapStyle';
import { dataMapStyle, dataMapStyleSatellite } from './dataMapStyle';

interface DataSource {
  type: string;
  features: any[];
}

type Props = Partial<ViewState> &
  Pick<MapboxProps, 'mapboxApiAccessToken'> & {
    dataSource?: DataSource;
    directions?: DataSource;
  };

const defaultSource: DataSource = { type: 'FeatureCollection', features: [] };
const sortByLatitude = sortByKeyDesc('lat');
const sortChildren = (a: ReactElement, b: ReactElement) =>
  sortByLatitude(a.props, b.props);

const MarkerStyleMemo = memo(() => <MarkerStyle />);

export const Map: FC<Props> = memo(
  ({
    latitude = 50.1904877,
    longitude = 8.5947669,
    zoom = 10,
    mapboxApiAccessToken,
    dataSource,
    children,
    directions,
  }) => {
    const [mapType, setMapType] = useState<MapStyle>(MapStyle.Default);
    const [mapStyle, setMapStyle] = useState<any>(null);
    const [viewport, setViewport] = useState<ViewState>({
      latitude,
      longitude,
      zoom,
    });

    useEffect(() => {
      setViewport({ latitude, longitude, zoom: zoom || viewport.zoom });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latitude, longitude, zoom]);
    useEffect(() => {
      const style =
        mapType === MapStyle.Default ? dataMapStyle : dataMapStyleSatellite;
      setMapStyle(
        style
          .setIn(['sources', 'directions'], {
            type: 'geojson',
            data: directions || defaultSource,
          })
          .setIn(['sources', 'dataSource'], {
            type: 'geojson',
            data: dataSource || defaultSource,
          }),
      );
    }, [dataSource, directions, mapType]);
    const onViewportChange = useCallback(
      (vp: ViewState) => setViewport(vp),
      [],
    );
    return (
      <>
        <MarkerStyleMemo />
        <MapGL
          {...viewport}
          reuseMaps
          width="100%"
          height="100%"
          mapStyle={mapStyle}
          mapboxApiAccessToken={mapboxApiAccessToken}
          onViewportChange={onViewportChange}
        >
          {(Children.toArray(children) as ReactElement[]).sort(sortChildren)}
          <Controls
            onViewportChange={onViewportChange}
            setMapStyle={setMapType}
            mapStyle={mapType}
          />
        </MapGL>
      </>
    );
  },
);
