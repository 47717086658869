import styled, { css } from '../../styled-components';
import { media } from '@meindach/ui-kit/dist';
import { touchSupport } from '@meindach/ui-kit';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Counter = styled.div<{
  selected: boolean;
}>`
  border: 2px solid ${({ theme }) => theme.color.nag};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: block;
  position: relative;
  margin-left: ${({ theme }) => theme.spacing.s};
  margin-right: ${({ theme }) => theme.spacing.l};

  > span {
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${({ selected }) =>
    !touchSupport &&
    media.md`
    :hover {
      border-color: ${({ theme }) => theme.color.nagAcc};
    }
    ${
      selected &&
      css`
        border-color: ${({ theme }) => theme.color.nagAcc};
      `
    }
  `}
`;
