import React, { FC } from 'react';
import { Modal } from '../../modal/Modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Exclaim } from '../../assets/icons/exclaim.svg';

interface Props {
  toggle: () => void;
}

export const ProjectTakenModal: FC<Props> = ({ toggle }) => {
  const { t } = useTranslation('projectTaken');
  return (
    <Modal
      icon={<Exclaim />}
      title={t('title')}
      message={t('message')}
      toggle={toggle}
      cancelText={t('ok')}
    />
  );
};
