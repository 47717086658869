import {
  CloseButton,
  CloseIcon,
  DeleteButton,
  DeleteIcon,
  ButtonDivider,
  StyledModal,
  PhotoContainer,
} from './styled';
import React, { FC } from 'react';

interface Props {
  toggle: () => void;
  deleteClick: () => void;
  photoSrc?: string;
}

export const PhotoModal: FC<Props> = ({ toggle, deleteClick, photoSrc }) => {
  return (
    <StyledModal>
      <DeleteButton
        onClick={() => {
          deleteClick();
          toggle();
        }}
      >
        <DeleteIcon />
      </DeleteButton>
      <ButtonDivider />
      <CloseButton onClick={toggle}>
        <CloseIcon />
      </CloseButton>
      <PhotoContainer>
        <img src={photoSrc} alt={''} />
      </PhotoContainer>
    </StyledModal>
  );
};
