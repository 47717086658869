import { config } from '../config';
import { getHeaders } from './getHeaders';
import { SalesStage } from '../types/ProjectEntity';

const getSalesStageFilters = (salesStages: SalesStage[]) =>
  salesStages.length === 0
    ? ''
    : `&${salesStages
        .map((salesStage) => `salesStage=${salesStage}`)
        .join('&')}`;

export const getOpportunities = (
  token: string,
  rooferId: string,
  salesStages: SalesStage[],
) =>
  fetch(
    `${
      config.classificationUrl
    }/api/v1/roofer/${rooferId}/project?excludeLost=false${getSalesStageFilters(
      salesStages,
    )}`,
    {
      headers: getHeaders(token),
    },
  );
