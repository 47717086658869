import { Header } from './styled';
import { Link } from 'react-router-dom';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import React, { FC } from 'react';

interface Props {
  header: string;
  image: FC;
  route: string;
}

export const ProfileHeader: FC<Props> = ({ header, image: Image, route }) => {
  return (
    <Header>
      <Image />
      {header}
      <Link to={route}>
        <Close />
      </Link>
    </Header>
  );
};
