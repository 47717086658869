import React, { FC } from 'react';
import {
  Address,
  Data,
  Details,
  DetailsHeader,
  Email,
  FullName,
  Phone,
  SubHeader,
} from './styled';
import { Label, P } from '@meindach/ui-kit';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as LocationPin } from '../../assets/icons/locationPin.svg';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { useTranslation } from 'react-i18next';
import { Card } from '../card/Card';
import { HorizontalDivider } from '../divider/styled';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as AddressIcon } from '../../assets/icons/mailBox.svg';
import { Link } from '../link/styled';
import { MapCard } from '../mapCard/MapCard';
import { Coordinates } from '../../types/Coordinates';

interface Props {
  reference: string;
  startDate: string;
  street?: string;
  city?: string;
  postalCode: string;
  dateEntered: string;
  customerFullName?: string;
  customerPhone?: string;
  customerEmail?: string;
  customerStreet?: string;
  customerCity?: string;
  customerPostalCode?: string;
  customerLatLon: Coordinates;
}

export const ProjectOverview: FC<Props> = ({
  reference,
  startDate,
  street,
  city,
  postalCode,
  dateEntered,
  customerFullName,
  customerPhone,
  customerEmail,
  customerStreet,
  customerCity,
  customerPostalCode,
  customerLatLon,
}) => {
  const { t } = useTranslation('projectOverview');

  return (
    <Card title={t('title')}>
      <DetailsHeader>
        <Label>{t('reference')}</Label>
        <P>{reference}</P>
      </DetailsHeader>
      <Details>
        <div>
          <Calendar />
          <Label>{t('calender')}</Label>
        </div>
        <Data>{dateEntered}</Data>
      </Details>
      <Details>
        <div>
          <LocationPin />
          <Label>{t('address')}</Label>
        </div>
        <Data>{street}</Data>
        <Data>{city}</Data>
        <Data>{postalCode}</Data>
        <MapCard customerLatLon={customerLatLon} />
      </Details>
      <Details>
        <div>
          <Clock />
          <Label>{t('time')}</Label>
        </div>
        <Data>{startDate}</Data>
      </Details>
      {customerFullName && (
        <>
          <HorizontalDivider />
          <SubHeader>{t('customerDetails')}</SubHeader>
          <FullName>{customerFullName}</FullName>
          <Phone>
            <PhoneIcon />
            <Link href={`tel:${customerPhone}`}>{customerPhone}</Link>
          </Phone>
          <Email>
            <EmailIcon />
            <Link href={`mailto:${customerEmail}`}>{customerEmail}</Link>
          </Email>
          <Address>
            <AddressIcon />
            <span>
              {customerStreet}, {customerCity}, {customerPostalCode}
            </span>
          </Address>
        </>
      )}
    </Card>
  );
};
