import './Polyfills';
// Rollbar should be the first thing that is imported after any polyfills
import './Rollbar';
import './i18n/i18n';
import React, { FC } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme } from '@meindach/ui-kit';
import ReactGA from 'react-ga';
import { ThemeProvider } from './styled-components';
import { Layout } from './layout/Layout';
import { Routes } from './Routes';
import { config } from './config';

const App: FC = () => {
  ReactGA.initialize(config.googleTrackingId, config.googleOptions);
  ReactGA.set({ page: window.location.pathname }); // Update the user's current page
  ReactGA.pageview(window.location.pathname); // Record a pageview for the given page

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Routes />
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

render(<App />, document.getElementById('root'));
