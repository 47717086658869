import React, { FC } from 'react';
import { render } from 'react-dom';
import { act } from 'react-dom/test-utils';

interface Props {
  callback: () => any;
}

const Component: FC<Props> = ({ callback }) => {
  callback();
  return null;
};

export const testHook = (callback: Props['callback']) => {
  const container = document.createElement('div');
  act(() => {
    render(<Component callback={callback} />, container);
  });
  return container;
};
