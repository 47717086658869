import { Roofer } from '../types/Roofer';

export const requiredFields: (keyof Roofer)[] = [
  'firstName',
  'lastName',
  'company',
  'city',
  'phone',
  'postalCode',
  'street',
  'masterCraftsmanCertificate',
  'partner',
  'maxDistance',
  'services',
  'asbestosCertificate',
  'energyConsultant',
];

export const aboutMeFields: (keyof Roofer)[] = [
  'firstName',
  'lastName',
  'company',
  'street',
  'city',
  'postalCode',
  'masterCraftsmanCertificate',
];

export const contactFields: (keyof Roofer)[] = ['email', 'phone'];

export const companyFields: (keyof Roofer)[] = ['partner', 'maxDistance'];

export const servicesFields: (keyof Roofer)[] = ['services'];

export const uploadFields: (keyof Roofer)[] = ['profile', 'companyLogo'];
