import { useStore, createStore } from 'react-hookstore';
import { useCallback } from 'react';
import { StorageId } from '../types/StorageId';
import { Store } from '../types/Store';
import { getRooferSession } from '../api/getRooferSession';
import { clearRooferSession } from '../api/clearRooferSession';
import { storageFactory } from 'storage-factory';

interface Auth {
  token: string | null;
  pending?: boolean;
  error?: string;
}

interface Response {
  token: string;
  uuid: string;
}

interface Output extends Auth {
  login: (email: string, password: string) => Promise<Response | undefined>;
  logout: () => Promise<void>;
  tokenLogin: (token: string) => void;
}

export const local = storageFactory(() => localStorage);

export const store = createStore<Auth>(Store.Auth, {
  token: local.getItem(StorageId.IdToken),
});

export const useAuth = (): Output => {
  const [state, setState] = useStore<Auth>(Store.Auth);
  const { token = null, pending = false, error } = state;

  const logout = useCallback(async () => {
    let promise;
    try {
      promise = clearRooferSession(token!);
    } catch {}
    setState({ token: null });
    local.removeItem(StorageId.IdToken);
    await promise;
  }, [token, setState]);

  const login = useCallback(
    async (email: string, password: string) => {
      setState({ token: null, pending: true });
      try {
        const response = await getRooferSession(email, password);
        const resp = await response.json();
        if (response.ok) {
          local.setItem(StorageId.IdToken, resp.token);
          setState({ token: resp.token });
          return resp;
        }
        setState({ token: null, error: resp.statusText });
      } catch {
        setState({ token: null, error: 'Authentication error' });
      }
    },
    [setState],
  );

  const tokenLogin = useCallback(
    (token: string) => {
      local.setItem(StorageId.IdToken, token);
      setState({ token });
    },
    [setState],
  );

  return { login, logout, token, pending, error, tokenLogin };
};
