import React, { FC } from 'react';
import { SmallTileContainer } from './styled';
import { ReactComponent as Chevron } from '@meindach/ui-kit/dist/assets/ui/chevronDown.svg';
import { P } from '@meindach/ui-kit';

interface Props {
  icon: FC;
  text: string;
  selected: boolean;
  enabled: boolean;
  nag: boolean;
  onClick?: () => void;
}

export const SmallTile: FC<Props> = ({
  icon: Icon,
  text,
  selected,
  enabled,
  nag,
  onClick,
}) => {
  return (
    <SmallTileContainer
      selected={selected}
      enabled={enabled}
      nag={nag}
      onClick={onClick}
    >
      <Icon />
      <div>
        <P>{text}</P>
      </div>
      <Chevron />
    </SmallTileContainer>
  );
};
