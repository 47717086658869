import React, { FC, useState } from 'react';
import { NumericInput } from '../input/NumericInput';
import { Error, Label } from './styled';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { Validation } from '../../types/Validation';

interface FieldProps {
  className?: string;
  onChange: (value: number) => void;
  label?: string;
  placeholder?: string;
  validations: Validation[];
  value?: number;
}

export const NumericField: FC<FieldProps> = ({
  className,
  onChange,
  label,
  placeholder,
  validations,
  value,
}) => {
  const [error, setError] = useState('');
  const handleChange = (value: number) => {
    const errorMessage = getErrorMessage(validations, value);
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    onChange(value);
    setError('');
  };

  return (
    <div className={className}>
      {label && <Label error={!!error}>{label}</Label>}
      <NumericInput
        onChange={handleChange}
        placeholder={placeholder}
        error={!!error}
        value={value}
      />
      <Error>{error}</Error>
    </div>
  );
};
