import { media } from '@meindach/ui-kit';
import styled from '../../styled-components';

export const InnerContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.m};
  padding-top: 0;
  box-sizing: border-box;
  flex-direction: column;
  ${media.md`
    flex-direction: row;
    > :first-child {
      margin-right: ${({ theme }) => theme.spacing.m};
    }
  `}

  > div {
    width: 100%;
  }
`;

export const Info = styled.div`
  padding: ${({ theme }) => theme.spacing.m};
  padding-bottom: 0;
  font-size: 16px;
  > p {
    margin-top: 0;
    :last-child {
      margin: 0;
    }
  }
`;

export const ImageTitle = styled.p`
  margin: ${({ theme }) => theme.spacing.l} 0 ${({ theme }) => theme.spacing.s};
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.slate};
`;
