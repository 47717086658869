import React, { useEffect, useState } from 'react';
import { SmallTile } from '../../components/smallTile/SmallTile';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Binoculars } from '../../assets/icons/binoculars.svg';
import { ReactComponent as Spec } from '../../assets/icons/spec.svg';
import { ReactComponent as ThumbsUpDown } from '../../assets/icons/thumbsUpDown.svg';
import { ProjectOverview } from '../../components/projectOverview/ProjectOverview';
import { PageLoading } from '../../components/pageLoading/PageLoading';
import { ProjectSpecification } from '../../components/projectSpecification/ProjectSpecification';
import { AcceptOpportunity } from '../../components/acceptOpportunity/AcceptOpportunity';
import { LargeTile } from '../../components/largeTile/LargeTile';
import {
  Column,
  ColumnContainer,
} from '../../components/columnContainer/styled';
import { EmptyContainer } from '../../components/emptyContainer/EmptyContainer';
import { Project } from '../../types/Project';
import { NavigationHeader } from '../../components/columnContainer/NavigationHeader';
import { useProject } from '../../hooks/useProject';
import { useLocation } from 'react-router';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Container, Counter } from './styled';
import { Route as RouteType } from '../../types/Route';
import { useHistory } from 'react-router-dom';
import { ProjectTakenModal } from '../../components/projectTakenModal/ProjectTakenModal';
import { useModal } from '../../hooks/useModal';
import { useProjectEntity } from '../../hooks/useProjectEntity';

enum View {
  Opportunities,
  Categories,
  Details,
}

interface State {
  index: number;
  categoryIndex: number;
  view: View;
  handledHash: boolean;
}

export const Opportunities = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation('opportunities');
  const { hash } = useLocation();
  const history = useHistory();
  const [state, setState] = useState<State>({
    index: 0,
    categoryIndex: 0,
    view: View.Opportunities,
    handledHash: false,
  });
  const { index, categoryIndex, view, handledHash } = state;
  const {
    initialised,
    inquiries,
    projects,
    lost,
    archived,
    updatePending,
  } = useProject();
  const { deleteLost } = useProjectEntity();
  const { show, toggle } = useModal();

  useEffect(() => {
    if (hash && !handledHash && initialised) {
      const id = hash.slice(1);
      const idxInquiry = inquiries.findIndex(
        (inquiry) => inquiry.opportunityId === id,
      );
      const idxLost = lost.findIndex((lost) => lost.opportunityId === id);
      if (idxInquiry === -1 && idxLost === -1) {
        if (projects.find((project) => project.opportunityId === id)) {
          history.push(`${RouteType.Projects}#${id}`);
        } else if (archived.find((project) => project.opportunityId === id)) {
          history.push(`${RouteType.Archive}#${id}`);
        }
      }
      if (idxInquiry >= 0) {
        setState((s) => ({ ...s, index: idxInquiry, handledHash: true }));
      } else if (idxLost >= 0) {
        setState((s) => ({
          ...s,
          index: idxLost + inquiries.length,
          handledHash: true,
        }));
      } else {
        setState((s) => ({ ...s, handledHash: true }));
        toggle();
      }
    }
  }, [
    hash,
    handledHash,
    initialised,
    inquiries,
    lost,
    projects,
    archived,
    history,
    toggle,
  ]);

  useEffect(() => {
    if (!hash && inquiries.length + lost.length >= index + 1) {
      if (index < inquiries.length) {
        history.push(
          `${RouteType.Opportunities}#${inquiries[index].opportunityId}`,
        );
      } else {
        history.push(
          `${RouteType.Opportunities}#${
            lost[index - inquiries.length].opportunityId
          }`,
        );
      }
    }
  }, [hash, inquiries, lost, index, history]);

  if (updatePending) {
    return <PageLoading />;
  }

  if (inquiries.length + lost.length === 0) {
    return <EmptyContainer reason={t('reason')} action={t('action')} />;
  }

  const getInquiry = (index: number) => {
    if (index >= inquiries.length + lost.length) {
      setState({
        ...state,
        index: 0,
        categoryIndex: 0,
        view: View.Opportunities,
      });
      return inquiries[0];
    }
    if (index < inquiries.length) {
      return inquiries[index];
    } else {
      return lost[index - inquiries.length];
    }
  };

  const visibleViews = () => {
    if (width < 850) {
      return [view];
    }
    if (width < 1200) {
      if (
        view === View.Opportunities.valueOf() ||
        view === View.Categories.valueOf()
      ) {
        return [View.Opportunities.valueOf(), View.Categories.valueOf()];
      }
      return [View.Details.valueOf()];
    }
    return [
      View.Opportunities.valueOf(),
      View.Categories.valueOf(),
      View.Details.valueOf(),
    ];
  };

  const back = () => {
    setState({ ...state, view: view.valueOf() - visibleViews().length });
  };

  const inquiry = getInquiry(index);
  const viewsToShow = visibleViews();
  const showNavigationHeader =
    viewsToShow.length !== 3 && view.valueOf() >= viewsToShow.length;

  const opportunityCards = (
    inquiry: Project[],
    index: number,
    setIndex: (newIndex: number) => void,
  ) =>
    inquiry.map(
      (
        { opportunityId, overview, specification, numberOfRoofers },
        currIndex,
      ) => (
        <LargeTile
          /* eslint-disable-next-line react/no-array-index-key */
          key={currIndex}
          selected={currIndex === index}
          state="nag"
          title={specification.fullName}
          postalCode={overview.postalCode}
          customerLatLon={overview.latLon}
          area={t(`roofArea:${specification.roofArea}`)}
          startDate={t(`startDate:${overview.startDate}`)}
          cardText={t('cardText', { numberOfRoofers })}
          onClick={() => {
            setIndex(currIndex);
            if (hash !== opportunityId) {
              history.push(`${RouteType.Opportunities}#${opportunityId}`);
            }
          }}
          icon={
            <Counter selected={currIndex === index}>
              <span>{numberOfRoofers}</span>
            </Counter>
          }
        />
      ),
    );

  const lostCards = (
    numberOfOpportunities: number,
    lostProjects: Project[],
    index: number,
    setIndex: (newIndex: number) => void,
  ) =>
    lostProjects.map(
      (
        { opportunityId, overview, specification, numberOfRoofers },
        currIndex,
      ) => (
        <LargeTile
          /* eslint-disable-next-line react/no-array-index-key */
          key={currIndex + numberOfOpportunities}
          selected={
            currIndex + numberOfOpportunities === index - numberOfOpportunities
          }
          state="lost"
          title={specification.fullName}
          postalCode={overview.postalCode}
          customerLatLon={overview.latLon}
          area={t(`roofArea:${specification.roofArea}`)}
          startDate={t(`startDate:${overview.startDate}`)}
          cardText={t('lostText', { numberOfRoofers })}
          onClick={() => {
            setIndex(currIndex + numberOfOpportunities);
            if (hash !== opportunityId) {
              history.push(`${RouteType.Opportunities}#${opportunityId}`);
            }
          }}
          onDeleteClick={() => {
            deleteLost(opportunityId);
          }}
        />
      ),
    );

  const opportunityCategories = (
    categoryIndex: number,
    lostProject: boolean,
    setCategoryIndex: (newCategoryIndex: number) => void,
  ) =>
    [
      { text: 'overView', icon: Binoculars, nag: false, enabled: true },
      { text: 'details', icon: Spec, nag: false, enabled: true },
      { text: 'accept', icon: ThumbsUpDown, nag: true, enabled: !lostProject },
    ].map(({ text, icon, nag, enabled }, currIndex) => (
      <SmallTile
        /* eslint-disable-next-line react/no-array-index-key */
        key={currIndex}
        selected={currIndex === categoryIndex}
        enabled={enabled}
        nag={nag}
        text={t(text)}
        icon={icon}
        onClick={() => {
          enabled && setCategoryIndex(currIndex);
        }}
      />
    ));

  const details = (categoryIndex: number, inquiry: Project) => (
    <>
      {categoryIndex === 0 && overview(inquiry)}
      {categoryIndex === 1 && projectSpec(inquiry)}
      {categoryIndex === 2 && !inquiry.projectLost && (
        <AcceptOpportunity project={inquiry} />
      )}
    </>
  );

  const overview = (inquiry: Project) => (
    <ProjectOverview
      reference={inquiry.overview.reference}
      startDate={t(`startDate:${inquiry.overview.startDate}`)}
      postalCode={inquiry.overview.postalCode}
      dateEntered={inquiry.overview.dateEntered}
      customerLatLon={inquiry.overview.latLon}
    />
  );

  const projectSpec = (inquiry: Project) => {
    const specificationData = Object.entries(
      inquiry.specification,
    ).flatMap(([first, second]) => ({ title: first, value: second }));
    return <ProjectSpecification data={specificationData} />;
  };

  return (
    <Container>
      {show && <ProjectTakenModal toggle={toggle} />}
      {showNavigationHeader && (
        <NavigationHeader project={inquiry} back={back} />
      )}
      <ColumnContainer>
        <Column
          currentView={View.Opportunities.valueOf()}
          viewsToShow={viewsToShow}
          fade={false}
        >
          {opportunityCards(inquiries, index, (idx) =>
            setState({
              ...state,
              index: idx,
              categoryIndex: 0,
              view: View.Categories,
            }),
          )}
          {lostCards(inquiries.length, lost, index + inquiries.length, (idx) =>
            setState({
              ...state,
              index: idx,
              categoryIndex: 0,
              view: View.Categories,
            }),
          )}
        </Column>
        <Column
          currentView={View.Categories.valueOf()}
          viewsToShow={viewsToShow}
          fade
        >
          {opportunityCategories(categoryIndex, inquiry.projectLost, (idx) =>
            setState({
              ...state,
              categoryIndex: idx,
              view: View.Details,
            }),
          )}
        </Column>
        <Column
          currentView={View.Details.valueOf()}
          viewsToShow={viewsToShow}
          fade
        >
          {details(categoryIndex, inquiry)}
        </Column>
      </ColumnContainer>
    </Container>
  );
};
