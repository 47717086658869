export const createGeoJSONCircle = (
  latitude: number,
  longitude: number,
  radiusInKm: number,
  points: number = 64,
) => {
  const distanceY = radiusInKm / 110.574;
  const distanceX =
    radiusInKm / (111.32 * Math.cos((latitude * Math.PI) / 180));

  const ret: [number, number][] = new Array(points)
    .fill(null)
    .map((_, index) => {
      const theta = (index / points) * (2 * Math.PI);
      return [
        longitude + distanceX * Math.cos(theta),
        latitude + distanceY * Math.sin(theta),
      ];
    });
  ret.push(ret[0]);
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [ret],
        },
      },
    ],
  };
};
