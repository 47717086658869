import React from 'react';
import { config } from '../../config';
import { Container } from './styled';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const SignUp = () => {
  const { width } = useWindowDimensions();
  return (
    <Container>
      <iframe
        src={config.registrationUrl}
        title={'Roofer Signup'}
        width={'100%'}
        height={width < 850 ? 590 : 374}
        frameBorder={0}
      />
    </Container>
  );
};
