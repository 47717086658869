import { ArchiveReason } from './ArchiveReason';

export interface ProjectEntity {
  lead: LeadEntity;
  opportunity: OpportunityEntity;
}

export interface LeadEntity {
  id: string;
  date_entered: string;
  reference_number_c: string;
  salutation?: Salutation;
  first_name: string;
  last_name: string;
  phone_home?: string;
  primary_address_street: string;
  primary_address_city: string;
  primary_address_postalcode: string;
  primary_address_state?: string;
  primary_address_country?: string;
  mask_request_type_c?: RequestType;
  house_type_v1_c?: HouseType;
  roof_type_v1_c?: RoofType;
  construction_year_c?: string;
  insulation_type_v1_c?: InsulationType;
  tile_shape_type_v1_c?: TileShapeType;
  tile_color_type_v1_c?: TileColorType;
  tile_finish_type_v1_c?: TileFinishType;
  mask_budget_v1_c?: string;
  picture?: string;
  email1: string;
  material_type_v1_c?: MaterialType;
  finance_types_v1_c?: FinanceType[];
  window_types_v1_c?: WindowType[];
  addon_types_v1_c?: AddonType[];
  roof_size_type_v1_c?: RoofSize;
  roof_size_v1_c?: number;
  start_date_type_v1_c?: StartDateType;
  residential_usage_c?: ResidentialUsage;
  lat_lon_c: string;
}

export interface OpportunityEntity {
  id: string;
  date_inspection_c?: number;
  sales_stage: SalesStage;
  archive_reason_c?: ArchiveReason;
}

export enum Salutation {
  Mr = 'herr',
  Mrs = 'frau',
}

export enum RequestType {
  Renovation = 'Dachsanierung',
  NewBuild = 'Neubau',
  RenovationAndNewBuild = 'Sanierung_und_Neubau',
  Repair = 'Reparaturen',
  Other = 'Sonstiges',
  Notes = 'notes',
}

export enum HouseType {
  Detached = 'efh_house',
  Apartment = 'mfh_house',
  SemiDetached = 'dhh_doublehouse',
  MidTerrace = 'middle_terraced_house',
  EndTerrace = 'end_terraced_house',
  Garage = 'garage',
  Carport = 'carport',
  Barn = 'barn',
  ProductionHall = 'production_hall',
  Hall = 'hall',
  Bungalow = 'bungalow',
  GardenHouse = 'garden_house',
  Terrace = 'terracee',
  Other = 'other',
  Notes = 'notes',
}

export enum RoofType {
  Saddle = 'saddle_roof',
  Pitched = 'pitched_roof',
  Hipped = 'hipped_roof',
  HalfHipped = 'half_hipped_roof',
  Winkelbungalow = 'winkelbungalow',
  Helm = 'helm_roof',
  Pent = 'pent_roof',
  Mansard = 'mansard_roof',
  Arched = 'arched_roof',
  SawTooth = 'scheddach',
  Flat = 'flat_roof',
  FlatAndPitched = 'flat_steep_roof',
  Other = 'other_roof',
  Notes = 'notes',
}

export enum InsulationType {
  RoofInterRafter = 'roof_inter_rafter_insulation',
  RoofRafters = 'roof_rafters_insulation',
  Combination = 'combination',
  Consulting = 'consulting',
  Notes = 'notes',
}

export enum TileShapeType {
  Dachstein = 'dachstein',
  Tonziegel = 'tonziegel',
  Trapezblech = 'trapezblech',
  Asbest = 'asbest',
  AsbestWave = 'asbest_wave',
  FibreCementBoards = 'fibre_cement_boards',
  FibreCementWaveBoards = 'fibre_cement_wave_boards',
  Slate = 'slate',
  BitSchindel = 'bit_schindel',
  Bitumen = 'bitumen',
  Foil = 'foil',
  Schare = 'schare',
  Notes = 'notes',
  Space = 'space',
  Hashes = 'hashes',
  ClickLock = 'click_lock',
  AnkleStandingSeam = 'ankle_standing_seam',
  DoubleStandingSeam = 'double_standing_seam',
  DynamicSquareDoubleCover = 'dynamic_square_double_cover',
  SquareDoubleCover = 'square_double_cover',
  ScalesCover = 'scales_cover',
  UniversalCover = 'universal_cover',
  FrankfurtPan = 'frankfurt_pan',
  RubinV11 = 'rubin_v_11',
  RubinV13 = 'rubin_v_13',
  Metal = 'metal',
  TaunusPan = 'taunus_pan',
  Other = 'other',
}

export enum TileColorType {
  Red = 'red',
  Brown = 'brown',
  Black = 'black',
  DeepRed = 'deep_red',
  DeepBlack = 'deep_black',
  CommonBeech = 'common_beech',
  Granite = 'granite',
  Other = 'other',
}

export enum TileFinishType {
  Matte = 'matt',
  SemiMatte = 'semi_matt',
  Shiny = 'shiny',
  Glazed = 'glaced',
  Other = 'other',
}

export enum MaterialType {
  Asphalt = 'asphalt',
  ClayOrStone = 'clay_or_stone',
  FiberCementOrCorrugated = 'fibre_cement_or_corrugated',
  Metal = 'metal',
  Slate = 'slate',
}

export enum FinanceType {
  Finance = 'finance',
  Support = 'support',
  Consulting = 'consulting',
}

export enum WindowType {
  ConvexGlass = 'window_convex_glass',
  Dome = 'window_dome',
  FlatGlass = 'window_flat_glass',
  FoldSwing = 'window_fold_swing',
  Swing = 'window_swing',
}

export enum AddonType {
  Construction = 'construction',
  Energy = 'energy',
  MoreLight = 'more_light',
}

export enum RoofSize {
  LessThan100 = 'lower_100_sqm',
  Between100To150 = 'between_100_and_150_sqm',
  Between150And200 = 'between_150_and_200_sqm',
  GreaterThan200 = 'over_200_sqm',
}

export enum StartDateType {
  In2To4Weeks = 'in_2_to_4_weeks',
  In1To3Months = 'in_1_to_3_months',
  In1To2Months = 'in_1_to_2_months',
  In2To4Months = 'in_2_to_4_months',
  In4To6Months = 'in_4_to_6_months',
  MoreThan3Months = 'after_3_months',
  MoreThan4Months = '4_or_more_months',
}

export enum ResidentialUsage {
  NonResidential = 'residential_usage_non_residential',
  Residential = 'residential_usage_residential',
}

export enum SalesStage {
  RooferPicking = 'roofer_picking',
  Inspection = 'inspection',
  ProposalCreation = 'proposal_creation',
  ProposalCheck = 'proposal_check',
  ProposalTracking = 'proposal_tracking',
  ProposalGranted = 'proposal_granted',
  ProposalDeclined = 'proposal_declined',
  ContractCreation = 'contract_creation',
  Execution = 'execution',
  Cleared = 'Cleared',
  ClosedWon = 'Closed Won',
  ClosedLost = 'Closed Lost',
  Completion = 'completion',
  GiveAway = 'give_away',
}
