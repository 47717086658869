import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThumbsDown } from '../../assets/icons/thumbsDown.svg';
import { useProjectEntity } from '../../hooks/useProjectEntity';
import { Project } from '../../types/Project';
import { Modal } from '../../modal/Modal';

interface Props {
  toggle: () => void;
  project: Project;
}

export const DeclineOpportunityModal: FC<Props> = ({ toggle, project }) => {
  const { t } = useTranslation('acceptOrDecline');
  const { decline } = useProjectEntity();

  const okAction = useCallback(() => decline(project.opportunityId), [
    decline,
    project.opportunityId,
  ]);

  return (
    <Modal
      icon={<ThumbsDown />}
      title={t('titleForDecline')}
      message={t('declineInfo')}
      okAction={okAction}
      okText={t('decline')}
      toggle={toggle}
      cancelText={t('cancel')}
    />
  );
};
